import { connect } from 'react-redux'
import CategoryList from './CategoryList'
import {
  getChangeCartActionRequest,
  ChangeTotalCartActionRequest,
} from 'store/actions'

const mapStateToProps = () => ({
  color: '#d80417',
})

const mapDispatchToProps = (dispatch) => ({
  getChangeCartActionRequest: (cart) =>
    dispatch(getChangeCartActionRequest(cart)),
  ChangeTotalCartActionRequest: (totalPrice, totalCount) =>
    dispatch(ChangeTotalCartActionRequest(totalPrice, totalCount)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
