export default {
  Home: 'Home',
  Learn: 'Learn',
  Shop: 'Shop',
  'Contact us': 'Contact us',
  Careers: 'Careers',
  Wholesale: 'Wholesale',
  Search: 'Search',
  Lang: 'Lang',
  'Log In': 'Log In',
  Empty: 'Empty',
  'CHOOSE FROM': 'CHOOSE FROM',
  'OUR LATEST PRODUCTS': 'OUR LATEST PRODUCTS',
  'VIEW ALL': 'VIEW ALL',
  'MORE INFO': 'MORE INFO',
  'OUR LATEST': 'OUR LATEST',
  'BLOG POSTS': 'BLOG POSTS',
  'READ MORE': 'READ MORE',
  'ALL RIGHTS RESERVED 2019': 'ALL RIGHTS RESERVED 2019',
  'STAY CONNECTED WITH US': 'STAY CONNECTED WITH US',
  'DESIGNED AND DEVELOPED BY': 'DESIGNED AND DEVELOPED BY',
  'Roasters Recipes': 'Roasters Recipes',
  'Roasting Memories': 'Roasting Memories',
  Events: 'Events',
  Blog: 'Blog',
  'Our Story': 'Our Story',
  'F.A.Q': 'F.A.Q',
  'FILTER RESULTS': 'FILTER RESULTS',
  'PRODUCT USAGE': 'PRODUCT USAGE',
  PRICE: 'PRICE',
  BRANDS: 'BRANDS',
  CATEGORIES: 'CATEGORIES',
  OUR: 'OUR',
  PRODUCTS: 'PRODUCTS',
  'ADD TO CART': 'ADD TO CART',
  WEIGHT: 'WEIGHT',
  DIMENSIONS: 'DIMENSIONS',
  SKU: 'SKU',
  CATEGORY: 'CATEGORY',
  SHARE: 'SHARE',
  'YOU MIGHT LIKE THESE PRODUCT TOO': 'YOU MIGHT LIKE THESE PRODUCT TOO',
  'PAYMENT SUMMARY': 'PAYMENT SUMMARY',
  CHECKOUT: 'CHECKOUT',
  'Sub Total': 'Sub Total',
  Profit: 'Profit',
  'Delivery Fee': 'Delivery Fee',
  Total: 'Total',
  REMOVE: 'REMOVE',
  DELIVERY: 'DELIVERY',
  NAME: 'NAME',
  'COUPON CODE': 'COUPON CODE',
  Remove: 'Remove',
  'PAYMENT METHOD': 'PAYMENT METHOD',
  'SEARCH HERE': 'SEARCH HERE',
  'CONTACT US': 'CONTACT US',
  'VIA THESE PLATFORMS': 'VIA THESE PLATFORMS',
  'SEND US': 'SEND US',
  'Your name': 'Your name',
  'Your address': 'Your address',
  'Phone number': 'Phone number',
  'Title of message': 'Title of message',
  'SEND MESSAGE': 'SEND MESSAGE',
  'INFORM US ABOUT YOUR COMPONY': 'INFORM US ABOUT YOUR COMPONY',
  'INTEREST IN BUYING IN BULK': 'INTEREST IN BUYING IN BULK',
  'Your company name': 'Your company name',
  'Altitude Roasters': 'Altitude Roasters',
  'Forget password': 'Forget password',
  'LOGIN WITH GOOGLE': 'LOGIN WITH GOOGLE',
  LOGIN: 'LOGIN',
  'DONT HAVE AN ACCOUNT YET ?': 'DONT HAVE AN ACCOUNT YET ?',
  'creating an account means that you agree with our':
    'creating an account means that you agree with our',
  'terms and services': 'terms and services',
  'CREATE ACCOUNT': 'CREATE ACCOUNT',
  'will deliver at': 'will deliver at',
  'WELCOME BACK!': 'WELCOME BACK!',
  'ACCOUNT DETAIL': 'ACCOUNT DETAIL',
  'ORDER HISTORY': 'ORDER HISTORY',
  'IN PROGRESS': 'IN PROGRESS',
  COMPLETED: 'COMPLETED',
  date: 'date',
  'order name': 'order name',
  total: 'total',
  Detail: 'Detail',
  Qty: 'Qty',
  item: 'item',
  weight: 'weight',
  Price: 'Price',
  'Total Price': 'Total Price',
  'Coupon Code': 'Coupon Code',
  "WE'VE BEEN LOOKING FOR YOU": "WE'VE BEEN LOOKING FOR YOU",
  'FILL OUT THE FORM TO JOIN US': 'FILL OUT THE FORM TO JOIN US',
  'SUBMIT FILTER': 'SUBMIT FILTER',
  'FILTER BY GOVERNORATE': 'FILTER BY GOVERNORATE',
  'LOAD MORE': 'LOAD MORE',
  'NO EVENT YET': 'NO EVENT YET',
  'HOW IT ALL STARTED': 'HOW IT ALL STARTED',
  'WHERE WE ARE NOW': 'WHERE WE ARE NOW',
  'OUR VISION FOR THE FUTURE': 'OUR VISION FOR THE FUTURE',
  'FIND YOUR': 'FIND YOUR',
  'ANSWERS HERE': 'ANSWERS HERE',
  'GENERAL QUESTIONS': 'GENERAL QUESTIONS',
  'NO RESULT': 'NO RESULT',
  'Search for your question here': 'Search for your question here',
  'YOUR MESSAGE': 'YOUR MESSAGE',
  'Your address': 'Your address',
  'Position interested in': 'Position interested in',
  'Upload your CV here': 'Upload your CV here',
  Upload: 'Upload',
  'INTEREST IN BUYING IN BULK': 'INTEREST IN BUYING IN BULK',
  DONE: 'DONE',
  TRENDING: 'TRENDING',
  PRODUCTS: 'PRODUCTS',
  OUR: 'OUR',
  'WELCOME TO': 'WELCOME TO',
  'ALTITUDE ROASTERS': 'ALTITUDE ROASTERS',
  'Please enter your registered email ID.':
    'Please enter your registered email ID.',
  'We will send a link to change your password.':
    'We will send a link to change your password.',
  'Your email': 'Your email',
  'SEND LINK': 'SEND LINK',
  'Email is invalid': 'Email is invalid',
  'Email is required': 'Email is required',
  'SIGN UP': 'SIGN UP',
  BACK: 'BACK',
  'Your email / phone number': 'Your email / phone number',
  password: 'password',
  'SIGN IN': 'SIGN IN',
  "DON'T HAVE": "DON'T HAVE",
  'AN ACCOUNT YET ?': 'AN ACCOUNT YET ?',
  'Phone is required': 'Phone is required',
  'Phone is invalid': 'Phone is invalid',
  'Password must be at least 6 characters':
    'Password must be at least 6 characters',
  'Password is required': 'Password is required',
  'Password 6+ characters': 'Password 6+ characters',
  'Confirm password': 'Confirm password',
  'terms and services.': 'terms and services.',
  'PLEASE ENTER': 'PLEASE ENTER',
  'NEW PASSWORD': 'NEW PASSWORD',
  'WE HAVE BEEN LOOKING FOR YOU': 'WE HAVE BEEN LOOKING FOR YOU',
  'Name is required': 'Name is required',
  'Message is required': 'Message is required',
  'Your message': 'Your message',
  register: 'register',
  'to checkout': 'to checkout',
  'WELCOME To': 'WELCOME To',
  'show more addresses': 'show more addresses',
  'ADDRESS LIST': 'ADDRESS LIST',
  'ADD NEW': 'ADD NEW',
  CHECKOUT: 'CHECKOUT',
  'There are no items in your cart currently':
    'There are no items in your cart currently',
  'BROWSE OUR SHOP': 'BROWSE OUR SHOP',
  'Product removed from your cart': 'Product removed from your cart',
  or: 'or',
  'ORDERS SUMMARY': 'ORDERS SUMMARY',
  'Coupon is not valid': 'Coupon is not valid',
  'Coupon is valid !': 'Coupon is valid !',
  'Coupon code here': 'Coupon code here',
  Redeem: 'Redeem',
  DELIVERY: 'DELIVERY',
  SEARCH: 'SEARCH',
  "OH WE'RE SORRY WE COULDN'T FIND WHAT YOU'RE LOOKING FOR":
    "OH WE'RE SORRY WE COULDN'T FIND WHAT YOU'RE LOOKING FOR",
  "If you're sure there must be something here, please contact us":
    "If you're sure there must be something here, please contact us",
  TAGS: 'TAGS',
  'Product added to your cart': 'Product added to your cart',
  'YOU MIGHT LIKE THIS PRODUCT TOO': 'YOU MIGHT LIKE THIS PRODUCT TOO',
  'YOUR ADDRESS LIST IS EMPTY! PLEASE ADD AN ADDRESS.':
    'YOUR ADDRESS LIST IS EMPTY! PLEASE ADD AN ADDRESS.',
  'Edit address': 'Edit address',
  'Delete Address': 'Delete Address',
  'Are you sure about deleting this address?':
    'Are you sure about deleting this address?',
  'Yes! I,m sure.': 'Yes! I,m sure.',
  Cancel: 'Cancel',
  'PERSONAL DETAIL': 'PERSONAL DETAIL',
  'Your Name': 'Your Name',
  'EDIT PROFILE': 'EDIT PROFILE',
  'Your Phone': 'Your Phone',
  'Your Email': 'Your Email',
  New: 'New',
  Current: 'Current',
  'SAVE CHANGES': 'SAVE CHANGES',
  'NO COMPLETED ORDER YET': 'NO COMPLETED ORDER YET',
  Done: 'Done',
  'NO IN PROGRESS ORDER': 'NO IN PROGRESS ORDER',
  'Delivery Date': 'Delivery Date',
  'Payment Type': 'Payment Type',
  'POSTING MEMORIES': 'POSTING MEMORIES',
  'MADE ETERNAL': 'MADE ETERNAL',
  'LEARN MORE': 'LEARN MORE',
  'ABOUT ALL KIND OF BREWINGS': 'ABOUT ALL KIND OF BREWINGS',
  'Search In...': 'Search In...',
  products: 'products',
  blog: 'blog',
  events: 'events',
  roastings: 'roastings',
  "This is something I'm searching for": "This is something I'm searching for",
  'NOTHING FOUND': 'NOTHING FOUND',
  'show more': 'show more',
  'Submit Filters': 'Submit Filters',
  'Price (Descending)': 'Price (Descending)',
  'Price (Ascending)': 'Price (Ascending)',
  'Price order': 'Price order',
  Title: 'Title',
  Block: 'Block',
  Street: 'Street',
  Avenue: 'Avenue',
  Building: 'Building',
  Floor: 'Floor',
  'Extra Information': 'Extra Information',
  SAVE: 'SAVE',
  Area: 'Area',
  'RELATED POSTS': 'RELATED POSTS',
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
  'Thank You!': 'Thank You!',
  'The form was submitted successfully': 'The form was submitted successfully',
  'Please choose payment method': 'Please choose payment method',
  Knet: 'Knet',
  'Cash on delivery': 'Cash on delivery',
  'Credit card': 'Credit card',
  SHARE: 'SHARE',
  Profile: 'Profile',
  'Log out': 'Log out',
  EMPTY: 'EMPTY',
  QTY: 'QTY',
  Item: 'Item',
  'order code': 'order code',
  'total items': 'total items',
  'Monthly consumption is required': 'Monthly consumption is required',
  'Monthly consumption (Kg)': 'Monthly consumption (Kg)',
}
