import React from 'react'
import CheckoutLayout from 'components/checkout'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomBtn from 'components/public/button'
import backGround from 'assets/img/map.png'
import { Link } from 'react-router-dom'

class DeliveryAddress extends React.PureComponent {
  state = {
    activeMethod:
      JSON.parse(localStorage.getItem('checkoutContact')) &&
      JSON.parse(localStorage.getItem('checkoutContact')).unitType
        ? JSON.parse(localStorage.getItem('checkoutContact')).unitType
        : 'house',
    area: null,
    area_ar: null,
    error: false,
    mode: null
  }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.area_enName && orderMode.area_arName) {
      this.setState({
        area: orderMode.area_enName,
        area_ar: orderMode.area_arName
      })
    }
    if (orderMode && orderMode.order_mode) {
      this.setState({ mode: orderMode.order_mode })
    }
  }

  render() {
    const { color, lang } = this.props
    const { activeMethod, area, area_ar } = this.state
    let checkoutForm = JSON.parse(localStorage.getItem('checkoutContact'))
      ? JSON.parse(localStorage.getItem('checkoutContact'))
      : {}
    return (
      <CheckoutLayout
        activeLink="/checkout/delivery"
        url="/checkout/contact"
        activeStep2
      >
        <div className="d-flex pt-3 justify-content-center">
          <i
            ariaHidden="true"
            className="ltr-10kpo2i e1i5aqxk0"
            role="presentation"
          >
            <svg width="160" height="160" viewBox="0 0 160 160">
              <g fill="none" fillRule="evenodd">
                <path
                  stroke="#4A4A4A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M57.366 69C60.66 78.322 69.55 85 80 85c10.45 0 19.34-6.678 22.634-16H107l15-9 14 51-26 16-30-10-30 10-26-15 14-52 16 9h3.366zM110 125l-6-46 6 46zm-30-8V99v18zm-29 10l6-49-6 49zm29-42c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"
                ></path>
                <path
                  className="st0"
                  fill="currentColor"
                  d="M91 57.816c0 .841-.096 1.658-.282 2.444l.008.003c-1.342 5.788-4.754 10.794-9.42 14.234-.118.087-.237.172-.357.258a1.912 1.912 0 01-.938.245c-.426 0-.82-.138-1.136-.372-4.8-3.471-8.295-8.585-9.632-14.498l.007-.003a10.742 10.742 0 01-.25-2.31C69 51.841 73.925 47 79.999 47 86.075 47 91 51.842 91 57.816zm-11-4.13c-2.319 0-4.2 1.849-4.2 4.13 0 2.28 1.881 4.127 4.2 4.127 2.32 0 4.2-1.847 4.2-4.128 0-2.28-1.88-4.128-4.2-4.128zm9 85.316c-2.82 0-2.995 2.503-3 3.005a1.001 1.001 0 01-2 .018v-.002c.005 0 .002-.008 0-.016v-.005c-.005-.495-.177-3-3-3a1 1 0 01-.002-2c.502-.007 3.002-.177 3.002-3 0-.552.448-1.002 1-1.002s1 .45 1 1.002c0 2.823 2.502 2.993 3.005 3a1 1 0 01.025 2H89zM146.4 93.6c-1.692 0-1.797 1.5-1.8 1.803a.601.601 0 01-1.2.015l.003-.006s-.003-.006-.003-.012c-.003-.297-.108-1.8-1.8-1.8a.601.601 0 01-.003-1.2c.303-.003 1.803-.108 1.803-1.8a.6.6 0 111.2 0c0 1.692 1.5 1.797 1.803 1.8a.601.601 0 01.009 1.2h-.012zm-109-53c-1.692 0-1.797 1.5-1.8 1.803a.601.601 0 01-1.2.015l.003-.006s-.003-.006-.003-.012c-.003-.297-.108-1.8-1.8-1.8a.601.601 0 01-.003-1.2c.303-.003 1.803-.108 1.803-1.8a.6.6 0 111.2 0c0 1.692 1.5 1.797 1.803 1.8a.601.601 0 01.009 1.2H37.4z"
                ></path>
              </g>
            </svg>
          </i>
        </div>
        <div className="mb-5">
          <Formik
            initialValues={{
              block: checkoutForm ? checkoutForm.block : '',
              street: checkoutForm ? checkoutForm.street : '',
              avenue: checkoutForm ? checkoutForm.avenue : '',
              houseNumber: checkoutForm ? checkoutForm.houseNumber : '',
              officeNumber: checkoutForm ? checkoutForm.officeNumber : '',
              apartmentNumber: checkoutForm ? checkoutForm.apartmentNumber : '',
              specialDirection: checkoutForm
                ? checkoutForm.specialDirection
                : '',
              floor: checkoutForm ? checkoutForm.floor : '',
              building: checkoutForm ? checkoutForm.building : ''
            }}
            onSubmit={async values => {
              const { getCheckoutContactActionRequest, history } = this.props
              const body = checkoutForm
              body.block = values.block
              body.street = values.street
              body.avenue = values.avenue
              body.houseNumber = values.houseNumber
              body.officeNumber = values.officeNumber
              body.apartmentNumber = values.apartmentNumber
              body.specialDirection = values.specialDirection
              body.floor = values.floor
              body.building = values.building
              body.unitType = activeMethod

              getCheckoutContactActionRequest(body)
              history.push('/checkout/payment')
            }}
            validationSchema={Yup.object().shape({
              block:
                lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'),
              street:
                lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'),
              houseNumber:
                activeMethod === 'house' &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required')),
              officeNumber:
                activeMethod === 'office' &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required')),
              apartmentNumber:
                activeMethod === 'apartment' &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required')),
              floor:
                (activeMethod === 'apartment' || activeMethod === 'office') &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required')),
              building:
                (activeMethod === 'apartment' || activeMethod === 'office') &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'))
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props
              return (
                <form onSubmit={handleSubmit}>
                  {(!isValid && touched.block) ||
                  (!isValid && touched.street) ||
                  (!isValid && touched.avenue) ||
                  (!isValid && touched.houseNumber) ||
                  (!isValid && touched.officeNumber) ||
                  (!isValid && touched.apartmentNumber) ||
                  (!isValid && touched.floor) ||
                  (!isValid && touched.building) ? (
                    <div className="text-center text-white error-section">
                      {lang === 'ar'
                        ? 'من فضلك راجع البيانات المدخلة'
                        : 'Please review your submission'}
                    </div>
                  ) : null}

                  <div className="mb-5">
                    <div className="p-3">
                      <h1 className="title">
                        {lang === 'ar' ? 'عنوان التوصيل' : 'Delivery Address'}
                      </h1>
                      <p className="ltr-qtvxxo mb-0 p-0">
                        {lang === 'ar'
                          ? 'إضافة موقعك بدقة يضمن خدمة توصيل سريعة'
                          : 'Precise location will help reach you faster'}
                      </p>
                    </div>
                    <Link
                      to="/location"
                      className="e6q2xso0 ltr-p9r53k p-5"
                      style={{ backgroundImage: `url(${backGround})` }}
                    >
                      <div className="ltr-uj94hs etsh3yo0" />
                      <button className="ez1ieiq2 ltr-14d91vi e1b3lgmq0 mx-3">
                        <span className="content">
                          {lang === 'ar'
                            ? 'إضغط لتعديل الموقع'
                            : 'Tap to edit location'}
                        </span>
                      </button>
                    </Link>
                    <div className="p-3 d-flex justify-content-between pt-3">
                      <p className="ltr-13w7zlb p-0 m-0">
                        {lang === 'ar' ? 'المنطقة' : 'Area'}
                      </p>
                      <div
                        className="d-flex area-section"
                        style={{ color: color }}
                      >
                        <Link className="ltr-2x41k4" to="/mode/delivery">
                          <div className="ltr-1egxki5">
                            {lang === 'ar' ? area_ar : area}
                            <i
                              cursor="pointer"
                              className="ltr-ttj5pb e1i5aqxk0"
                            >
                              <svg width="1em" height="1em" viewBox="0 0 24 24">
                                <g fill="none" fill-rule="evenodd">
                                  <path d="M3 3h18v18H3z"></path>
                                  <path d="M3 3h18v18H3z"></path>
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.4"
                                    d="M9 18l6-6-6-6"
                                  ></path>
                                </g>
                              </svg>
                            </i>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="white-bg px-3">
                      <label className="unit-label m-0">
                        {lang === 'ar' ? 'اختر نوع الوحدة' : 'Choose Unit Type'}
                      </label>
                    </div>
                    <div className="ltr-13atv4k etr9bj70 p-3 text-center white-bg">
                      <label className="ltr-ndfmgy e1b3lgmq0 ">
                        <a
                          className={`text-center d-flex flex-column ${
                            activeMethod === 'house'
                              ? 'active-method'
                              : ' ltr-1scfs3q '
                          }`}
                          onClick={() => {
                            this.setState({ activeMethod: 'house' })
                            setFieldValue('apartmentNumber', '')
                            setFieldValue('officeNumber', '')
                          }}
                        >
                          <i
                            className="ltr-1jdtxyz e1i5aqxk0"
                            style={{
                              color:
                                activeMethod === 'house' ? color : 'inherit'
                            }}
                          >
                            <svg width="1em" height="1em" viewBox="0 0 35 35">
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.4"
                                d="M8 15.786v12.198C8 28.544 8.455 29 9.016 29H15v-7.115h5V29h5.984c.56 0 1.016-.455 1.016-1.016V15.786M4 17.82L16.302 5.315a1.016 1.016 0 011.422-.027L31 17.82"
                              ></path>
                            </svg>
                          </i>
                          <p className="p-0 ltr-993o66  mb-0">
                            {lang === 'ar' ? 'منزل' : 'House'}
                          </p>
                        </a>
                      </label>
                      <label className="ltr-ndfmgy e1b3lgmq0">
                        <a
                          className={`text-center d-flex flex-column ${
                            activeMethod === 'apartment'
                              ? 'active-method'
                              : 'ltr-1scfs3q'
                          }`}
                          onClick={() => {
                            setFieldValue('houseNumber', '')
                            setFieldValue('officeNumber', '')
                            this.setState({ activeMethod: 'apartment' })
                          }}
                        >
                          <i
                            className="ltr-1jdtxyz"
                            style={{
                              color:
                                activeMethod === 'apartment' ? color : 'inherit'
                            }}
                          >
                            <svg width="1em" height="1em" viewBox="0 0 35 35">
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.4"
                                d="M5 29V6a1 1 0 011-1h23a1 1 0 011 1v23a1 1 0 01-1 1h-8v-8.333h-7V30H6a1 1 0 01-1-1zm6-18.5h1-1zm0 6h1-1zm6-6h1-1zm0 6h1-1zm6-6h1-1zm0 6h1-1z"
                              ></path>
                            </svg>
                          </i>
                          <p className="p-0 ltr-993o66  mb-0">
                            {lang === 'ar' ? 'شقة' : 'Apartment'}
                          </p>
                        </a>
                      </label>
                      <label className="ltr-ndfmgy e1b3lgmq0">
                        <a
                          className={`text-center d-flex flex-column ${
                            activeMethod === 'office'
                              ? 'active-method'
                              : 'ltr-1scfs3q '
                          }`}
                          onClick={() => {
                            setFieldValue('houseNumber', '')
                            setFieldValue('apartmentNumber', '')
                            this.setState({ activeMethod: 'office' })
                          }}
                        >
                          <i
                            className="ltr-1jdtxyz e1i5aqxk0"
                            style={{
                              color:
                                activeMethod === 'office' ? color : 'inherit'
                            }}
                          >
                            <svg width="1em" height="1em" viewBox="0 0 35 35">
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.4"
                                d="M5 17.9V28a1 1 0 001 1h23a1 1 0 001-1V18m-10 1.88V19a1 1 0 00-1-1h-2a1 1 0 00-1 1v.957c-3.721-.214-7.528-1.22-11.42-3.017a1 1 0 01-.58-.907V11a1 1 0 011-1h25a1 1 0 011 1v5.033a1 1 0 01-.58.907c-3.544 1.637-7.017 2.617-10.42 2.94zM17 18h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1zm-4-9V7a1 1 0 011-1h8a1 1 0 011 1v2a1 1 0 01-1 1h-8a1 1 0 01-1-1z"
                              ></path>
                            </svg>
                          </i>
                          <p className="p-0 ltr-993o66  mb-0">
                            {lang === 'ar' ? 'مكان عمل' : 'Office'}
                          </p>
                        </a>
                      </label>
                    </div>
                    <div className="pb-3 px-3 white-bg mb-5">
                      <div
                        className={
                          errors.block && touched.block
                            ? ' error field-container mt-4'
                            : 'field-container mt-4'
                        }
                      >
                        <input
                          id="block"
                          type="text"
                          placeholder=" "
                          value={values.block}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input px-0"
                        />
                        <label
                          className={
                            errors.block && touched.block
                              ? ' error field-placeholder'
                              : ' field-placeholder'
                          }
                          for="inputName"
                        >
                          {lang === 'ar' ? 'قطعة' : 'Block'}
                        </label>
                      </div>
                      {errors.block && touched.block && (
                        <div className="input-feedback">{errors.block}</div>
                      )}
                      <div
                        className={
                          errors.street && touched.street
                            ? ' error field-container mt-4'
                            : ' field-container mt-4'
                        }
                      >
                        <input
                          id="street"
                          type="text"
                          placeholder=" "
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input px-0"
                        />
                        <label
                          className={
                            errors.street && touched.street
                              ? ' error field-placeholder'
                              : ' field-placeholder'
                          }
                          for="inputName"
                        >
                          {lang === 'ar' ? 'الشارع' : 'Street'}
                        </label>
                      </div>
                      {errors.street && touched.street && (
                        <div className="input-feedback">{errors.street}</div>
                      )}
                      <div
                        className={
                          errors.avenue && touched.avenue
                            ? ' error field-container mt-4'
                            : ' field-container mt-4'
                        }
                      >
                        <input
                          id="avenue"
                          type="text"
                          placeholder=" "
                          value={values.avenue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input px-0"
                        />
                        <label
                          className={
                            errors.avenue && touched.avenue
                              ? ' error field-placeholder'
                              : ' field-placeholder'
                          }
                          for="inputName"
                        >
                          {lang === 'ar'
                            ? 'جادة (اختياري)'
                            : 'Avenue (optional)'}
                        </label>
                      </div>
                      {errors.avenue && touched.avenue && (
                        <div className="input-feedback">{errors.avenue}</div>
                      )}
                      {(activeMethod === 'apartment' ||
                        activeMethod === 'office') && (
                        <>
                          <div
                            className={
                              errors.building && touched.building
                                ? ' error field-container mt-4'
                                : 'field-container mt-4'
                            }
                          >
                            <input
                              id="building"
                              type="text"
                              placeholder=" "
                              value={values.building}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field-input px-0"
                            />
                            <label
                              className={
                                errors.building && touched.building
                                  ? ' error field-placeholder'
                                  : ' field-placeholder'
                              }
                              for="inputName"
                            >
                              {lang === 'ar' ? 'المبنى' : 'Building'}
                            </label>
                          </div>
                          {errors.building && touched.building && (
                            <div className="input-feedback">
                              {errors.building}
                            </div>
                          )}
                          <div
                            className={
                              errors.floor && touched.floor
                                ? ' error field-container mt-4'
                                : 'field-container mt-4'
                            }
                          >
                            <input
                              id="floor"
                              type="text"
                              placeholder=" "
                              value={values.floor}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field-input px-0"
                            />
                            <label
                              className={
                                errors.floor && touched.floor
                                  ? ' error field-placeholder'
                                  : ' field-placeholder'
                              }
                              for="inputName"
                            >
                              {lang === 'ar' ? 'الطابق' : 'Floor'}
                            </label>
                          </div>
                          {errors.floor && touched.floor && (
                            <div className="input-feedback">{errors.floor}</div>
                          )}
                        </>
                      )}
                      <div
                        className={
                          (errors.houseNumber && touched.houseNumber) ||
                          (errors.officeNumber && touched.officeNumber) ||
                          (errors.apartmentNumber && touched.apartmentNumber)
                            ? ' error field-container mt-4'
                            : 'field-container mt-4'
                        }
                      >
                        <input
                          id={
                            activeMethod === 'office'
                              ? 'officeNumber'
                              : activeMethod === 'apartment'
                              ? 'apartmentNumber'
                              : 'houseNumber'
                          }
                          type="text"
                          placeholder=" "
                          value={
                            activeMethod === 'office'
                              ? values.officeNumber
                              : activeMethod === 'apartment'
                              ? values.apartmentNumber
                              : values.houseNumber
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input px-0"
                        />
                        <label
                          className={
                            (errors.houseNumber && touched.houseNumber) ||
                            (errors.officeNumber && touched.officeNumber) ||
                            (errors.apartmentNumber && touched.apartmentNumber)
                              ? ' error field-placeholder'
                              : 'field-placeholder'
                          }
                          for="inputName"
                        >
                          {activeMethod === 'office'
                            ? lang === 'ar'
                              ? 'رقم المكتب'
                              : 'Office No.'
                            : activeMethod === 'apartment'
                            ? lang === 'ar'
                              ? 'رقم الشقة'
                              : 'Apartment No.'
                            : lang === 'ar'
                            ? 'رقم المنزل'
                            : 'House No.'}
                        </label>
                      </div>
                      {errors.houseNumber && touched.houseNumber && (
                        <div className="input-feedback">
                          {errors.houseNumber}
                        </div>
                      )}
                      {errors.officeNumber && touched.officeNumber && (
                        <div className="input-feedback">
                          {errors.officeNumber}
                        </div>
                      )}
                      {errors.apartmentNumber && touched.apartmentNumber && (
                        <div className="input-feedback">
                          {errors.apartmentNumber}
                        </div>
                      )}
                      <div
                        className={
                          errors.specialDirection && touched.specialDirection
                            ? ' error field-container mt-4'
                            : 'field-container mt-4'
                        }
                      >
                        <input
                          id="specialDirection"
                          type="text"
                          placeholder=" "
                          value={values.specialDirection}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input px-0"
                        />
                        <label
                          className={
                            errors.specialDirection && touched.specialDirection
                              ? ' error field-placeholder'
                              : ' field-placeholder'
                          }
                          for="inputName"
                        >
                          {lang === 'ar'
                            ? 'تعليمات (اختياري)'
                            : ' Special Directions (optional)'}
                        </label>
                      </div>
                      {errors.specialDirection && touched.specialDirection && (
                        <div className="input-feedback">
                          {errors.specialDirection}
                        </div>
                      )}
                    </div>
                    <div className="my-5 white-bg" />
                  </div>
                  <CustomBtn
                    color={color}
                    type="submit"
                    onClick={() => {}}
                    text={lang === 'ar' ? 'متابعة' : 'Next'}
                  />
                </form>
              )
            }}
          </Formik>
        </div>
        <style type="text/css">
          {`.active-method,.ltr-1scfs3q:hover{border-color:${color} !important ;} 
          .error{
            border-color:${color}
          }
          .st0{
            fill:${color}
          }
          .ltr-2x41k4{
            color:${color}
          }
          .ltr-2x41k4:hover {
            color: #a60311;
           }
            `}
        </style>
      </CheckoutLayout>
    )
  }
}
export default DeliveryAddress
