import React from 'react'
import CustomRadioBtn from 'components/public/radioBtn'
import { Formik, FieldArray } from 'formik'
import _ from 'lodash'

class Requirements extends React.PureComponent {
  state = { checkRadio: null }
  renderRadios = (name, items) => {
    const { onChange, color, invalid, defaultChecked } = this.props
    const checked = []
    if (!_.isEmpty(defaultChecked)) {
      defaultChecked.map((i) => {
        checked.push(i.value)
      })
    }
    return (
      <>
        <div className="d-flex justify-content-between">
          <h1 className="ltr-1i60a19">{name}</h1>
          <div
            className={`ltr-1mx4hym emv37iq0 ${
              invalid ? 'invalid-require' : ''
            }`}
          >
            Required
          </div>
        </div>
        <div>
          <Formik
            initialValues={{ requirements: checked }}
            onSubmit={() => {}}
            render={({ values }) => (
              <div>
                <FieldArray
                  name="condiments"
                  render={(arrayHelpers) => (
                    <div>
                      {items.map((item) => (
                        <CustomRadioBtn
                          color={color}
                          key={item.id}
                          label={item.title}
                          defaultChecked={values.requirements.includes(
                            item.title
                          )}
                          name={name}
                          value={item.title}
                          id={item.title}
                          onChange={(value, name) => onChange(value, name)}
                        />
                      ))}
                    </div>
                  )}
                />
              </div>
            )}
          />
        </div>
      </>
    )
  }
  render() {
    const { data, color } = this.props
    return (
      <>
        {data
          ? data.map((item) => (
              <>
                <div
                  className="p-3 white-bg"
                  key={item.id}
                  id={`requirement-${item.id}`}
                >
                  {this.renderRadios(item.title, item.items)}
                </div>
                <hr />
              </>
            ))
          : ''}
        <style>
          {`.invalid-require{
              background-color:${color}
            }`}
        </style>
      </>
    )
  }
}

export default Requirements
