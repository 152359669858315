import { connect } from 'react-redux'
import MasterForm from './MasterForm'

const mapStateToProps = () => ({
  color: '#d80417',
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MasterForm)
