import React from 'react'
import { Link } from 'react-router-dom'

class CustomBtn extends React.PureComponent {
  render() {
    const {
      text,
      isLink,
      url,
      count,
      info,
      color,
      onClick,
      type,
      disabled,
    } = this.props

    return (
      <>
        <div className="ltr-1usrurx e1b3lgmq0 ">
          {isLink ? (
            <Link to={url}>
              <button
                className={`ez1ieiq2 ltr-yheipv e1b3lgmq0 text-center d-flex ${
                  count ? 'justify-content-between' : 'justify-content-center'
                }`}
                type={type}
                disabled={disabled}
              >
                {count && <span className="ltr-i6bgv1">{count}</span>}
                <span className="content ">{text}</span>
                {info && <span className="">{info}</span>}
              </button>
            </Link>
          ) : (
            <button
              className="ez1ieiq2
              ltr-yheipv
              e1b3lgmq0
              text-center
              d-flex
              justify-content-between
              "
              onClick={() => onClick()}
              type={type}
              disabled={disabled}
            >
              <span className="content w-100">{text}</span>
            </button>
          )}
        </div>
        <style type="text/css">
          {`
          .ltr-yheipv{
            background-color: ${color};
          }
          .ltr-yheipv:hover:enabled,
          .ltr-yheipv:focus {
     
          } 
          .ltr-yheipv[disabled] {
              opacity: 0.7;
              cursor: not-allowed;
          }         
        `}
        </style>
      </>
    )
  }
}
export default CustomBtn
