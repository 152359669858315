import {
  CHANGE_LANG_FETCHING,
  CHANGE_LANG_SUCCESS,
  CHANGE_LANG_FAILURE,
} from '../actionTypes/changeLang'

const initialState = {
  lang: 'ar',
  error: null,
  data: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANG_FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case CHANGE_LANG_SUCCESS:
      return {
        ...state,
        lang: action.payload,
        isFetching: false,
      }
    case CHANGE_LANG_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }

    default:
      return state
  }
}
