import React from 'react'
import { Formik, FieldArray } from 'formik'
import _ from 'lodash'

class Condiments extends React.PureComponent {
  renderRadios = items => {
    const { onChange, color, defaultChecked } = this.props
    const checked = []
    if (!_.isEmpty(defaultChecked)) {
      defaultChecked.map(i => {
        checked.push(i.value)
      })
    }

    return (
      <div>
        <Formik
          initialValues={{ condiments: checked }}
          onSubmit={() => {}}
          render={({ values }) => (
            <div>
              <FieldArray
                name="condiments"
                render={arrayHelpers => (
                  <div>
                    {items.map(item => (
                      <div className="py-3" key={item.id}>
                        <input
                          className="styled-checkbox"
                          id={item.id}
                          type="checkbox"
                          name="condiments"
                          value={item.title}
                          checked={values.condiments.includes(item.title)}
                          onChange={e => {
                            if (e.target.checked) arrayHelpers.push(item.title)
                            else {
                              const idx = values.condiments.indexOf(item.title)
                              arrayHelpers.remove(idx)
                            }
                            onChange(e.target.checked, item.title, item.value)
                          }}
                        />
                        <label
                          htmlFor={item.id}
                          className="d-flex .ltr-17sbyd3 justify-content-between"
                        >
                          <div class="ltr-7ae04q eppvlgj2">
                            <p className="text-uppercase ltr-asx5e1 p-0 m-0">
                              {item.title}
                            </p>
                            <small class="ltr-1ng991p e1iliclz0"></small>
                          </div>
                          <span class="ltr-17jqah9 eppvlgj3">
                            {`+ KWD  ${item.value}`}
                          </span>
                        </label>
                        <style type="text/css">
                          {`
                      .styled-checkbox:hover + label:before {
                        border-color: ${color};
                      }
                      .styled-checkbox:checked + label:before   {
                        background: ${color};
                        border-color: ${color};
                      }
                    `}
                        </style>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          )}
        />
      </div>
    )
  }

  render() {
    const { data } = this.props
    return (
      <>
        {data
          ? data.map((item, index) => (
              <>
                <div className="p-3 white-bg" key={item.id}>
                  <div className="d-flex">
                    <h1 className="w-100 ltr-1i60a19">{item.title}</h1>
                  </div>
                  {this.renderRadios(item.items, index)}
                </div>
                <hr />
              </>
            ))
          : ''}
      </>
    )
  }
}
export default Condiments
