import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class Delivery extends React.PureComponent {
  render() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    const { lang } = this.props
    const data = [
      {
        id: 1,
        deliver_time: '15 mins',
        deliver_time_ar: '15 دقیقه'
      }
    ]
    return (
      <>
        <hr />
        <div className="white-bg p-3 ">
          <h1 className="ltr-14siyw">
            {orderMode && orderMode.order_mode === 'delivery'
              ? lang === 'ar'
                ? 'معلومات التوصيل'
                : 'Delivery Info'
              : lang === 'ar'
              ? 'معلومات الإستلام بالسيارة'
              : 'Car Pickup Info'}
          </h1>
          <Link to="/time">
            <div className="mt-3 d-flex white-bg justify-content-between">
              <div className="d-flex">
                <p className="m-0 ltr-1fsw27x">
                  {orderMode &&
                    orderMode.time_mode === 'Scheduled time' &&
                    lang === 'en' &&
                    orderMode.day}{' '}
                  {orderMode &&
                    orderMode.time_mode === 'Scheduled time' &&
                    lang === 'ar' &&
                    orderMode.day_ar &&
                    orderMode.day_ar}{' '}
                  {` `}
                  {!_.isNil(orderMode) && !_.isNil(orderMode.selected_time)
                    ? lang === 'ar'
                      ? orderMode.selected_time_ar
                      : orderMode.selected_time
                    : lang === 'ar'
                    ? data[0].deliver_time_ar
                    : data[0].deliver_time}
                </p>
              </div>
              <span className="icon-arrow_right checkout-arrow" />
            </div>
          </Link>
        </div>
        <hr />
      </>
    )
  }
}
export default Delivery
