import { connect } from 'react-redux'
import Menu from './Menu'
import { getChangeLangActionRequest } from 'store/actions'

const mapStateToProps = (state) => ({
  lang: state.changeLang.lang,
  totalCart: state.totalCart.total,
})

const mapDispatchToProps = (dispatch) => ({
  getChangeLangActionRequest: (lang) =>
    dispatch(getChangeLangActionRequest(lang)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
