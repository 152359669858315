import * as React from 'react'

function EmptyBag(props) {
  return (
    <div className="p-3 h-100 white-bg">
      <div className="d-flex p-3 mt-2">
        <div className="m-auto">
          <svg viewBox="0 0 196.2 206.7" {...props} width="120">
            <style>{`.prefix__st0{fill:${props.color}}`}</style>
            <g id="prefix__base_1_">
              <path d="M81.2 73.3c19 0 34.4-15.1 34.4-33.8 0-4.2 0-7.7-.1-11.7 0-.8-.7-1.4-1.5-1.4s-1.5.7-1.5 1.6c.2 4.5.4 7.4.4 11.6 0 17.2-14.2 31.1-31.7 31.1S49.5 56.8 49.5 39.6c0-4.2.2-7.4.4-11.6 0-.8-.6-1.5-1.5-1.5-.8 0-1.5.6-1.5 1.4-.1 3.9-.1 7.6-.1 11.7 0 18.5 15.4 33.7 34.4 33.7z" />
              <path d="M195.8 115.3c0-20.7-16-37.6-36.3-39.1l-1.4-64.9v-.1c0-.1 0-.1-.1-.2 0-.1 0-.2-.1-.2 0-.1-.1-.1-.1-.2l-.2-.2-.1-.1-.1-.1V1.3c0-.1 0-.3-.1-.4 0 0 0-.1-.1-.1 0-.1-.1-.2-.2-.3l-.1-.1c-.1-.1-.2-.1-.3-.2 0 0-.1 0-.1-.1s-.2-.1-.3-.1H6c-.1 0-.3 0-.4.1 0 0-.1 0-.1.1-.1 0-.2.1-.3.2l-.1.1c-.1 0-.1.1-.2.2l-.1.1v10l-.1.1c0 .1-.1.2-.1.2 0 .1-.1.1-.1.2l-4.2 194c0 .4.1.7.3 1 .2.3.6.4.9.4H161c.7 0 1.3-.6 1.3-1.3v-.3l-1.1-50.7c19.5-2.4 34.6-18.9 34.6-39.1zM152.5 2.5s-1.7 1.5-3 2.7c-.6.6-.6 1.6 0 2.2l3.3 2.8H9.5l3.3-3c.7-.6.7-1.7 0-2.3-1.2-1-2.7-2.3-2.7-2.3l142.4-.1zm2.4 1.4v4.7l-3.2-2.2 3.2-2.5zM7.3 3.7L10.7 6 7.3 8.7v-5zm-.3 9h148.7l1.3 63.4h-.4c-21.7 0-39.3 17.6-39.3 39.3s17.6 39.3 39.3 39.3c.7 0 1.4 0 2.1-.1l.6 28.6H3.4L7 12.7zM3 204.1l.4-18.5h155.9l.4 18.5H3zm153.5-51.9c-20.3 0-36.8-16.5-36.8-36.8s16.5-36.8 36.8-36.8 36.8 16.5 36.8 36.8-16.4 36.8-36.8 36.8z" />
            </g>
            <g id="prefix__color_1_">
              <g id="prefix__color">
                <path
                  className="prefix__st0"
                  d="M180 166.1c0-.1-.1-.2-.2-.2l-3.6-1.5-1.5-3.6c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2l-1.5 3.6-3.6 1.5c-.2.1-.3.4-.2.6 0 .1.1.2.2.2l3.6 1.5 1.5 3.6c.1.2.4.3.6.2.1 0 .2-.1.2-.2l1.5-3.6 3.6-1.5c.2-.1.3-.4.2-.6zM163.1 56l3.6 1.5 1.5 3.6c.1.2.4.3.6.2.1 0 .2-.1.2-.2l1.5-3.6L174 56c.2-.1.3-.4.2-.6 0-.1-.1-.2-.2-.2l-3.6-1.5-1.5-3.6c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2l-1.5 3.6-3.6 1.5c-.2.1-.3.4-.2.6.1 0 .2.1.3.2zM176 115.3c0-10.5-8.5-19-19-19s-19 8.5-19 19 8.5 19 19 19 19-8.5 19-19zm-10.9.6c0 .6-.5 1.1-1.1 1.1h-5.1v5.1c0 .6-.5 1.1-1.1 1.1h-1.1c-.6 0-1.1-.5-1.1-1.1V117h-5.1c-.6 0-1.1-.5-1.1-1.1v-1.1c0-.6.5-1.1 1.1-1.1h5.1v-5.1c0-.6.5-1.1 1.1-1.1h1.1c.6 0 1.1.5 1.1 1.1v5.1h5.1c.6 0 1.1.5 1.1 1.1v1.1zM85.9 131.1l-5.6-2.3-2.3-5.6c-.2-.4-.6-.5-1-.3-.1.1-.3.2-.3.3l-2.3 5.6-5.6 2.3c-.4.2-.5.6-.3 1 .1.1.2.3.3.3l5.6 2.3 2.3 5.6c.2.4.6.5 1 .3.1-.1.3-.2.3-.3l2.3-5.6 5.6-2.3c.4-.2.5-.6.3-1 0-.1-.1-.2-.3-.3z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <h1 className="empty-bag w-100 text-center mt-2">
        {props.lang === 'ar' ? 'حقيبتك فارغة' : 'Your bag is empty'}
      </h1>
      <p className="w-100 text-center mt-2 empty-bag-sub">
        {props.lang === 'ar'
          ? 'للمتابعة، تصفح القائمة وأضف طلبات إلى حقيبتك'
          : 'Browse menu and add items to your order to proceed'}
      </p>
    </div>
  )
}

export default EmptyBag
