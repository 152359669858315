import React from 'react'
import CustomBtn from 'components/public/button'

class OrderBtn extends React.PureComponent {
  render() {
    let totalCart = localStorage.getItem('merchantTotalPriceCart')
    let count = localStorage.getItem('merchantTotalCountCart')
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    const { color, lang } = this.props

    return (
      <>
        {orderMode && count && count != 0 ? (
          <CustomBtn
            text={lang === 'ar' ? 'راجع الطلب' : 'Review order'}
            info={lang === 'ar' ? `د.ك ${totalCart}` : `KD ${totalCart}`}
            isLink
            url="/order"
            count={count}
            color={color}
          />
        ) : (
          <CustomBtn
            text={lang === 'ar' ? 'بدء الطلب' : 'Start ordering'}
            isLink
            url="/mode/delivery"
            color={color}
          />
        )}
      </>
    )
  }
}
export default OrderBtn
