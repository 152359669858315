import React, { Component } from 'react'
import CustomMap from 'components/public/map'
import CustomBtn from 'components/public/button'
import Header from 'components/public/pageHeader'

export class BranchDetail extends Component {
  render() {
    const data = {
      id: '1',
      tell: '97994444',
      lng: '48.0152539247872',
      lat: '29.3129697901648',
      start_day: 'Sunday ',
      end_day: 'Saturday',
      start_time: '9:00 AM ',
      end_time: '5:00 PM',
      name_en: 'sura',
      name_ar: 'سورا',
    }
    const { color, history, setDeliveryModeActionRequest } = this.props
    return (
      <div className="h-100 main-bg">
        <div
          className="white-bg"
          style={{
            width: '100%',
            height: '50px',
            borderBottom: '1px solid #eee',
          }}
        >
          <Header text="Branch Details" url="/branches" />
        </div>
        <div
          className="white-bg"
          style={{
            width: '100%',
            height: '220px',
            borderBottom: '1px solid #eee',
          }}
        >
          <CustomMap lat={data.lat} lng={data.lng} info={data.name_en} />
        </div>
        <div className="d-flex p-3 text-center white-bg">
          <a
            href={`tel:${data.tell}`}
            className="ez1ieiq2 ltr-wp05q2 e1b3lgmq0 text-center"
          >
            <span
              className="content text-center w-100"
              style={{ color: 'rgb(0, 0, 0)' }}
            >
              Call branch
            </span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/dir/?api=1&amp;destination=${data.lng},${data.lat}`}
            className="ez1ieiq2 ltr-wp05q2 e1b3lgmq0 text-center"
          >
            <span
              className="content text-center w-100"
              style={{ color: 'rgb(0, 0, 0)' }}
            >
              Get directions
            </span>
          </a>
        </div>
        <hr />
        <div className="d-flex p-3 white-bg">
          <span className="bullet" style={{ color: 'rgba(40, 189, 80, 0.9)' }}>
            &#8226;
          </span>
          <span className="px-1" style={{ color: 'rgba(40, 189, 80, 0.9)' }}>
            Open
          </span>
          <span className="px-1">Until {data.end_time}</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between p-3 white-bg">
          <p className="p-0 m-0 ltr-k97gjc">
            {data.start_day}-{data.end_day}
          </p>
          <p className="p-0 m-0 ltr-k97gjc">
            {data.start_time}-{data.end_time}
          </p>
        </div>
        <CustomBtn
          text="Select pickup branch"
          color={color}
          onClick={() => {
            const orderMode = JSON.parse(
              localStorage.getItem('merchantOrderMode')
            )
              ? JSON.parse(localStorage.getItem('merchantOrderMode'))
              : {}
            orderMode.order_mode = 'pickup'
            orderMode.branch_id = data.id
            orderMode.branch_enName = data.name_en
            orderMode.branch_arName = data.name_ar
            setDeliveryModeActionRequest(orderMode)
            history.push('/')
          }}
        />
      </div>
    )
  }
}

export default BranchDetail
