import { SET_AREAS_SUCCESS } from '../actionTypes/areas'

const initialState = {
  error: null,
  data: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AREAS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      }
    default:
      return state
  }
}
