import { connect } from 'react-redux'
import DeliveryAddress from './DeliveryAddress'
import { getCheckoutContactActionRequest } from 'store/actions'

const mapStateToProps = state => ({
  color: '#d80417',
  contactData: state.checkoutContact.data,
  lang: state.changeLang.lang
})

const mapDispatchToProps = dispatch => ({
  getCheckoutContactActionRequest: body =>
    dispatch(getCheckoutContactActionRequest(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryAddress)
