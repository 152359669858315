import React from 'react'
import ModeLayout from 'components/mode'
import { StickyContainer, Sticky } from 'react-sticky'
import data from './data'
import _ from 'lodash'

class Header extends React.Component {
  static defaultProps = {
    className: ''
  }
  render() {
    const { style, className, text, onClick, active } = this.props
    return (
      <div
        className={'header ltr-d0y9pp' + className}
        style={{
          ...style,
          zIndex: '1000',
          top: '103px'
        }}
        {...{
          onClick: () => {
            onClick()
          }
        }}
      >
        <div
          className={`accordion-item__line ltr-13oyw06 `}
          style={{
            backgroundColor: active === text ? 'rgb(245, 245, 245)' : '#fff'
          }}
        >
          <p className="ltr-1qegtm7 mb-0">{text}</p>
          <span className="accordion-item__icon">
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 32 32"
              style={{ transform: 'rotate(180deg)' }}
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h32v32H0z"></path>
                <path
                  fill="currentColor"
                  d="M21.293 19.707L16 14.414l-5.293 5.293a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 11-1.414 1.414z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </div>
    )
  }
}

class AreaContainer extends React.Component {
  state = {
    opened: true,
    active: '',
    activeArea: null
  }
  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.area_id && orderMode.order_mode === 'delivery') {
      this.setState({ activeArea: orderMode.area_id })
    }
  }

  renderSubAreas = (items, catId) => {
    const { request, color, history, lang } = this.props
    const { activeArea } = this.state

    return items.map(item => (
      <>
        <li
          role="button"
          className={`ltr-1bvpidu ${activeArea &&
            activeArea === item.id &&
            'active-area'}`}
          catId={catId}
          key={item.id}
          onClick={() => {
            this.setState({ activeArea: item.id })
            const area = JSON.parse(localStorage.getItem('merchantOrderMode'))
              ? JSON.parse(localStorage.getItem('merchantOrderMode'))
              : {}
            area.area_catId = catId
            area.order_mode = 'delivery'
            area.area_id = item.id
            area.area_enName = item.name_en
            area.area_arName = item.name_ar
            request(area)
            history.push('/')
          }}
        >
          <div className="accordion-item__inner">
            <div className="accordion-item__content ltr-17nw5cg">
              {lang === 'ar' ? item.name_ar : item.name_en}
            </div>
          </div>
        </li>
        <style>
          {`.active-area::before {
                background: ${color};
              }`}
        </style>
      </>
    ))
  }

  render() {
    const { item, lang } = this.props
    const { opened } = this.state
    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`
          }}
        >
          <StickyContainer key={item.id}>
            <Sticky>
              {({ style }) => (
                <Header
                  style={{ ...style }}
                  text={lang === 'ar' ? item.name_ar : item.name_en}
                  onClick={() =>
                    this.setState({ opened: !opened, active: item.name_en })
                  }
                  active={this.state.active}
                />
              )}
            </Sticky>
            <ul className="ltr-tcfqt2">
              {this.renderSubAreas(item.childs, item.id)}
            </ul>
          </StickyContainer>
        </div>
      </>
    )
  }
}

class DeliveryMood extends React.PureComponent {
  state = { searchValue: '', searchResult: [] }
  renderAreaList = list => {
    const { setDeliveryModeActionRequest, color, history, lang } = this.props
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {list.items.map(item => (
            <li {...{ className: 'accordion-list__item' }} key={item.id}>
              <AreaContainer
                item={item}
                request={setDeliveryModeActionRequest}
                color={color}
                history={history}
                lang={lang}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  search = e => {
    this.setState({ searchValue: e.target.value })

    const result = { items: [] }

    data.items.map(item => {
      const { childs } = item
      childs.map(i => {
        if (e.target.value === '') {
          this.setState({ searchResult: result })
        } else if (_.includes(i.name_en, e.target.value)) {
          item.childs = [i]
          result.items.push(item)
        }
      })
    })

    const { items } = result

    const final = { items: [] }

    if (!_.isEmpty(items)) {
      const uniqueResult = Array.from(new Set(items.map(a => a.id))).map(id => {
        return items.find(a => a.id === id)
      })

      if (!_.isEmpty(uniqueResult)) {
        uniqueResult.map(i => final.items.push(i))
      }
      this.setState({ searchResult: final })
    }

    if (e.target.value === '') {
      this.setState({ searchResult: data, searchValue: '' })
    }
  }

  render() {
    const { searchValue, searchResult } = this.state
    const { lang } = this.props
    return (
      <ModeLayout history={this.props.history}>
        <div className="ltr-1fqqef4">
          <div className="ltr-1tbs9m9 e1i3i4pb1">
            <input
              type="search"
              required=""
              placeholder={lang === 'ar' ? 'إبحث عن مدينة' : 'Search in cities'}
              className="e1mim1e70 ltr-xauasu e1i3i4pb2"
              value={searchValue}
              onChange={e => this.search(e)}
            />
          </div>
        </div>
        <div className="ltr-11xvhlv e1ia0kni0" />
        <div>
          {searchValue != '' && _.isEmpty(searchResult) ? (
            <p className="ltr-aoug8f mb-0"></p>
          ) : searchValue === '' ? (
            this.renderAreaList(data)
          ) : (
            this.renderAreaList(searchResult)
          )}
        </div>
      </ModeLayout>
    )
  }
}
export default DeliveryMood
