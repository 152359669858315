import React from 'react'
import Heder from 'components/public/pageHeader'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomBtn from 'components/public/button'

class OrderStatus extends React.PureComponent {
  state = { error: false, validCode: 'valid', invalidCode: 'invalid' }
  render() {
    const { color, history, lang } = this.props
    const { error } = this.state
    return (
      <>
        <Heder
          text={lang === 'ar' ? 'البحث عن طلب' : 'Order Look Up'}
          url="/"
        />
        <hr />
        <div className="order-status-container white-bg">
          <div className="section">
            <p className="ltr-1rn4h86 e1iliclz0 m-0 p-0">
              {lang === 'ar'
                ? 'يمكن العثور على رقم الطلب في رسالة نصية ، بعد القيام بطلب.'
                : 'Order code can be found in SMS messages, after placing an order.'}
            </p>
          </div>
          <div className="">
            <Formik
              initialValues={{
                code: ''
              }}
              onSubmit={async values => {
                if (values.code === 'valid') {
                  history.push(`/receipt/${values.code}`)
                } else {
                  this.setState({ error: true })
                }
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().required('Required')
              })}
            >
              {props => {
                const {
                  values,
                  dirty,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="p-3">
                      <div
                        className={
                          error
                            ? ' error field-container '
                            : ' field-container mt-3'
                        }
                      >
                        <input
                          id="code"
                          type="text"
                          placeholder=" "
                          value={values.code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-input"
                        />
                        <label
                          className={
                            error
                              ? ' error-label field-placeholder'
                              : ' field-placeholder'
                          }
                          for="inputName"
                        >
                          {lang === 'ar' ? 'رقم الطلب' : 'Order code'}
                        </label>
                      </div>
                      {error && (
                        <div className="input-feedback">
                          {lang === 'ar'
                            ? 'رقم الطلب غير متوفر'
                            : 'Order not found'}
                        </div>
                      )}
                    </div>
                    <CustomBtn
                      color={color}
                      type="submit"
                      onClick={() => {}}
                      text={lang === 'ar' ? 'تابع الطلب' : 'Look Up Order'}
                      disabled={!dirty}
                    />
                  </form>
                )
              }}
            </Formik>
          </div>
        </div>
        <style>{`.error.field-container{border-bottom: 1px solid ${color}} 
        .error-label{color:${color} !important}`}</style>
      </>
    )
  }
}
export default OrderStatus
