import React from 'react'
import { store, persistor } from 'store/index'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import MainRouter from 'view'
import 'assets/scss/index.scss'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainRouter />
      </PersistGate>
    </Provider>
  )
}

export default App
