import React from 'react'
import GoogleMapReact from 'google-map-react'
import Header from 'components/public/pageHeader'
import CustomBtn from 'components/public/button'

const Marker = () => {
  return (
    <svg
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      viewBox="0 0 81.8 100"
    >
      <defs>
        <style>
          {`  .cls-1 {
        isolation: isolate;
      }

      .cls-2 {
        fill: #d60000;
      }

      .cls-3 {
        fill: url(#radial-gradient);
      }

      .cls-4 {
        mix-blend-mode: overlay;
        fill: url(#linear-gradient);
      }`}
        </style>
        <radialGradient
          id="radial-gradient"
          cx="40.9"
          cy="50"
          r="45.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" stop-opacity="0" />
          <stop offset="1" stop-opacity="0.2" />
        </radialGradient>
        <linearGradient
          id="linear-gradient"
          x1="26.61"
          y1="21.83"
          x2="55.01"
          y2="59.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset="0.2" stop-color="#fff" stop-opacity="0.92" />
          <stop offset="0.6" stop-color="#fff" stop-opacity="0.72" />
          <stop offset="1" stop-color="#fff" stop-opacity="0.5" />
        </linearGradient>
      </defs>
      <title>map_pin</title>
      <g class="cls-1">
        <g id="color">
          <path
            class="cls-2"
            d="M40.9,0A40.82,40.82,0,0,0,0,40.9C0,73.1,40.9,100,40.9,100S81.8,73.1,81.8,40.9A40.82,40.82,0,0,0,40.9,0Z"
          />
        </g>
        <g id="main">
          <path
            class="cls-3"
            d="M40.9,0A40.82,40.82,0,0,0,0,40.9C0,73.1,40.9,100,40.9,100S81.8,73.1,81.8,40.9A40.82,40.82,0,0,0,40.9,0Z"
          />
          <circle class="cls-4" cx="40.9" cy="41" r="32.22" />
        </g>
      </g>
    </svg>
  )
}

class Location extends React.PureComponent {
  state = { lat: 29.292698726397433, lng: 48.007314842550386 }
  handleMapClick = e => {
    this.setState({ lat: e.lat, lng: e.lng })
  }
  render() {
    const center = {
      lat: 29.292698726397433,
      lng: 48.007314842550386,
      zoom: 20
    }
    const mapStyle = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ]
    const { lat, lng } = this.state
    const { color, lang } = this.props
    return (
      <>
        <Header
          text={lang === 'ar' ? 'أضف تفاصيل التوصيل' : 'Add delivery details'}
          url="/checkout/delivery"
        />
        <div className="white-bg">
          <div className="white-bg mb-3" style={{ marginTop: '48px' }}>
            <div class="ltr-1fqqef4 e1i3i4pb0">
              <div class="ltr-1tbs9m9 e1i3i4pb1">
                <input
                  type="address"
                  required=""
                  placeholder={
                    lang === 'ar'
                      ? 'إبحث عن منطقة، قطعة، شارع'
                      : 'Search for area, block, street'
                  }
                  class="e1mim1e70 ltr-xauasu e1i3i4pb2 pac-target-input"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="ltr-11xvhlv e1ia0kni0 white-bg"></div>
          <p class="ltr-ptzb68 e1iliclz0 mb-0">
            {lang === 'ar'
              ? 'ساعد سائقك في العثور عليك بشكل أسرع من خلال تحديد موقعك بدقة.'
              : 'Help your driver find you faster by providing an accurate location.'}
          </p>
        </div>

        <div className="w-100" style={{ height: '90%', overflowY: 'hidden' }}>
          <GoogleMapReact
            defaultCenter={center}
            defaultZoom={center.zoom}
            onClick={e => this.handleMapClick(e)}
            setOptions={{ styles: mapStyle }}
          >
            <Marker lat={lat} lng={lng} />
          </GoogleMapReact>
        </div>
        <CustomBtn
          text={lang === 'ar' ? 'إحفظ الموقع' : 'Save Location'}
          isLink
          url="/checkout/delivery"
          color={color}
        />
      </>
    )
  }
}

export default Location
