import React from 'react'

class Intro extends React.PureComponent {
  render() {
    const { url, title, desc, price, lang } = this.props
    return (
      <>
        <div className="ltr-1079k8q">
          <img src={url} className="ltr-1vttx28" alt={title} />
        </div>
        <div className="p-3 white-bg">
          <h2 className="text-uppercase ltr-1i60a19">{title}</h2>
          <h3 className="ltr-18j2v0h">{desc}</h3>
        </div>
        <hr />
        <div className=" p-3 d-flex white-bg justify-content-between">
          <h2 className="ltr-1i60a19">{lang === 'ar' ? 'السعر' : 'Price'}</h2>
          <h3 className="ltr-1rn4h86">
            {lang === 'en' && 'KWD   '}
            {Number(price)} {lang === 'ar' && '  د.ك'}
          </h3>
        </div>
        <hr />
      </>
    )
  }
}
export default Intro
