import appLocaleData from 'react-intl/locale-data/ar'
import arMessages from 'utilities/lang/locales/ar-KW'

const ArLang = {
  messages: { ...arMessages },
  locale: 'ar-KW',
  data: appLocaleData,
}

export default ArLang
