import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky'
import items, { categories } from './data'
import _ from 'lodash'
import Header from './Header'

class CategoryList extends React.PureComponent {
  state = {
    loading: true,
    data: [],
    catMenu: false,
    activeCat: null,
    activeCat: null,
    currentCart: []
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false, data: items })
    }, 1000)
    const cart = JSON.parse(localStorage.getItem('merchantCart'))
    if (cart) {
      this.setState({ currentCart: cart })
    }
  }

  submitOrder = (mainPrice, price, id, title, count, sum, extra, more) => {
    const {
      getChangeCartActionRequest,
      ChangeTotalCartActionRequest
    } = this.props
    const { currentCart } = this.state

    let cart = currentCart
    let currentProduct = {}
    let currentAvailable = false

    currentProduct.mainPrice = mainPrice
    currentProduct.price = Number(price)
    currentProduct.id = id
    currentProduct.title = title
    currentProduct.count = count
    currentProduct.sum = Number(sum)
    currentProduct.extra = extra
    currentProduct.more = more

    if (!_.isEmpty(cart)) {
      cart.map(item => {
        if (item.id == id) {
          item.count = count + item.count
          item.price = Number(item.price) + Number(price)
          currentAvailable = true
        }
      })
    }
    if (!currentAvailable) {
      cart.push(currentProduct)
    }
    getChangeCartActionRequest(cart)
    ChangeTotalCartActionRequest(price, count)
  }

  renderItems = items => {
    const { loading, currentCart } = this.state
    const { lang } = this.props
    var NewItems = items
    if (!_.isEmpty(currentCart)) {
      var presents = _.intersectionWith(
        NewItems,
        currentCart,
        (i, j) => i.id == j.id
      )
      let cartIds = []
      presents.map(i => {
        cartIds.push(i.id)
      })
      NewItems.map(item => {
        if (_.includes(cartIds, item.id)) {
          currentCart.map(i => {
            if (item.id == i.id) {
              item.count = i.count
              item.active = true
            }
          })
        } else {
          item.active = false
          item.count = 0
        }
      })
    }

    return (
      <>
        <ListGroup>
          {items.map(item => (
            <>
              <ListGroupItem
                action
                active={item.count !== 0}
                className="p-3"
                key={item.id}
                id={`list-item-${item.id}`}
                onClick={() => {}}
              >
                <Link to={`/item/${item.id}`} className="link">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div className="d-flex">
                        <span
                          className={`text-danger ${item.count == 0 &&
                            'display-none'}  count-cart ${loading &&
                            'skeleton'}`}
                          id={`list-count-${item.id}`}
                        >
                          {item.count}x
                        </span>
                        <h3
                          className={`ltr-1xe9geg  m-0 p-0  w-100 ${loading &&
                            'skeleton'}`}
                        >
                          {item.title}
                        </h3>
                      </div>
                      <div>
                        <h4
                          className={`product-desc mb-0 mt-2 text-justify ${loading &&
                            'skeleton'}`}
                        >
                          {item.desc}
                        </h4>
                        {item.extra ? (
                          <p className={`extra-info ${loading && 'skeleton'}`}>
                            {item.extra}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                      <button
                        onClick={e => {
                          if (!item.required && !item.condiments) {
                            e.preventDefault()
                            const element = document.getElementById(
                              `list-item-${item.id}`
                            )
                            const elementCount = document.getElementById(
                              `list-count-${item.id}`
                            )
                            let currentCount = elementCount.innerHTML
                            const newCurrentCount = Number(
                              currentCount.replace('x', '')
                            )
                            if (element && elementCount) {
                              element.classList.add('active')
                              elementCount.classList.remove('display-none')
                              elementCount.innerHTML = `${newCurrentCount + 1}x`
                            }

                            this.submitOrder(
                              item.price,
                              item.price,
                              item.id,
                              item.title,
                              1,
                              item.price,
                              null,
                              null
                            )
                          }
                        }}
                        className={`btn product-btn mt-3 bg-white ${loading &&
                          'skeleton'}`}
                      >
                        {lang === 'en' && 'KWD   '}
                        {Number(item.price).toFixed(3)}{' '}
                        {lang === 'ar' && '  د.ك'}
                        {!item.required && !item.condiments && (
                          <i
                            className="ltr-1xtaxlx e1i5aqxk0 px-2"
                            style={{ fontSize: '15px' }}
                          >
                            <svg width="1em" height="1em" viewBox="0 0 13 16">
                              <path d="M12.321 12.195a.683.683 0 010 1.366h-1.746v1.756a.679.679 0 11-1.358 0v-1.756H7.471a.683.683 0 010-1.366h1.746V10.44a.679.679 0 111.358 0v1.76h1.746zM10.7 2.927a1.309 1.309 0 011.329 1.29.882.882 0 010 .09L11.8 7.561a.68.68 0 11-1.355-.1l.224-3.17H1.651a.683.683 0 01-.074 0 .683.683 0 01-.075 0h-.13l.56 6.586a2.436 2.436 0 002.629 1.8.683.683 0 010 1.366c-2.068 0-3.832-1.3-3.981-3.054L.015 4.36a1.153 1.153 0 01.206-.954 1.481 1.481 0 011.279-.48.584.584 0 01.073.005.683.683 0 01.074 0h1.33a.684.684 0 01-.065-.293A2.627 2.627 0 015.53 0h.97a2.627 2.627 0 012.62 2.634.684.684 0 01-.065.293H10.7zm-2.938-.293A1.265 1.265 0 006.5 1.366h-.97a1.265 1.265 0 00-1.26 1.268.684.684 0 01-.065.293h3.622a.684.684 0 01-.065-.293z"></path>
                            </svg>
                          </i>
                        )}
                      </button>
                    </div>
                    <div className={` ${loading && 'skeleton'}`}>
                      <img src={item.img} className="img" alt={item.id} />
                    </div>
                  </div>
                </Link>
              </ListGroupItem>
            </>
          ))}
        </ListGroup>
      </>
    )
  }

  handleShow = i => {
    const element = document.getElementById(`list-${i}`)
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    })
  }
  render() {
    const { color, lang } = this.props
    return (
      <>
        {items.map(item => (
          <div
            className="category-section"
            role="button"
            tabIndex="0"
            key={item.id}
            id={`list-${item.category_id}`}
            ref={this.refs[item.category_id]}
          >
            <StickyContainer key={item.id}>
              <Sticky>
                {({ style }) => (
                  <Header
                    ref={item.category_id}
                    style={{ ...style }}
                    text={
                      lang === 'ar' ? item.category_name_ar : item.category_name
                    }
                    onClick={() =>
                      this.setState({
                        catMenu: true,
                        activeCat: item.category_id
                      })
                    }
                  />
                )}
              </Sticky>
              {this.renderItems(item.items)}
            </StickyContainer>
          </div>
        ))}
        <div style={{ backgroundColor: '#f5f5f9', height: '200px' }} />
        {this.state.catMenu && (
          <nav className="ltr-uf4fm0 e1b3lgmq0">
            <div
              className="ltr-176x4ze e6wsqwp0"
              onClick={() => this.setState({ catMenu: false })}
            >
              <h1 className="ltr-1lj26yz e1iliclz0 m-0 p-0">
                {lang === 'ar' ? 'أقسام القائمة' : 'Menu Sections'}
              </h1>
              <button
                aria-label="Go back to the menu"
                className="eve3u80 ltr-1snk7vm e1b3lgmq0"
              >
                <i>
                  <svg width="1em" height="1em" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h32v32H0z"></path>
                      <path
                        fill="currentColor"
                        d="M21.293 19.707L16 14.414l-5.293 5.293a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 11-1.414 1.414z"
                      ></path>
                    </g>
                  </svg>
                </i>
              </button>
            </div>
            <ul className="ltr-de47eb e6q2xso0">
              {categories.map(item => (
                <li
                  role="button"
                  tabindex="0"
                  className={`ltr-1bvpidu e18235w40 ${this.state.activeCat ===
                    item.category_id && 'active_cat'}`}
                  onClick={() => {
                    this.setState({ catMenu: false })
                    this.handleShow(item.category_id)
                  }}
                >
                  <div className="ltr-1mg3f9m e1b3lgmq0">
                    <h2 className="ltr-1gvv4e3 e1iliclz0 m-0 p-0">
                      {lang === 'en'
                        ? item.category_name
                        : item.category_name_ar}
                    </h2>
                    <span className="ltr-1rn4h86 e1iliclz0">{item.count}</span>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        )}
        <style>
          {`.active_cat::before {
            background: ${color}
        }`}
        </style>
      </>
    )
  }
}
export default CategoryList
