import {
  changeLangActionFetching,
  changeLangActionSuccess,
} from './actionCreators'

const getChangeLangActionRequest = (lang) => (dispatch) => {
  dispatch(changeLangActionFetching())
  localStorage.setItem('merchantLang', lang)
  dispatch(changeLangActionSuccess(lang))
}

export default getChangeLangActionRequest
