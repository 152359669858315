import React from 'react'
import CheckoutLayout from 'components/checkout'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import knet from 'assets/img/knet.png'
import master from 'assets/img/master.png'
import CustomBtn from 'components/public/button'

class CheckoutConfirm extends React.PureComponent {
  state = {
    currentCart: JSON.parse(localStorage.getItem('merchantCart')),
    mode: null
  }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.order_mode) {
      this.setState({ mode: orderMode.order_mode })
    }
  }

  renderReq = data => {
    return (
      <div className="mt-3">
        {data.map(item => (
          <div
            className="d-flex justify-content-between req-section"
            key={item.name}
          >
            <p className="ltr-1y4p49f mb-2">{item.name}</p>
            <small className="ltr-1o15x0g">-</small>
          </div>
        ))}
      </div>
    )
  }

  renderCon = data => {
    return data.map(item => (
      <div
        className="d-flex justify-content-between req-section"
        key={item.value}
      >
        <p className="ltr-1y4p49f mb-2">{item.value}</p>
        <small className="ltr-1o15x0g">
          KWD {Number(item.cost).toFixed(2)}
        </small>
      </div>
    ))
  }
  renderItems = () => {
    const { currentCart } = this.state
    const { lang } = this.props
    return (
      <>
        {currentCart.map(item => (
          <div className="p-3 white-bg" key={item.title}>
            <div className="d-flex justify-content-between rtl-qcod0n">
              <div className="ltr-1gxuiwm-checkout">
                <span className="count-cart">{item.count}x</span>
                <p className="px-3 rtl-5w76og cart-title py-0 m-0">
                  {item.title}
                </p>
              </div>
              <p class="rtl-wtvwk0 e1iliclz0 m-0">
                {lang === 'en' && 'KWD   '}
                {Number(item.mainPrice).toFixed(2)}
                {lang === 'ar' && '  د.ك'}
              </p>
            </div>
            {item.extra && !_.isEmpty(item.extra.requirements)
              ? this.renderReq(item.extra.requirements)
              : ''}

            {item.extra && !_.isEmpty(item.extra.condiments)
              ? this.renderCon(item.extra.condiments)
              : ''}
            {item.more ? (
              <p className="ltr-1y4p49f confirm-more">{item.more}</p>
            ) : (
              ''
            )}
          </div>
        ))}
      </>
    )
  }

  renderDelivery = () => {
    const { mode } = this.state
    const { lang } = this.props
    const data = [
      {
        id: 1,
        deliver_time: '15 mins',
        deliver_time_ar: '15 دقیقه'
      }
    ]
    let contactForm = JSON.parse(localStorage.getItem('checkoutContact'))
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    return (
      <div className="pt-3">
        <span className="ltr-14siyw e1iliclz0 p-3">
          {mode === 'delivery'
            ? lang === 'ar'
              ? 'معلومات التوصيل'
              : 'Delivery Info'
            : lang === 'ar'
            ? 'معلومات الإستلام بالسيارة'
            : 'Car Pickup Info'}
        </span>
        <Link to="/time" style={{ color: '#000' }}>
          <div className="p-3  d-flex white-bg items-container justify-content-between">
            <div className="d-flex">
              <span className="icon-clock delivery-icons my-auto" />
              <span className="mx-3 ltr-1ibwh4p" style={{ fontSize: '15px' }}>
                {orderMode && orderMode.day && orderMode.day_ar
                  ? lang === 'ar'
                    ? orderMode.day_ar
                    : orderMode.day
                  : ''}{' '}
                {` `}
                {orderMode &&
                orderMode.selected_time &&
                orderMode.selected_time_ar
                  ? lang === 'ar'
                    ? orderMode.selected_time_ar
                    : orderMode.selected_time
                  : lang === 'ar'
                  ? data[0].deliver_time_ar
                  : data[0].deliver_time}
              </span>
            </div>
            <span className="icon-arrow_right checkout-arrow" />
          </div>
        </Link>
        {mode === 'pickup' ? (
          <Link to="/checkout/pickup" style={{ color: '#000' }}>
            <div className="p-3  d-flex white-bg items-container  justify-content-between">
              <div className="d-flex">
                <i className="ltr-14g83yz e1i5aqxk0 my-auto">
                  <svg width="1em" height="1em" viewBox="0 0 35 35">
                    <path
                      fill="none"
                      stroke="#4A4A4A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.4"
                      d="M28.227 6H7.772L6 14.518C6 15.89 7.073 17 8.4 17c1.326 0 2.4-1.11 2.4-2.482C10.8 15.89 11.872 17 13.198 17s2.402-1.11 2.402-2.482C15.6 15.89 16.675 17 18 17c1.324 0 2.399-1.11 2.399-2.482C20.4 15.89 21.474 17 22.8 17c1.325 0 2.4-1.11 2.4-2.482C25.2 15.89 26.275 17 27.6 17s2.4-1.11 2.4-2.482L28.227 6zm.394 12.625v8.766c0 .681-.58 1.234-1.297 1.234H8.917c-.716 0-1.296-.553-1.296-1.234v-8.766m8 10h5v-7h-5v7z"
                    ></path>
                  </svg>
                </i>
                <div className="d-flex align-items-start flex-column">
                  <span className="mx-3 ltr-1ibwh4p">
                    {orderMode && orderMode.branch_enName}
                  </span>
                </div>
              </div>
              <span className="icon-arrow_right checkout-arrow my-auto" />
            </div>
          </Link>
        ) : (
          <Link to="/checkout/delivery" style={{ color: '#000' }}>
            <div className="p-3  d-flex white-bg items-container  justify-content-between">
              <div className="d-flex">
                <span
                  className={`${
                    contactForm.unitType === 'house'
                      ? 'icon-home'
                      : contactForm.unitType === 'apartment'
                      ? 'icon-appartment'
                      : contactForm.unitType === 'office'
                      ? 'icon-briefcase'
                      : null
                  } delivery-icons my-auto`}
                />
                {mode === 'delivery' && (
                  <div className="d-flex align-items-start flex-column">
                    <span className="mx-3 ltr-1ibwh4p">
                      {orderMode && orderMode.area_enName
                        ? lang === 'en'
                          ? orderMode.area_enName
                          : orderMode.area_arName
                        : ''}
                    </span>
                    <span className="mx-3 ltr-1o15x0g">
                      Block {contactForm.block} ,Avenue {contactForm.avenue}{' '}
                      ,Street
                      {contactForm.street}
                      {contactForm.houseNumber
                        ? `,House No. ${contactForm.houseNumber}`
                        : null}
                      {contactForm.apartmentNumber
                        ? `,Apartment No. ${contactForm.apartmentNumber}`
                        : null}
                      {contactForm.officeNumber
                        ? `,Office No. ${contactForm.officeNumber}`
                        : null}
                      {contactForm.building ? `,${contactForm.building}` : null}
                      {contactForm.floor ? `,Floor ${contactForm.floor}` : null}
                      {contactForm.specialDirection
                        ? `,${contactForm.specialDirection}`
                        : null}
                    </span>
                  </div>
                )}
              </div>
              <span className="icon-arrow_right checkout-arrow my-auto" />
            </div>
          </Link>
        )}
        {mode === 'pickup' ? (
          <Link to="/checkout/contact" style={{ color: '#000' }}>
            <div className="p-3  d-flex white-bg items-container justify-content-between">
              <div className="d-flex">
                <i className="ltr-14g83yz e1i5aqxk0 my-auto">
                  <svg width="1em" height="1em" fill="none" viewBox="0 0 22 18">
                    <path
                      fill="#4A4A4A"
                      stroke="#4A4A4A"
                      strokeWidth="0.1"
                      d="M18.316 2.185h0A1.845 1.845 0 0016.6.95h0H5.67c-.766 0-1.45.496-1.718 1.235h0L2.448 6.386l-.619.593A2.625 2.625 0 00.95 8.947v6.214h0c.004 1.043.824 1.889 1.836 1.889h1.129c1.011 0 1.831-.846 1.836-1.889v0-.851h10.498v.851h0c.005 1.043.825 1.889 1.837 1.889h1.128c1.012 0 1.832-.846 1.836-1.889v0-6.378h0a2.656 2.656 0 00-.779-1.873l-.428-.469-1.527-4.256zm.898 11.14h0H2.786h0a.885.885 0 01-.881-.895V8.947c0-.472.203-.922.551-1.226h0l.003-.003.009-.01.008-.008.566-.55h16.14l.393.435.01.012.01.008v.001l.007.007c.31.304.489.728.489 1.17v3.697h.002c-.029.476-.416.85-.879.846zm0 .05H2.786h16.428zM4.85 2.52s0 0 0 0a.876.876 0 01.82-.586h10.933c.362 0 .69.234.82.586l1.31 3.645h-.882c-.258-1.66-1.762-2.803-3.38-2.554-1.287.196-2.3 1.233-2.505 2.554H3.54L4.85 2.52zm10.473 2.082a2.066 2.066 0 011.55 1.563h-3.94c.256-1.101 1.314-1.795 2.39-1.563zM4.796 15.162c-.004.503-.398.904-.881.904H2.786c-.483 0-.877-.4-.881-.905v-1.068c.254.146.569.217.881.217h2.01v.851zm15.3 0c-.005.503-.398.904-.882.904h-1.128c-.484 0-.878-.405-.882-.905 0 0 0 0 0 0v-.851h2.01c.312 0 .627-.07.881-.217v1.068s0 0 0 0z"
                    ></path>
                    <path
                      fill="#4A4A4A"
                      stroke="#4A4A4A"
                      strokeWidth="0.1"
                      d="M6.64 8.817H3.437a.486.486 0 00-.478.492v2.299c0 .269.213.492.478.492H6.64a.486.486 0 00.477-.492V9.309a.486.486 0 00-.477-.492zm-.477 2.299h-2.25V9.8h2.25v1.315zm12.401-2.299H15.36a.486.486 0 00-.477.492v2.299c0 .269.213.492.477.492h3.205a.486.486 0 00.477-.492V9.309a.486.486 0 00-.477-.492zm-.477 2.299h-2.25V9.8h2.25v1.315zm-5.023-.442H8.936c-.264 0-.477.223-.477.492s.213.492.477.492h4.128a.486.486 0 00.477-.492.485.485 0 00-.477-.492zm0-1.37H8.936a.485.485 0 00-.477.492c0 .269.213.492.477.492h4.128a.486.486 0 00.477-.492.485.485 0 00-.477-.492z"
                    ></path>
                  </svg>
                </i>
                <div className="d-flex align-items-start flex-column">
                  <span className="mx-3 ltr-1ibwh4p">
                    {contactForm && contactForm.carMake}
                  </span>
                  <span className="mx-3 ltr-1o15x0g">
                    {contactForm && contactForm.carColor}
                    {`, ${contactForm && contactForm.carLicence}`}
                  </span>
                </div>
              </div>
              <span className="icon-arrow_right checkout-arrow my-auto" />
            </div>
          </Link>
        ) : (
          <Link to="/checkout/contact" style={{ color: '#000' }}>
            <div className="p-3  d-flex white-bg items-container justify-content-between">
              <div className="d-flex">
                <span className="icon-phone-call-outline delivery-icons my-auto" />
                <div className="d-flex align-items-start flex-column">
                  <span className="mx-3 ltr-1ibwh4p">{contactForm.name}</span>
                  <span className="mx-3 ltr-1o15x0g">{contactForm.phone}</span>
                </div>
              </div>
              <span className="icon-arrow_right checkout-arrow my-auto" />
            </div>
          </Link>
        )}
      </div>
    )
  }

  renderPayment = () => {
    let contactForm = JSON.parse(localStorage.getItem('checkoutContact'))
    const { lang } = this.props
    return (
      <Link to="/checkout/payment" style={{ color: '#000' }}>
        <div className="white-bg pt-3">
          <span className="ltr-14siyw e1iliclz0 p-3">
            {lang === 'ar' ? 'طريقة الدفع' : 'Payment method'}
          </span>
          <div className="p-3  d-flex white-bg items-container justify-content-between">
            <div className="d-flex">
              <img
                src={contactForm.paymentMethod == 'knet' ? knet : master}
                className="confirm-payment-icon my-auto"
                alt="pay"
              />
              <span className="mx-3">
                {contactForm.paymentMethod == 'knet'
                  ? lang === 'ar'
                    ? 'كي نت'
                    : 'KNET'
                  : (lang === 'ar') === 'ar'
                  ? 'بطاقة إئتمان'
                  : 'CREDIT CART'}
              </span>
            </div>
            <span className="icon-arrow_right checkout-arrow" />
          </div>
        </div>
      </Link>
    )
  }
  renderTotal = () => {
    const { mode } = this.state
    const { lang } = this.props
    let totalCart = localStorage.getItem('merchantTotalPriceCart')
    return (
      <>
        <div className="white-bg pt-3">
          {mode === 'delivery' && (
            <>
              <div className="px-3 py-1 d-flex white-bg justify-content-between">
                <small className="ltr-1y4p49f">
                  {lang === 'ar' ? 'مجموع الطلب' : 'Subtotal'}
                </small>
                <small className="ltr-1y4p49f">
                  {lang === 'en' && 'KWD   '}
                  {Number(totalCart).toFixed(2)}
                  {lang === 'ar' && '  د.ك'}
                </small>
              </div>
              <div className="px-3 py-1 d-flex white- justify-content-between">
                <small className="ltr-1y4p49f">
                  {lang === 'ar' ? 'رسوم التوصيل' : 'Delivery'}
                </small>
                <small className="ltr-1y4p49f">
                  {lang === 'en' && 'KWD   '}
                  {Number(totalCart).toFixed(2)}
                  {lang === 'ar' && '  د.ك'}
                </small>
              </div>
            </>
          )}
          <div className="p-3 d-flex white- justify-content-between">
            <h1 className="ltr-14siyw">
              {lang === 'ar' ? 'المجموع' : 'Total'}
            </h1>
            <p className="ltr-14siyw">
              {lang === 'en' && 'KWD   '} {Number(totalCart).toFixed(2)}
              {lang === 'ar' && '  د.ك'}
            </p>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { currentCart } = this.state
    const { color, lang } = this.props
    return (
      <CheckoutLayout
        activeLink="/checkout/contact"
        url="/checkout/payment"
        activeStep2
        activeStep3
        activeStep4
      >
        <Link to="/order">
          <div className="pt-3 px-3  d-flex white-bg  justify-content-between">
            <span className="ltr-14siyw e1iliclz0">
              {lang === 'ar' ? 'الطلبات' : 'Order Items'}
            </span>
            <span className="icon-arrow_right checkout-arrow" />
          </div>
        </Link>
        {currentCart ? this.renderItems() : null}
        <hr />
        {this.renderDelivery()}
        <hr />
        {this.renderPayment()}
        <hr />
        {this.renderTotal()}
        <div className="wite-bg gap" />
        <CustomBtn
          color={color}
          type="submit"
          onClick={() => {}}
          text={lang === 'ar' ? 'إتمام الطلب' : 'Place Order'}
          isLink
          url={`/status/${'SDSF-CXDZ'}`}
        />
      </CheckoutLayout>
    )
  }
}

export default CheckoutConfirm
