import { connect } from 'react-redux'
import BranchDetail from './BranchDetail'
import { setDeliveryModeActionRequest } from 'store/actions'

const mapStateToProps = () => ({
  color: '#d80417',
})

const mapDispatchToProps = (dispatch) => ({
  setDeliveryModeActionRequest: (body) =>
    dispatch(setDeliveryModeActionRequest(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetail)
