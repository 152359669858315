export default {
  Home: 'بيت',
  Learn: 'تعلم',
  Shop: 'متجر',
  'Contact us': 'اتصل بنا',
  Careers: 'وظائف',
  Wholesale: 'المحصولات',
  Search: 'بحث',
  Lang: 'لغة',
  'Log In': 'الدخول',
  Empty: 'فارغة',
  'CHOOSE FROM': 'اختر من',
  'OUR LATEST PRODUCTS': 'أحدث منتجاتنا',
  'VIEW ALL': 'الکتکوری',
  'MORE INFO': 'مزيد من المعلومات',
  'OUR LATEST': 'أحدث',
  'BLOG POSTS': 'وظائف المدونة',
  'READ MORE': 'الجک اوت',
  'ALL RIGHTS RESERVED 2019': 'جميع الحقوق محفوظة 2019',
  'STAY CONNECTED WITH US': 'ابق على اتصال معنا',
  'DESIGNED AND DEVELOPED BY': 'صمم و طور بواسطة',
  'Roasters Recipes': 'وصفات الشواية',
  'Roasting Memories': 'تحميص الذكريات',
  Events: 'الأحداث',
  Blog: 'مدونة',
  'Our Story': 'قصتنا',
  'F.A.Q': 'F.A.Q',
  'FILTER RESULTS': 'تصفية النتائج',
  'PRODUCT USAGE': 'استخدام المنتج',
  PRICE: 'السعر',
  BRANDS: 'العلامات التجارية',
  CATEGORIES: 'التصنيفات',
  OUR: 'لنا',
  PRODUCTS: 'منتجات',
  'ADD TO CART': 'أضف إلى السلة',
  WEIGHT: 'وزن',
  DIMENSIONS: 'الأبعاد',
  SKU: 'SKU',
  CATEGORY: 'الفئة',
  SHARE: 'شارك',
  'YOU MIGHT LIKE THESE PRODUCT TOO': 'قد يعجبك هذا المنتج أيضًا',
  'PAYMENT SUMMARY': 'ملخص الدفع',
  CHECKOUT: 'الدفع',
  'Sub Total': 'الدلیوری',
  Profit: 'ربح',
  'Delivery Fee': 'رسوم التوصيل',
  Total: 'مجموع',
  REMOVE: 'إزالة',
  DELIVERY: 'توصيل',
  NAME: 'اسم',
  'COUPON CODE': 'رمز الكوبون',
  Remove: 'إزالة',
  'PAYMENT METHOD': 'طريقة الدفع او السداد',
  'SEARCH HERE': 'ابحث هنا',
  'CONTACT US': 'اتصل بنا',
  'SEND US': 'السند',
  'Your name': 'اسمك',
  'Your address': 'عنوانك',
  'Phone number': 'رقم الهاتف',
  'Title of message': 'عنوان الرسالة',
  'SEND MESSAGE': 'إرسال رسالة',
  'INTEREST IN BUYING IN BULK': 'الاهتمام بالشراء بالجملة',
  'Your company name': 'اسم شركتك',
  'Altitude Roasters': 'محمصات الارتفاع',
  'LOGIN WITH GOOGLE': 'تسجيل الدخول عبر جوجل',
  'Forget password': 'نسيت كلمة المرور',
  LOGIN: 'الدخول',
  'creating an account means that you agree with our':
    'إنشاء حساب يعني موافقتك على',
  'terms and services': 'الشروط والخدمات',
  'CREATE ACCOUNT': 'إنشاء حساب',
  'will deliver at': 'سوف يسلم في',
  'WELCOME BACK!': 'مرحبا بعودتك!',
  'ACCOUNT DETAIL': 'تفاصيل الحساب',
  'ORDER HISTORY': 'تاريخ الطلب',
  'IN PROGRESS': 'في تقدم',
  COMPLETED: 'منجز',
  date: 'تاريخ',
  'order name': 'اسم الطلب',
  total: 'مجموع',
  Qty: 'الكمية',
  item: 'بند',
  weight: 'الوزن',
  Price: 'السعر',
  'Total Price': 'السعر الكلي',
  'Coupon Code': 'رمز الكوبون',
  "WE'VE BEEN LOOKING FOR YOU": 'لقد بحثنا عنك',
  'FILL OUT THE FORM TO JOIN US': 'املأ النموذج للانضمام إلينا',
  'SUBMIT FILTER': 'مرشح التقديم',
  'FILTER BY GOVERNORATE': 'تصفية حسب المحافظة',
  'LOAD MORE': 'تحميل المزيد',
  'NO EVENT YET': 'أي حدث حتى الآن',
  'HOW IT ALL STARTED': 'كيف بدأ كل شيء',
  'WHERE WE ARE NOW': 'أين نحن الآن',
  'OUR VISION FOR THE FUTURE': 'رؤيتنا للمستقبل',
  'FIND YOUR': 'تجد الخاص بك',
  'ANSWERS HERE': 'الإجابات هنا',
  'GENERAL QUESTIONS': 'اسئلة عامة',
  'NO RESULT': 'لا نتيجة',
  'Search for your question here': 'ابحث عن سؤالك هنا',
  'YOUR MESSAGE': 'رسالتك',
  'VIA THESE PLATFORMS': 'عن طريق هذه المنصات',
  'Position interested in': 'الوظيفة المهتمة',
  'Upload your CV here': 'قم بتحميل سيرتك الذاتية هنا',
  Upload: 'رفع',
  'INTEREST IN BUYING IN BULK': 'الاهتمام بالشراء بالجملة',
  'INFORM US ABOUT YOUR COMPONY': 'أبلغنا عن شركتك',
  DONE: 'فعله',
  TRENDING: 'الشائع',
  PRODUCTS: 'منتجات',
  OUR: 'لنا',
  'WELCOME TO': 'مرحبا بك في',
  'ALTITUDE ROASTERS': 'أفران التحميص',
  'Please enter your registered email ID.':
    'يرجى إدخال معرف البريد الإلكتروني المسجل الخاص بك.',
  'We will send a link to change your password.':
    'سوف نرسل رابطًا لتغيير كلمة مرورك.',
  'Your email': 'بريدك الالكتروني',
  'SEND LINK': 'أرسل الرابط',
  'Email is invalid': 'البريد الإلكتروني غير صالح',
  'Email is required': 'البريد الالكتروني مطلوب',
  'SIGN UP': 'سجل',
  BACK: 'عودة',
  'Your email / phone number': 'Your email / phone number',
  password: 'كلمه السر',
  'SIGN IN': 'الدخول',
  "DON'T HAVE": 'لا تملك',
  'AN ACCOUNT YET ?': 'حساب حتى الآن؟',
  'Phone is required': 'الهاتف مطلوب',
  'Phone is invalid': 'الهاتف غير صالح',
  'Password must be at least 6 characters':
    'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل',
  'Password is required': 'كلمة المرور مطلوبة',
  'Passwords must match': 'يجب ان تتطابق كلمات المرور',
  'Confirm Password is required': 'تأكيد كلمة المرور مطلوب',
  'Password 6+ characters': 'كلمة المرور 6+ أحرف',
  'Confirm password': 'تأكيد كلمة المرور',
  'terms and services.': 'الشروط والخدمات.',
  'PLEASE ENTER': 'تفضل',
  'NEW PASSWORD': 'كلمة مرور جديدة',
  'CHANGE PASSWORD': 'تغيير كلمة السر',
  'WE HAVE BEEN LOOKING FOR YOU': 'لقد كنا نبحث عنك',
  'Name is required': 'مطلوب اسم',
  'Message is required': 'الرسالة مطلوبة',
  'Your message': 'رسالتك',
  'If you already have an account on our website, please':
    'إذا كان لديك بالفعل حساب على موقعنا ، من فضلك',
  register: 'تسجيل',
  'to checkout': 'للتحقق من',
  'WELCOME To': 'مرحبا بك في',
  'YOUR ADDRESS LIST IS EMPTY! PLEASE ADD AN ADDRESS':
    'قائمة عناوينك فارغة! يرجى إضافة عنوان',
  'show more addresses': 'إظهار المزيد من العناوين',
  'ADDRESS LIST': 'قائمة العناوين',
  'ADD NEW': 'اضف جديد',
  CHECKOUT: 'الدفع',
  'There are no items in your cart currently':
    'لا توجد عناصر في سلة التسوق الخاصة بك حاليا.',
  'BROWSE OUR SHOP': 'تصفح متجرنا',
  'Product removed from your cart': 'تمت إزالة المنتج من سلة التسوق الخاصة بك',
  or: 'أو',
  'ORDERS SUMMARY': 'ملخص الطلبات',
  'Coupon is not valid': 'القسيمة غير صالحة',
  'Coupon is valid !': 'الكوبون صالح!',
  'Coupon code here': 'رمز القسيمة هنا',
  Redeem: 'تخليص',
  DELIVERY: 'توصيل',
  'will deliver at ': 'سوف يسلم في',
  SEARCH: 'بحث',
  "OH WE'RE SORRY WE COULDN'T FIND WHAT YOU'RE LOOKING FOR":
    'عذرًا ، لم نتمكن من العثور على ما تبحث عنه',
  "If you're sure there must be something here, please contact us":
    'إذا كنت متأكدًا من وجود شيء هنا ، فيرجى الاتصال بنا',
  TAGS: 'العلامات',
  'Product added to your cart': 'وأضاف المنتج إلى سلة التسوق الخاصة بك',
  'YOU MIGHT LIKE THIS PRODUCT TOO': 'قد يعجبك هذا المنتج أيضًا',
  'YOUR ADDRESS LIST IS EMPTY! PLEASE ADD AN ADDRESS.':
    'قائمة عناوينك فارغة! يرجى إضافة عنوان.',
  'Edit address': 'تعديل العنوان',
  'Delete Address': 'حذف العنوان',
  'Are you sure about deleting this address?':
    'هل أنت متأكد من حذف هذا العنوان؟',
  'Yes! I,m sure.': 'نعم! أنا متأكد.',
  Cancel: 'إلغاء',
  'PERSONAL DETAIL': 'التفاصيل الشخصية',
  'Your Name': 'اسمك',
  'EDIT PROFILE': 'تعديل الملف الشخصي',
  'Your Phone': 'هاتفك',
  'Your Email': 'بريدك الالكتروني',
  New: 'جديد',
  Current: 'تيار',
  Confirm: 'تؤكد',
  'SAVE CHANGES': 'احفظ التغييرات',
  'NO COMPLETED ORDER YET': 'لم يكتمل الطلب حتى الآن',
  Done: 'منجز',
  Detail: 'التفاصيل',
  'NO IN PROGRESS ORDER': ' قيد التقدم لا',
  'Delivery Date': 'تاريخ التسليم او الوصول',
  'Payment Type': 'نوع الدفع',
  'POSTING MEMORIES': 'ذكريات النشر',
  'MADE ETERNAL': 'صناعة أبدية',
  'LEARN MORE': 'أعرف أكثر',
  'ABOUT ALL KIND OF BREWINGS': 'عن جميع أنواع السلالات',
  'Search In...': 'بحث في...',
  products: 'منتجات',
  blog: 'مدونة',
  events: 'الأحداث',
  roastings: 'تحميص',
  "This is something I'm searching for": 'هذا شيء أبحث عنه',
  'NOTHING FOUND': 'لا يوجد شيء',
  'show more': 'أظهر المزيد',
  'Submit Filters': 'إرسال الفلاتر',
  'Price (Descending)': 'السعر (تنازلي)',
  'Price (Ascending)': 'السعر (تصاعدي)',
  'Price order': 'ترتيب السعر',
  Title: 'عنوان',
  Block: 'منع',
  Street: 'شارع',
  Avenue: 'السبيل',
  Building: 'بناء',
  Floor: 'أرضية',
  'Extra Information': 'معلومات اضافية',
  SAVE: 'حفظ',
  Area: 'منطقة',
  'RELATED POSTS': 'المنشورات ذات الصلة',
  PREVIOUS: 'السابق',
  NEXT: 'التالى',
  'Thank You!': 'شكرا لكم!',
  'The form was submitted successfully': 'تم تقديم النموذج بنجاح',
  'Please choose payment method': 'يرجى اختيار طريقة الدفع',
  Knet: 'کی نت',
  'Cash on delivery': 'الدفع عند الاستلام',
  'Credit card': 'بطاقة الائتمان',
  SHARE: 'شارك',
  Profile: 'الشخصي',
  'Log out': 'خروج',
  EMPTY: 'فارغة',
  QTY: 'تعداد',
  Item: 'بند',
  'order code': 'رمز الطلب',
  'total items': 'إجمالي العناصر',
  'Monthly consumption is required': 'الاستهلاك الشهري مطلوب',
  'Monthly consumption (Kg)': 'الاستهلاك الشهري (Kg)',
}
