import React from 'react'
import data from 'components/home/components/categoryList/data'
import _ from 'lodash'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

class Search extends React.PureComponent {
  state = { searchValue: '', searchResult: [] }

  search = e => {
    this.setState({ searchValue: e.target.value })

    const result = []

    data.map(item => {
      const { items } = item
      items.map(i => {
        if (e.target.value === '') {
          this.setState({ searchResult: result })
        } else if (_.includes(i.title, e.target.value)) {
          result.push(i)
          this.setState({ searchResult: result })
        }
      })
    })
  }

  renderItems = items => {
    const { loading } = this.state
    const { lang } = this.props
    return (
      <>
        <ListGroup>
          {items.map(item => (
            <ListGroupItem
              action
              active={item.count !== 0}
              className="p-3"
              key={item.id}
              onClick={() => {}}
            >
              <Link to={`/item/${item.id}`} className="link">
                <div className="d-flex">
                  <div className="w-75">
                    <div className="d-flex">
                      {item.count !== 0 && (
                        <span
                          className={`text-danger  count-cart ${loading &&
                            'skeleton'}`}
                        >
                          {item.count}x
                        </span>
                      )}
                      <h3
                        className={`ltr-1xe9geg  m-0 p-0  w-100 ${loading &&
                          'skeleton'}`}
                      >
                        {item.title}
                      </h3>
                    </div>
                    <div>
                      <h4
                        className={`product-desc mb-0 mt-2 text-justify ${loading &&
                          'skeleton'}`}
                      >
                        {item.desc}
                      </h4>
                      {item.extra ? (
                        <p className={`extra-info ${loading && 'skeleton'}`}>
                          {item.extra}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <button
                      onClick={() => {}}
                      className={`btn product-btn mt-3 bg-white ${loading &&
                        'skeleton'}`}
                    >
                      {lang === 'en' && 'KWD   '}
                      {Number(item.price).toFixed(3)} {lang === 'ar' && '  د.ك'}
                    </button>
                  </div>
                  <div className={`w-25 ${loading && 'skeleton'}`}>
                    <img src={item.img} className="img" alt={item.id} />
                  </div>
                </div>
              </Link>
            </ListGroupItem>
          ))}
        </ListGroup>
      </>
    )
  }

  render() {
    const { color, lang } = this.props
    const { searchValue, searchResult } = this.state
    return (
      <div className="h-100 white-bg">
        <div className="ltr-1pvnlgv e6q2xso0">
          <div className="ltr-yxrjdm e1b3lgmq0">
            <div className="ltr-1fqqef4 e1i3i4pb0">
              <div className="ltr-1qg0orz e1i3i4pb1">
                <input
                  type="search"
                  required=""
                  placeholder={lang === 'ar' ? 'بحث' : 'Search'}
                  className="e1mim1e70 ltr-xauasu e1i3i4pb2"
                  value={searchValue}
                  onChange={e => this.search(e)}
                />
              </div>
            </div>
          </div>
          <Link className="ltr-r9p7n8 " to="/">
            {lang === 'ar' ? 'إلغاء' : 'Cancel'}
          </Link>
        </div>
        <div className="ltr-2qa8x9 e1ia0kni0" />
        <div className="ltr-1m5wpo6 e6q2xso0">
          {searchValue != '' && _.isEmpty(searchResult) ? (
            <p className="ltr-aoug8f mb-0 px-2">
              {lang === 'ar' ? 'لا توجد نتائج' : 'No results found'}
            </p>
          ) : searchValue === '' ? (
            <>
              <p className="ltr-11x4bkd e1iliclz0 mb-0 ">
                {lang === 'ar' ? 'البحث عن المنتجات' : 'Find products'}
              </p>
              <p className="ltr-1xbfmzv mb-0">
                {lang === 'ar'
                  ? 'أدخل ما تبحث عنه'
                  : 'Type in what you are looking for'}
              </p>
            </>
          ) : (
            <div className="category-section" role="button" tabIndex="0">
              {this.renderItems(searchResult)}
            </div>
          )}
        </div>
        <style>{`.ltr-r9p7n8{color:${color} !important}`}</style>
      </div>
    )
  }
}
export default Search
