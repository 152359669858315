import React from 'react'

class Header extends React.Component {
  static defaultProps = {
    className: '',
  }
  render() {
    const { style, className, text, onClick, ref } = this.props
    return (
      <div
        ref={ref}
        className={'header ' + className}
        style={{
          ...style,
          zIndex: '1000',
          top: '0',
        }}
        {...{
          onClick: () => {
            onClick()
          },
        }}
      >
        <div className="ltr-1rr0azy white-bg d-flex justify-content-between p-2 pl-3">
          <p className="ltr-14siyw mb-0" style={{ lineHeight: '2' }}>
            {text}
          </p>
          <span className="accordion-item__icon">
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 32 32"
              style={{ transform: 'rotate(180deg)' }}
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h32v32H0z"></path>
                <path
                  fill="currentColor"
                  d="M21.293 19.707L16 14.414l-5.293 5.293a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 11-1.414 1.414z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </div>
    )
  }
}
export default Header
