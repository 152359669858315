import React from 'react'
import Intro from 'components/Product/components/intro'
import Requirements from 'components/Product/components/requirments'
import CustomInput from 'components/public/customInput'
import Condiments from 'components/Product/components/condiments'
import PlusMinusBtn from 'components/public/plusMinusBtn'
import CustomBtn from 'components/public/button'
import Header from 'components/public/pageHeader'
import products from './data'
import _ from 'lodash'

class Product extends React.PureComponent {
  state = {
    product: null,
    currentCart: [],
    required: false,
    mainPrice: null,
    price: null,
    id: null,
    title: null,
    count: 1,
    sum: null,
    extra: {
      requirements: [],
      condiments: []
    },
    more: '',
    error: false,
    prevPath: null
  }

  componentDidMount() {
    const { history } = this.props

    const product_id = history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    )
    let cart = JSON.parse(localStorage.getItem('merchantCart'))
    if (cart) {
      this.setState({ currentCart: cart })
    }
    if (this.props.location.state) {
      const { route } = this.props.location.state
      this.setState({ prevPath: route })
      if (cart) {
        cart.map(item => {
          if (item.id == product_id) {
            if (item.extra) {
              this.setState({ extra: item.extra })
            }
            this.setState({
              count: item.count,
              more: item.more,
              price: item.price,
              sum: item.sum,
              mainPrice: item.mainPrice,
              title: item.title,
              id: item.id,
              more: item.more
            })
          }
        })
      }
      let product = null
      products.map(item => {
        if (item.id == product_id) {
          product = item
          this.setState({
            product
          })
        }
      })
    } else {
      let product = null
      products.map(item => {
        if (item.id == product_id) {
          product = item
          this.setState({
            product,
            mainPrice: item.price,
            price: item.price,
            id: item.id,
            title: item.title,
            sum: item.price
          })
        }
      })
    }

    window.addEventListener('scroll', this.handleScroll, true)
    const nav = document.getElementById('product-header')
    const path = document.getElementById('path')
    if (nav) {
      nav.classList.add('header-gradient-bg')
    }
    if (path) {
      path.style.fill = '#fff'
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const nav = document.getElementById('product-header')
    const path = document.getElementById('path')

    if (window.scrollY >= 10 && nav) {
      nav.classList.add('light')
      nav.classList.remove('header-gradient-bg')
      if (path) {
        path.style.fill = '#000'
      }
    } else if (nav) {
      nav.classList.remove('light')
      nav.classList.add('header-gradient-bg')
      if (path) {
        path.style.fill = '#fff'
      }
    }
  }

  increment = () => {
    this.setState(
      {
        count: this.state.count + 1
      },
      () => this.setState({ price: this.state.count * Number(this.state.sum) })
    )
  }

  decrease = () => {
    this.setState(
      {
        count: this.state.count - 1
      },
      () => this.setState({ price: this.state.count * Number(this.state.sum) })
    )
  }

  requirementsChange = (value, name) => {
    let extra = {
      requirements: [...this.state.extra.requirements],
      condiments: [...this.state.extra.condiments]
    }
    if (!_.isEmpty(extra.requirements)) {
      _.remove(extra.requirements, function(e) {
        return e.name === name
      })
    }
    extra.requirements.push({ value, name })
    this.setState({ extra })
  }

  condimentsChange = (checked, value, cost) => {
    let extra = {
      condiments: [...this.state.extra.condiments],
      requirements: [...this.state.extra.requirements]
    }
    if (!_.isEmpty(extra.condiments)) {
      _.remove(extra.condiments, function(e) {
        return e.value === value
      })
    }
    if (checked) {
      extra.condiments.push({ value, cost })
      this.setState(
        {
          extra,
          sum: Number(this.state.sum) + Number(cost)
        },
        () => this.setState({ price: this.state.count * this.state.sum })
      )
    } else if (!checked) {
      this.setState(
        {
          extra,
          sum: Number(this.state.sum) - Number(cost)
        },
        () =>
          this.setState({
            price: this.state.count * this.state.sum
          })
      )
    }
  }

  inputChange = value => {
    this.setState({ more: value })
  }

  submitOrder = () => {
    const {
      product,
      currentCart,
      mainPrice,
      price,
      id,
      title,
      count,
      sum,
      extra,
      more,
      prevPath
    } = this.state
    const { required } = product
    const { requirements } = extra

    const {
      getChangeCartActionRequest,
      ChangeTotalCartActionRequest,
      history
    } = this.props

    let cart = currentCart
    let currentProduct = {}
    let currentAvailable = false
    let valid = true

    currentProduct.mainPrice = mainPrice
    currentProduct.price = price
    currentProduct.id = id
    currentProduct.title = title
    currentProduct.count = count
    currentProduct.sum = sum
    currentProduct.extra = extra
    currentProduct.more = more

    let totalPrice = localStorage.getItem('merchantTotalPriceCart')
    let totalCount = localStorage.getItem('merchantTotalCountCart')

    let minusPrice = 0
    let minusCount = 0

    if (!_.isEmpty(required)) {
      valid = false
      if (_.isEmpty(requirements)) {
        window.scrollTo(0, 550)
        this.setState({ required: true, error: true })
      } else if (requirements.length != required.length) {
        this.setState({ required: true, error: true })
        required.map(item => {
          let element = document.getElementById(`requirement-${item.id}`)
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          })
        })
      } else if (requirements.length === required.length) {
        valid = true
        this.setState({ required: false, error: false })
      }
    }

    if (valid) {
      if (!_.isEmpty(cart)) {
        cart.map(item => {
          if (item.id == id) {
            if (totalCount) {
              minusCount = count - item.count
            }

            if (totalPrice) {
              minusPrice = price - item.price
            }

            item.count = prevPath ? count : count + item.count
            item.price = price
            item.sum = sum
            item.extra = extra
            item.more = more

            this.setState({
              count: item.count
            })
            currentAvailable = true
          }
        })
      }
      if (!currentAvailable) {
        cart.push(currentProduct)
      }
      getChangeCartActionRequest(cart)
      if (prevPath) {
        ChangeTotalCartActionRequest(minusPrice, minusCount)
        history.push('/order')
      } else {
        ChangeTotalCartActionRequest(price, count)
        history.push('/')
      }
    }
  }

  removeCartItem = (price, count, i) => {
    const {
      getChangeCartActionRequest,
      ChangeTotalCartActionRequest,
      history
    } = this.props
    let currentItems = this.state.currentCart
    let newItems = currentItems.filter(function(item, index) {
      return item.id != i
    })
    getChangeCartActionRequest(newItems)
    ChangeTotalCartActionRequest(-count * Number(price), -count)
    history.push('/order')
  }

  render() {
    const {
      price,
      product,
      required,
      error,
      prevPath,
      count,
      sum,
      id,
      extra,
      more
    } = this.state

    const { lang, color } = this.props

    return (
      <>
        <Header id="product-header" url="/" />
        {error && (
          <div className="text-center text-white error-section">
            {lang === 'ar'
              ? 'من فضلك راجع البيانات المدخلة'
              : 'Please review your submission'}
          </div>
        )}
        <Intro
          url={product && product.img}
          title={product && product.title}
          desc={product && product.desc}
          price={product && product.price}
          lang={lang}
        />
        {prevPath && (
          <>
            <div className="p-3 white-bg">
              <PlusMinusBtn
                color="#d80417"
                increment={() => this.increment()}
                decrease={() => this.decrease()}
                plusStyle={{ left: '47%' }}
                minusStyle={{ paddingTop: '5px' }}
                textStyle={{ padding: '0 22px' }}
                currentTotal={count}
              />
            </div>
            <button
              class="ez1ieiq2 ltr-tpgxpi e1b3lgmq0"
              onClick={() => this.removeCartItem(sum, count, id)}
            >
              {lang === 'ar' ? 'أزل من الحقيبة' : 'Remove from bag'}
            </button>
            <hr />
          </>
        )}
        <Requirements
          data={product && product.required}
          color="#d80417"
          invalid={required}
          onChange={(value, name) => this.requirementsChange(value, name)}
          defaultChecked={extra.requirements}
          lang={lang}
        />
        <Condiments
          data={product && product.condiments}
          color="#d80417"
          onChange={(value, cost, checked) =>
            this.condimentsChange(value, cost, checked)
          }
          defaultChecked={extra.condiments}
          lang={lang}
        />
        <div className="p-3 white-bg">
          <CustomInput
            value={more}
            label={
              lang === 'ar'
                ? 'أضف تعليمات (إختياري)'
                : 'Add Instructions (Optional)'
            }
            onChange={value => this.inputChange(value)}
          />
        </div>
        <hr />
        {!prevPath && (
          <>
            <div className="pt-3 white-bg">
              <PlusMinusBtn
                color="#d80417"
                increment={() => this.increment()}
                decrease={() => this.decrease()}
                plusStyle={{ left: '47%' }}
                minusStyle={{ paddingTop: '5px' }}
                textStyle={{ padding: '0 22px' }}
              />
            </div>
          </>
        )}
        <div style={{ height: '80px' }} className="white-bg" />
        <CustomBtn
          text={
            prevPath
              ? lang === 'ar'
                ? `حدث الطلب .  ${Number(price).toFixed(2)}  د.ك`
                : `Update order . ${Number(price).toFixed(2)} KWD`
              : lang === 'ar'
              ? `حدث الطلب .  ${Number(price).toFixed(2)} د.ك`
              : `Add to order .  ${Number(price).toFixed(2)} KWD`
          }
          color={color}
          onClick={() => this.submitOrder()}
        />
        <style>
          {`.ltr-tpgxpi {
            color: rgb(196, 33, 33)}`}
        </style>
      </>
    )
  }
}
export default Product
