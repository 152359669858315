import { connect } from 'react-redux'
import Product from './Product'
import {
  getChangeCartActionRequest,
  ChangeTotalCartActionRequest
} from 'store/actions'

const mapStateToProps = state => ({
  cartData: state.cart.data,
  lang: state.changeLang.lang,
  color: '#bf0414'
})

const mapDispatchToProps = dispatch => ({
  getChangeCartActionRequest: cart =>
    dispatch(getChangeCartActionRequest(cart)),
  ChangeTotalCartActionRequest: (totalPrice, totalCount) =>
    dispatch(ChangeTotalCartActionRequest(totalPrice, totalCount))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product)
