import { connect } from 'react-redux'
import Receipt from './Receipt'

const mapStateToProps = () => ({
  color: '#d80417',
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)
