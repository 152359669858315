import React from 'react'
import { Link } from 'react-router-dom'
import { ListGroupItem, ListGroup } from 'reactstrap'
import Header from 'components/public/pageHeader'

class BranchesList extends React.PureComponent {
  renderList = data => {
    const { color, lang } = this.props
    return (
      <ListGroup className="ltr-1v9c9b3">
        {data.map(item => (
          <ListGroupItem
            action
            className="py-3 px-0 branches-list-group m-0 w-100"
            key={item.id}
            onClick={() => {}}
          >
            <Link to={`branch/${item.id}`} className="link">
              <div className="d-flex justify-content-between">
                <div className="">
                  <h3 className="ltr-1gvv4e3 p-0 m-0">
                    {lang === 'ar' ? item.title_ar : item.title}
                  </h3>
                  <p className="ltr-1kefxcu pt-1 m-0">
                    {lang === 'ar' ? item.desc_ar : item.desc}
                  </p>
                </div>
                <div className=" custom-text-align">
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 44 44"
                    style={{ color: `${color}` }}
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      transform="translate(10 10)"
                    >
                      <path d="M0 0h24v24H0z"></path>
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.4"
                        d="M22 12c0 2.761-.976 5.118-2.929 7.071C17.118 21.024 14.761 22 12 22s-5.118-.976-7.071-2.929C2.976 17.118 2 14.761 2 12s.976-5.118 2.929-7.071C6.882 2.976 9.239 2 12 2s5.118.976 7.071 2.929C21.024 6.882 22 9.239 22 12zm-10 4v-4"
                      ></path>
                      <circle cx="12" cy="9" r="1" fill="currentColor"></circle>
                    </g>
                  </svg>
                </div>
              </div>
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    )
  }
  render() {
    const data = [
      {
        id: 1,
        title: 'PitaBar Surra',
        title_ar: 'مدينة الكويت',
        desc: 'Block 02 , Street – 05 , Building No – 427,',
        desc_ar: 'شارع 5'
      }
    ]
    const { lang } = this.props
    return (
      <>
        <Header text={lang === 'ar' ? 'الفروع' : 'Branches'} url="/" />
        <div className="branches-section h-100 white-bg branches-list-section">
          {this.renderList(data)}
        </div>
      </>
    )
  }
}
export default BranchesList
