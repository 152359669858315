import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import CustomBtn from 'components/public/button'
import CheckoutLayout from 'components/checkout'

class ContactForm extends React.PureComponent {
  state = { phone: null, hasError: false, mode: null }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.order_mode) {
      this.setState({ mode: orderMode.order_mode })
    }
  }
  render() {
    const { color, lang } = this.props
    const { mode, hasError } = this.state
    let contactForm = JSON.parse(localStorage.getItem('checkoutContact'))
    return (
      <CheckoutLayout activeLink="/checkout/contact" url="/order">
        <div className="white-bg height-100">
          <Formik
            initialValues={{
              email: contactForm ? contactForm.email : '',
              name: contactForm ? contactForm.name : '',
              phone: contactForm ? contactForm.phone : '',
              make:
                contactForm && contactForm.carMake ? contactForm.carMake : '',
              color:
                contactForm && contactForm.carColor ? contactForm.carColor : '',
              license:
                contactForm && contactForm.carLicence
                  ? contactForm.carLicence
                  : ''
            }}
            onSubmit={async values => {
              const { getCheckoutContactActionRequest, history } = this.props
              if (isPossiblePhoneNumber(values.phone)) {
                this.setState({ hasError: false })
                let body = JSON.parse(localStorage.getItem('checkoutContact'))
                  ? JSON.parse(localStorage.getItem('checkoutContact'))
                  : {}

                body.email = values.email
                body.name = values.name
                body.phone = values.phone
                body.carMake = values.make ? values.make : null
                body.carColor = values.color ? values.color : null
                body.carLicence = values.license ? values.license : null
                getCheckoutContactActionRequest(body)
                if (mode === 'delivery') {
                  history.push('/checkout/delivery')
                } else {
                  history.push('/checkout/payment')
                }
              } else {
                this.setState({ hasError: true })
              }
            }}
            validationSchema={Yup.object().shape({
              name:
                lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'),
              phone:
                lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'),
              email:
                lang === 'ar'
                  ? Yup.string().email('من فضلك ادخل بريد إلكتروني صحيح')
                  : Yup.string().email('Please insert a valid e-mail address'),
              make:
                mode === 'pickup' &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required')),
              color:
                mode === 'pickup' &&
                (lang === 'ar'
                  ? Yup.string().required('مطلوب')
                  : Yup.string().required('Required'))
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props
              return (
                <form onSubmit={handleSubmit}>
                  {(!isValid && touched.name) ||
                  (!isValid && touched.phone) ||
                  hasError ? (
                    <div className="text-center text-white error-section">
                      {lang === 'ar'
                        ? 'من فضلك راجع البيانات المدخلة'
                        : 'Please review your submission'}
                    </div>
                  ) : null}

                  <div className="p-3">
                    <h1 className="title m-0">
                      {lang === 'ar'
                        ? 'معلومات الإتصال'
                        : 'Contact Information'}
                    </h1>
                    <p className="ltr-qtvxxo e1iliclz0 mb-3">
                      {lang === 'ar'
                        ? 'سوف نستخدم تلك المعلومات لنوافيك بإحداثيات الطلب'
                        : 'We’ll use it to get back to you for order updates'}
                    </p>
                    <div style={{ height: '10px' }} />
                    <div
                      className={
                        errors.name && touched.name
                          ? 'error-border field-container'
                          : ' field-container'
                      }
                    >
                      <input
                        id="name"
                        type="text"
                        placeholder=" "
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-input px-0"
                      />
                      <label
                        className={
                          errors.name && touched.name
                            ? ' error field-placeholder'
                            : ' field-placeholder'
                        }
                        for="inputName"
                      >
                        {lang === 'ar' ? 'الاسم' : 'Name'}
                      </label>
                    </div>
                    {errors.name && touched.name && (
                      <div className="input-feedback">{errors.name}</div>
                    )}
                    <div className="my-2">
                      <label
                        className={
                          errors.phone && touched.phone
                            ? 'error label'
                            : 'label'
                        }
                      >
                        {lang === 'ar' ? 'رقم الهاتف' : 'Phone number'}
                      </label>
                      <PhoneInput
                        className={
                          errors.phone && touched.phone ? 'error px-0' : 'px-0'
                        }
                        id="phone"
                        value={values.phone}
                        defaultCountry="KW"
                        isRequired
                        onChange={e => {
                          setFieldValue('phone', e)
                        }}
                        error={
                          values.phone
                            ? isPossiblePhoneNumber(values.phone)
                              ? undefined
                              : 'Required (please insert a valid phone number)'
                            : 'Phone number required'
                        }
                      />
                    </div>
                    {!isPossiblePhoneNumber(values.phone) &&
                      !errors.phone &&
                      values.phone != '' && (
                        <div className="input-feedback">
                          {lang === 'ar'
                            ? 'مطلوب (من فضلك ادخل رقم هاتف صحيح)'
                            : 'Required (please insert a valid phone number)'}
                        </div>
                      )}
                    {errors.phone && (
                      <div className="input-feedback">{errors.phone}</div>
                    )}
                    <div style={{ height: '8px' }} />
                    <div
                      className={
                        errors.email && touched.email
                          ? ' error-border field-container mt-4'
                          : 'field-container mt-4'
                      }
                    >
                      <input
                        id="email"
                        placeholder="Enter your email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-input px-0"
                      />
                      <label
                        className={
                          errors.email && touched.email
                            ? ' error field-placeholder'
                            : 'field-placeholder'
                        }
                        for="inputName"
                      >
                        {lang === 'ar'
                          ? 'البريد الإلكتروني (اختياري)'
                          : 'E-mail(optional)'}
                      </label>
                    </div>
                    {errors.email && touched.email && (
                      <div className="input-feedback">{errors.email}</div>
                    )}

                    {mode && mode === 'pickup' && (
                      <>
                        <br />
                        <br />
                        <h1 className="title m-0">
                          {lang === 'ar'
                            ? 'وصف السيارة'
                            : 'Pickup Car Information'}
                        </h1>
                        <p className="ltr-qtvxxo e1iliclz0 mb-3">
                          {lang === 'ar'
                            ? 'لنصل إليك بسهولة'
                            : 'Help us quickly identify your car.'}
                        </p>
                        <div
                          className={
                            errors.make && touched.make
                              ? ' error-border field-container mt-4'
                              : 'field-container mt-4'
                          }
                        >
                          <input
                            id="make"
                            placeholder="Make"
                            type="text"
                            value={values.make}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field-input px-0"
                          />
                          <label
                            className={
                              errors.make && touched.make
                                ? ' error field-placeholder'
                                : 'field-placeholder'
                            }
                            for="inputName"
                          >
                            {lang === 'ar' ? 'موديل السيارة' : 'Make'}
                          </label>
                        </div>
                        {errors.make && touched.make && (
                          <div className="input-feedback">{errors.make}</div>
                        )}
                        <div
                          className={
                            errors.color && touched.color
                              ? ' error-border field-container mt-4'
                              : 'field-container mt-4'
                          }
                        >
                          <input
                            id="color"
                            placeholder="Color"
                            type="text"
                            value={values.color}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field-input px-0"
                          />
                          <label
                            className={
                              errors.color && touched.color
                                ? ' error field-placeholder'
                                : 'field-placeholder'
                            }
                            for="inputName"
                          >
                            {lang === 'ar' ? 'لون السيارة' : 'Color'}
                          </label>
                        </div>
                        {errors.color && touched.color && (
                          <div className="input-feedback">{errors.color}</div>
                        )}
                        <div
                          className={
                            errors.license && touched.license
                              ? ' error-border field-container mt-4'
                              : 'field-container mt-4'
                          }
                        >
                          <input
                            id="license"
                            placeholder="license"
                            type="text"
                            value={values.license}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field-input px-0"
                          />
                          <label
                            className={
                              errors.license && touched.license
                                ? ' error field-placeholder'
                                : 'field-placeholder'
                            }
                            for="inputName"
                          >
                            {lang === 'ar'
                              ? 'رقم لوحة السيارة (اختياري)'
                              : 'License Number (optional)'}
                          </label>
                        </div>
                        {errors.license && touched.license && (
                          <div className="input-feedback">{errors.license}</div>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                  </div>
                  <CustomBtn
                    color={color}
                    type="submit"
                    onClick={() => {}}
                    text={lang === 'ar' ? 'متابعة' : 'Next'}
                  />
                </form>
              )
            }}
          </Formik>
        </div>
        <style>{`.error-border{      
            border-bottom: 1px solid #c42121;
          }
          .error {
            color: #c42121 !important;
            }
          `}</style>
      </CheckoutLayout>
    )
  }
}
export default ContactForm
