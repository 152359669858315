import {
  CART_FETCHING,
  CART_SUCCESS,
  CART_FAILURE,
} from '../actionTypes/addToCart'

const initialState = {
  error: null,
  data: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CART_FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case CART_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      }
    case CART_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }

    default:
      return state
  }
}
