import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from 'store/reducers'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: []
}

const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware)
)

export let persistor = persistStore(store)
