import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from 'components/home'
import routes from 'utilities/Routes'
import MainLayout from 'components/layout'
import Branches from 'components/branches/branchesList'
import ProductDetail from 'components/Product'
import BranchDetail from 'components/branches/branchDetail'
import Cart from 'components/cart'
import CheckoutContactForm from 'components/checkout/components/contactForm'
import CheckoutDeliveryAddress from 'components/checkout/components/deliveryAdress'
import CheckoutPayment from 'components/checkout/components/payment'
import CheckoutConfirm from 'components/checkout/components/confirm'
import Status from 'components/status'
import Receipt from 'components/receipt'
import DeliveryMood from 'components/mode/delivery'
import CarPickUp from 'components/mode/carPickUp'
import Time from 'components/time'
import OrderStatus from 'components/orderStatus'
import Search from 'components/search'
import Location from 'components/checkout/components/location'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const MainRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <MainLayout>
          <Route exact path={routes.index._} component={Home} />
          <Route path={routes.branches._} component={Branches} />
          <Route path={routes.product_detail._} component={ProductDetail} />
          <Route path={routes.branch_detail._} component={BranchDetail} />
          <Route path={routes.cart._} component={Cart} />
          <Route path={routes.checkout._} component={CheckoutContactForm} />
          <Route
            path={routes.checkout.checkout_delivery._}
            component={CheckoutDeliveryAddress}
          />
          <Route
            path={routes.checkout.checkout_payment._}
            component={CheckoutPayment}
          />
          <Route
            path={routes.checkout.checkout_confirm._}
            component={CheckoutConfirm}
          />
          <Route path={routes.status._} component={Status} />
          <Route path={routes.receipt._} component={Receipt} />
          <Route path={routes.mode.delivery._} component={DeliveryMood} />
          <Route path={routes.mode.pickup._} component={CarPickUp} />
          <Route path={routes.time._} component={Time} />
          <Route path={routes.order_status._} component={OrderStatus} />
          <Route path={routes.search._} component={Search} />
          <Route path={routes.location._} component={Location} />
        </MainLayout>
      </Switch>
    </Router>
  )
}

export default MainRouter
