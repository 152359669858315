import React from 'react'
import { Link } from 'react-router-dom'
import PlusMinusBtn from 'components/public/plusMinusBtn'
import _ from 'lodash'

class CartItems extends React.PureComponent {
  state = { currentCart: JSON.parse(localStorage.getItem('merchantCart')) }
  renderReq = data => {
    return data.map(item => (
      <div className="d-flex justify-content-between" key={item.name}>
        <p className="ltr-1y4p49f p-0 mb-2">{item.name}</p>
        <small className="ltr-1o15x0g">-</small>
      </div>
    ))
  }
  renderCon = data => {
    return data.map(item => (
      <div className="d-flex justify-content-between" key={item.value}>
        <p className="ltr-1y4p49f p-0 mb-2">{item.value}</p>
        <small className="ltr-1o15x0g">
          KWD {Number(item.cost).toFixed(2)}
        </small>
      </div>
    ))
  }
  increment = (price, count, index) => {
    const {
      ChangeTotalCartActionRequest,
      getChangeCartActionRequest
    } = this.props
    let currentCart = this.state.currentCart
    currentCart[index].count = count + 1
    this.setState({ currentCart }, () =>
      getChangeCartActionRequest(this.state.currentCart)
    )
    ChangeTotalCartActionRequest(Number(price), 1)
  }

  decrease = (price, count, index) => {
    const {
      ChangeTotalCartActionRequest,
      getChangeCartActionRequest
    } = this.props
    let currentCart = this.state.currentCart
    currentCart[index].count = count - 1
    this.setState({ currentCart }, () =>
      getChangeCartActionRequest(this.state.currentCart)
    )
    ChangeTotalCartActionRequest(-Number(price), -1)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cartData } = this.props
    if (nextProps.currentCart != cartData) {
      this.setState({
        currentCart: nextProps.cartData
      })
    }
  }

  removeCartItem = (price, count, i) => {
    const {
      getChangeCartActionRequest,
      ChangeTotalCartActionRequest
    } = this.props
    let currentItems = this.state.currentCart
    let newItems = currentItems.filter(function(item, index) {
      return index != i
    })
    getChangeCartActionRequest(newItems)
    ChangeTotalCartActionRequest(-price, -count)
  }

  renderItems = () => {
    const { currentCart } = this.state
    const { lang } = this.props
    return (
      <>
        {currentCart.map((item, index) => (
          <div className="p-3 white-bg items-container" key={item.title}>
            <div className="d-flex justify-content-between">
              <Link
                to={{
                  pathname: `/item/${item.id}`,
                  state: { route: '/order' }
                }}
              >
                <p className="ltr-1gxuiwm mb-0" style={{ color: '#f1041a' }}>
                  {item.title}
                </p>
              </Link>
              <span className="ltr-8942aw">
                {lang === 'en' && 'KWD   '}
                {Number(item.mainPrice)}
                {lang === 'ar' && '  د.ك'}
              </span>
            </div>
            <div className="mt-3">
              {item.extra && !_.isEmpty(item.extra.requirements)
                ? this.renderReq(item.extra.requirements)
                : ''}
            </div>
            {item.extra && !_.isEmpty(item.extra.condiments)
              ? this.renderCon(item.extra.condiments)
              : ''}
            {item.more ? <p className="ltr-1y4p49f">{item.more}</p> : ''}
            <div className="d-flex justify-content-between">
              <PlusMinusBtn
                style={{
                  height: '32px',
                  width: '32px'
                }}
                minusStyle={{
                  color: '#000',
                  fontSize: '18px',
                  paddingTop: '5px'
                }}
                plusStyle={{
                  color: '#000',
                  fontSize: '18px'
                }}
                textStyle={{
                  fontSize: '15px',
                  fontWeight: '400',
                  lineHeight: '2',
                  color: 'rgb(0, 0, 0)',
                  padding: '0 22px'
                }}
                color="#e8e3e3"
                currentTotal={item.count}
                increment={() => this.increment(item.sum, item.count, index)}
                decrease={() => this.decrease(item.sum, item.count, index)}
              />
              <button
                onClick={() =>
                  this.removeCartItem(item.price, item.count, index)
                }
                className="cart-btn bg-white px-3 mt-3"
              >
                {lang === 'ar' ? 'حذف' : 'Remove'}
              </button>
            </div>
          </div>
        ))}
      </>
    )
  }

  render() {
    const { currentCart } = this.state
    const { lang } = this.props
    return (
      <>
        <div className="p-3 white-bg">
          <span className="ltr-14siyw e1iliclz0">
            {lang === 'ar' ? 'الطلبات' : 'Order Items'}
          </span>
        </div>
        {currentCart ? this.renderItems() : null}
        <hr />
      </>
    )
  }
}

export default CartItems
