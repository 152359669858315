import {
  CHANGE_LANG_FETCHING,
  CHANGE_LANG_SUCCESS,
  CHANGE_LANG_FAILURE,
} from 'store/actionTypes/changeLang'

export const changeLangActionFetching = () => ({
  type: CHANGE_LANG_FETCHING,
})
export const changeLangActionSuccess = (payload) => ({
  type: CHANGE_LANG_SUCCESS,
  payload,
})
export const changeLangActionFailure = (failure) => ({
  type: CHANGE_LANG_FAILURE,
  failure,
})
