import React, { useState } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap'
import Burger from 'react-css-burger'
import { Link } from 'react-router-dom'

const Menu = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [active, setActive] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const activeIcon = () => setActive(!active)
  const { lang } = props

  const handleLang = () => {
    const { getChangeLangActionRequest, lang } = props
    if (lang === 'ar') {
      getChangeLangActionRequest('en')
    } else {
      getChangeLangActionRequest('ar')
    }
  }
  let loc = window.location.pathname
  let Count = props.totalCart
    ? props.totalCart.count
    : localStorage.getItem('merchantTotalCountCart')
  return (
    <div className="w-100">
      <Navbar
        color={`${isOpen ? 'white' : 'transparent'}`}
        light
        expand="lg"
        style={{ direction: lang === 'ar' ? 'ltr' : 'rtl' }}
        className={`p-2 ${isOpen ? 'customNav' : ''}`}
      >
        <NavbarBrand className=" lang-switch cursor-pointer py-0 m-0">
          {!isOpen && (
            <>
              {lang === 'en' ? (
                <>
                  <svg
                    width="1.7em"
                    height="1.7em"
                    viewBox="0 0 32 32"
                    className="langSvg"
                    id="enSvg"
                    onClick={() => handleLang()}
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 22.424c-1.099.95-2.388 1.675-3.868 2.174-.952.268-1.919.402-2.901.402-1.48 0-2.721-.399-3.725-1.197C10.502 23.006 10 21.711 10 19.921c0-.743.117-1.463.35-2.16.233-.698.65-1.403 1.252-2.115.289-.317.505-.553.65-.708.144-.155.271-.266.382-.333-.792-.683-1.188-1.346-1.188-1.992-.03-.749.497-1.857 1.584-3.325C13.779 8.429 14.605 8 15.507 8c.455 0 .91.132 1.368.397.457.265.792.488 1.004.667.212.18.39.375.534.585.144.21.216.412.216.607-.006.03-.021.046-.046.046-.847-.305-1.565-.457-2.155-.457-.233 0-.532.054-.898.16-.365.107-.744.3-1.137.58-.393.28-.59.563-.59.85 0 .286.274.59.82.913.547.323.875.506.986.548.233-.03 1.092-.231 2.578-.603l1.073-.205c.237-.046.557-.117.963-.215l-.857 2.64h-.064c-.24 0-.792.085-1.658.256-4.163.962-6.244 2.552-6.244 4.768 0 .396.224.84.672 1.334.995.719 2.22 1.163 3.675 1.334.46.085.878.14 1.252.164.375.025 1.02.052 1.934.082A122.29 122.29 0 0122 22.424z"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    width="1.7em"
                    height="1.7em"
                    viewBox="0 0 32 32"
                    className="langSvg"
                    id="arSvg"
                    onClick={() => handleLang()}
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.665 21H8V11h6.665v1.742h-4.69v2.345h3.994v1.742H9.975v2.43h4.69V21zM24 21h-1.762l-4.008-6.138V21h-1.975V11h1.762l4.008 6.124V11H24v10z"
                    />
                  </svg>
                </>
              )}
              <Link to="/order" className="position-relative">
                <svg
                  width="0.8em"
                  height="0.8em"
                  viewBox="0 0 14 16"
                  className="bagSvg  mx-2"
                  id="bagSvg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.454 3.333c.134 0 .264.017.389.047.39.07.687.225.9.499.218.28.27.5.255.855l.002.07c0 .05 0 .05-.004.1l-.022.148-.647 7.47c-.168 1.939-2.1 3.385-4.41 3.474L8.692 16h-3.5v-.003l.116.003c-2.407 0-4.46-1.477-4.634-3.478L.028 5.076l-.024-.171-.003-.064v-.037l.001-.056c-.015-.365.034-.585.255-.87.277-.355.697-.511 1.277-.54l.013-.005h1.916A.757.757 0 013.387 3c0-1.657 1.364-3 3.048-3h1.13c1.684 0 3.049 1.343 3.049 3 0 .12-.028.232-.077.333h1.917zM1.748 4.89h-.15l.65 7.5c.097 1.113 1.408 2.055 3.06 2.055h3.384c1.653 0 2.964-.942 3.06-2.054l.65-7.5-.15-.001H1.748zm5.816-3.333H6.437c-.81 0-1.468.646-1.468 1.444 0 .12-.027.232-.076.333h4.214a.757.757 0 01-.076-.333c0-.798-.657-1.444-1.467-1.444z"
                  />
                </svg>
                {((Count && Count > 0) || props.totalCart) && (
                  <span className="ltr-8xnx94 e1r80u6l0">
                    <p className="ltr-1ifilxi e1iliclz0">{Count}</p>
                  </span>
                )}
              </Link>
              <svg
                width="0.8em"
                height="0.8em"
                viewBox="0 0 16 16"
                className="bagSvg searchSvg  mx-2"
                id="searchSvg"
              >
                <path
                  fill="current-color"
                  fillRule="evenodd"
                  d="M6.741 0c3.716 0 6.742 3.026 6.742 6.741a6.7 6.7 0 01-1.266 3.924l3.445 3.445a1.098 1.098 0 11-1.545 1.56l-3.452-3.453a6.7 6.7 0 01-3.924 1.266C3.026 13.483 0 10.457 0 6.74 0 3.026 3.026 0 6.741 0zm0 1.254a5.478 5.478 0 00-5.487 5.487 5.478 5.478 0 005.487 5.487 5.478 5.478 0 005.487-5.487 5.478 5.478 0 00-5.487-5.487z"
                />
              </svg>
              <img
                id="miniLogo"
                alt={props.logo}
                src={props.logo}
                className="small-logo"
              />
            </>
          )}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="float-left">
          <Burger
            onClick={activeIcon}
            active={active}
            burger="arrow"
            color="black"
            hoverOpacity={0.8}
            scale={0.7}
          />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
            <NavItem>
              <Link
                to="/"
                onClick={() => {
                  if (isOpen) {
                    toggle()
                  }
                }}
                className={`menu-items ${!isOpen && 'text-white'}  ${loc ===
                  '/' && 'active-menu-item'}`}
              >
                {lang === 'ar' ? 'القاعمه' : 'Menu'}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/search"
                className={`menu-items ${!isOpen && 'text-white'}  ${loc ===
                  '/search' && 'active-menu-item'}`}
              >
                {lang === 'ar' ? 'بحث' : 'Search'}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/branches"
                className={`menu-items ${!isOpen && 'text-white'}  ${loc ===
                  '/branches' && 'active-menu-item'}`}
              >
                {lang === 'ar' ? 'الفروع' : 'Branches'}
              </Link>
            </NavItem>
            <NavItem className="order-item">
              <Link
                to="/order-status"
                className={`menu-items ${!isOpen && 'text-white'}  ${loc ===
                  '/order-status' && 'active-menu-item'}`}
              >
                {lang === 'ar' ? 'تابع الطلب' : 'Order Status'}
              </Link>
            </NavItem>
            <NavItem className="order-item m-0">
              <div className="one-tap-ordering">
                <p className="ltr-125d2vb e1iliclz0">Enable one-tap ordering</p>
                <div className="d-flex justify-content-start">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z"></path>
                      <g transform="translate(6 3)">
                        <path
                          stroke="#4A4A4A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.4"
                          d="M6 18c4-4.2 6-8.2 6-12A6 6 0 100 6c0 3.8 2 7.8 6 12z"
                        ></path>
                        <circle cx="6" cy="6" r="2" fill="#4A4A4A"></circle>
                      </g>
                    </g>
                  </svg>
                  <p className="ltr-1gvv4e3 px-3 e1iliclz0">
                    Save your addresses
                  </p>
                </div>
                <div className="d-flex justify-content-start">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z"></path>
                      <path
                        stroke="#4A4A4A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.4"
                        d="M3.818 4h16.364C21.394 4 22 4.667 22 6v12c0 1.333-.606 2-1.818 2H3.818C2.606 20 2 19.333 2 18V6c0-1.333.606-2 1.818-2z"
                      ></path>
                      <path
                        stroke="#4A4A4A"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2 9h20"
                      ></path>
                      <path
                        stroke="#4A4A4A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.4"
                        d="M5 17h3m3 0h6"
                      ></path>
                    </g>
                  </svg>
                  <p className="ltr-1gvv4e3 px-3 e1iliclz0">
                    Save your payments.Coming Soon!
                  </p>
                </div>
                <div className="d-flex justify-content-start">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z"></path>
                      <g
                        stroke="#4A4A4A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.4"
                      >
                        <path d="M2 12l2 2 2-2m7-5v5l3 3"></path>
                        <path d="M13 21a9 9 0 10-9-9v1"></path>
                      </g>
                    </g>
                  </svg>
                  <p className="ltr-1gvv4e3 px-3 e1iliclz0">
                    Re-order with one tap.Coming Soon!
                  </p>
                </div>
                <button className="ez1ieiq2 ltr-oz6p4a e1b3lgmq0 d-flex justify-content-center">
                  <span className="content">Login</span>
                </button>
              </div>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Menu
