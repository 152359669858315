import React from 'react'

class PromoCode extends React.PureComponent {
  state = { promoCod: '', error: false }
  render() {
    const { error } = this.state
    const { lang } = this.props
    return (
      <>
        <div className="p-3 white-bg">
          <div className="ltr-vsl5ie e1limytx0">
            <h3 className="ltr-14siyw e1iliclz0">
              {lang === 'ar' ? 'كود الخصم' : 'Promo Code'}
            </h3>
          </div>
          <div className="d-flex justify-content-between">
            <div className="white-bg w-100">
              <div className={`field-container ${error && 'err'}`}>
                <input
                  className="field-input px-0"
                  id="promo"
                  value={this.state.promoCod}
                  name="inputName"
                  type="text"
                  placeholder=" "
                  onChange={e => this.setState({ promoCod: e.target.value })}
                />
                <label
                  className={`field-placeholder ${error && 'err-place'}`}
                  for="inputName"
                >
                  {lang === 'ar' ? 'إدخل الكود' : 'Enter code'}
                </label>
              </div>
              {error && (
                <p className="ltr-s0sui0 mb-0">
                  {lang === 'ar'
                    ? 'هذا الكود غير صحيح أو منتهي'
                    : 'Voucher code is invalid or expired'}
                </p>
              )}
            </div>
            <button
              disabled={this.state.promoCod === '' ? true : false}
              className="white-bg cart-btn ltr-1wi12uh  apply-btn ml-3"
              onClick={() => {
                this.setState({ error: true })
              }}
            >
              {lang === 'ar' ? 'تأكيد' : 'Apply'}
            </button>
          </div>
        </div>
        <style type="text/css">
          {`
          .apply-btn:hover{
            border-color: rgb(166, 3, 17) !important;
            color: rgb(166, 3, 17);
          }
          .apply-btn {
            color: rgb(166, 3, 17)!important ;
          }
          .ltr-s0sui0,.err-place {
            color:rgb(196, 33, 33) !important 
          }
          .err {
            border-bottom: 1px solid rgb(196, 33, 33);
          }
        `}
        </style>
      </>
    )
  }
}
export default PromoCode
