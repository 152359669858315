import Axios from 'axios'
// import Constants from './Constants'
import _ from 'lodash'

const http = Axios.create({
  // baseURL: Constants.protocol + Constants.baseUrl,
  withCredentials: true,
  timeout: 31000,
  headers: {
    'Content-Type': 'application/json',
  },
})
http.interceptors.request.use(
  async (config) => {
    const storageLang = await localStorage.getItem('lang')
    if (!_.isEmpty(storageLang)) {
      config.headers.lang = storageLang
    } else {
      config.headers.lang = 'ar'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default http
