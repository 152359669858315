import React from 'react'

class WalletSvg extends React.PureComponent {
  render() {
    const { color, lang } = this.props
    return (
      <>
        <div className="d-flex justify-content-center pt-5">
          <svg
            id="prefix__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 196.2 188.5"
            xmlSpace="preserve"
            width="130"
          >
            <style>{`.prefix__st0{fill:${color}}`}</style>
            <g id="prefix__Base_1_">
              <g id="prefix___x32_">
                <path d="M36.9 86c-.9 0-1.6.7-1.6 1.6v40.1c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6V87.6c0-.9-.7-1.6-1.6-1.6zM60 148h-6.5c-1.1 0-2 .9-2 2s.9 2 2 2H60c1.1 0 2-.9 2-2s-.9-2-2-2z" />
                <path d="M157.9 64.1v-10c0-14.8-12-26.8-26.8-26.8H47.6c-14.8 0-26.8 12-26.8 26.8v74.3c0 13 10.6 23.6 23.6 23.6h.4c1.1 0 2-.9 2-2s-.9-2-2-2h-1.4c-10.3 0-18.6-8.3-18.6-18.6V64.9H135c2.3 0 5.9.6 7.3 1.8-14.4 5.6-24.5 19.6-24.5 36 0 20.5 16 37.3 36.2 38.5v6.5c0 .2-.1.3-.3.3H68.8c-1.1 0-2 .9-2 2s.9 2 2 2h83.8c2.9 0 5.3-2.4 5.3-5.3v-5.5c20.6-.8 37.1-17.8 37.1-38.6s-16.5-37.7-37.1-38.5zM23.7 60.9v-5.8c0-13.7 11.1-24.8 24.8-24.8h81.6c13.7 0 24.8 11.1 24.8 24.8v2.1c-3.6-10.2-13.1-17.5-24.2-17.5H50.6c-12.4 0-22.7 9.1-25.2 21.3l-1.7-.1zm7.5 0h-2.6c2.7-10.5 12-18.3 22.9-18.3h78.3c11.8 0 21.6 9 23.4 20.7-4.3-8.2-12.7-13.8-22.2-13.8H52c-8.6.1-16.2 4.6-20.8 11.4zm103.7 0H35.3c4.3-5.1 10.6-8.3 17.6-8.3h77.3c8.5 0 16 4.8 20.1 12-.8.1-1.5.3-2.3.4-3.7-2.6-8.2-4.1-13.1-4.1zm20.5 80.4h1-1zm1-4c-19.1 0-34.6-15.5-34.6-34.6s15.5-34.6 34.6-34.6S191 83.6 191 102.7s-15.6 34.6-34.6 34.6z" />
              </g>
            </g>
            <g id="prefix__Color_1_">
              <g id="prefix___x31_">
                <path
                  className="prefix__st0"
                  d="M12.4 87.7l-3.6-1.5-1.5-3.6c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2L5 86.2l-3.6 1.5c-.2.1-.3.4-.2.6 0 .1.1.2.2.2L5 90l1.5 3.6c.1.2.4.3.6.2.1 0 .2-.1.2-.2L8.8 90l3.6-1.5c.2-.1.3-.4.2-.6 0-.1-.1-.2-.2-.2zM145.3 5.5c0-.1-.1-.2-.2-.2l-3.6-1.5L140 .2c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2l-1.5 3.6-3.6 1.5c-.2.1-.3.4-.2.6.1 0 .2.1.3.2l3.6 1.5 1.5 3.6c.1.2.4.3.6.2.1 0 .2-.1.2-.2l1.5-3.6 3.5-1.5c.2-.1.3-.4.2-.6zM114.7 178.9l-5.6-2.3-2.3-5.6c-.2-.4-.6-.5-1-.3-.1.1-.3.2-.3.3l-2.3 5.6-5.6 2.3c-.4.2-.5.6-.3 1 .1.1.2.3.3.3l5.6 2.3 2.3 5.6c.2.4.6.5 1 .3.1-.1.3-.2.3-.3l2.3-5.6 5.6-2.3c.4-.2.5-.6.3-1 0-.1-.1-.2-.3-.3zM171.5 97.6h-2.1v-6.2c0-6.3-5.1-11.4-11.4-11.4-6.3 0-11.4 5.1-11.4 11.4v6.2h-2.1c-1.1 0-1.9.9-1.9 1.9v22.9c0 1.1.9 1.9 1.9 1.9h27.1c1.1 0 1.9-.9 1.9-1.9V99.6c-.1-1.1-1-2-2-2zm-6 0h-15.1v-6.2c0-4.2 3.4-7.6 7.6-7.6 4.2 0 7.6 3.4 7.6 7.6v6.2h-.1z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="d-flex flex-column text-center white-bg pt-3">
          <h1 className="ltr-1ieammf e1iliclz0">
            {lang === 'ar' ? 'طريقة الدفع' : 'Payment Method'}
          </h1>
          <p className="ltr-qtvxxo e1iliclz0">
            {lang === 'ar'
              ? 'اختر طريقة الدفع المفضلة لديك'
              : 'Choose your preferred payment method'}
          </p>
        </div>
      </>
    )
  }
}
export default WalletSvg
