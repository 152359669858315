export default [
  {
    id: 1,
    count: 0,
    active: false,
    title: 'DIY SHAWARMA',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [],
    condiments: [],
  },
  {
    id: 2,
    count: 4,
    active: true,
    title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '200',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [
      {
        id: 100,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 101, title: '1 KG MEAT', value: '1', extra: null },
          { id: 102, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 103,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 104,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 105, title: 'FRESH BAKED PITA', value: '1' },
          { id: 106, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 107,
        title: 'ADD ONS',
        items: [
          {
            id: 108,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 109,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 110,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 111,
        title: 'CONDIMENTS',
        items: [
          { id: 112, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 113, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 3,
    count: 0,
    active: false,
    title: '1/2 KG READY TO COOK FALAFEL MIX WITH CITRUS TAHINI SAUCE',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '100',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/1ed2af5a-94e4-42e5-8e41-f2eccdad03b1/original/New-falafel-Mix.jpg?1588948875',
    required: [],
    condiments: [],
  },
  {
    id: 4,
    count: 0,
    active: false,
    title: 'SHAWARMA BOX',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '120',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [],
    condiments: [],
  },
  {
    id: 5,
    count: 0,
    active: false,
    title: 'Apple Tabouleh',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '140',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
    required: [],
    condiments: [],
  },
  {
    id: 6,
    count: 0,
    active: false,
    title: 'Tahini Hummus',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '170',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
    required: [],
    condiments: [],
  },
  {
    id: 7,
    count: 0,
    active: false,
    title: 'Special Chicken Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '130',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [
      {
        id: 114,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 115, title: '1 KG MEAT', value: '1', extra: null },
          { id: 116, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 117,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 118,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 119, title: 'FRESH BAKED PITA', value: '1' },
          { id: 120, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 121,
        title: 'ADD ONS',
        items: [
          {
            id: 122,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 123,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 124,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 125,
        title: 'CONDIMENTS',
        items: [
          { id: 126, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 127, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 8,
    count: 4,
    title: 'Classic Meat Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [],
    condiments: [],
  },
  {
    id: 9,
    count: 4,
    title: 'SHAWARMA',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '180',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
    required: [],
    condiments: [],
  },
  {
    id: 10,
    count: 4,
    title: 'Sabich',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',

    required: [],
    condiments: [],
  },
  {
    id: 11,
    count: 0,
    active: false,
    title: 'Apple Tabouleh',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '140',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
    required: [],
    condiments: [],
  },
  {
    id: 12,
    count: 0,
    active: false,
    title: 'Tahini Hummus',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '170',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
    required: [],
    condiments: [],
  },
  {
    id: 13,
    count: 0,
    active: false,
    title: 'Special Chicken Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '130',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [
      {
        id: 128,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 129, title: '1 KG MEAT', value: '1', extra: null },
          { id: 130, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 131,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 132,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 133, title: 'FRESH BAKED PITA', value: '1' },
          { id: 134, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 135,
        title: 'ADD ONS',
        items: [
          {
            id: 136,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 137,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 138,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 139,
        title: 'CONDIMENTS',
        items: [
          { id: 140, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 141, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 14,
    count: 4,
    title: 'Classic Meat Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,
    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [],
    condiments: [],
  },
  {
    id: 15,
    count: 4,
    title: 'DIY SHAWARMA BOX',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '180',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
    required: [],
    condiments: [],
  },
  {
    id: 16,
    count: 4,
    title: 'Sabich',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16 fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring onions,pomegrante seeds, veg mix chilli, pickled cucumber, red onion, pickled red onions, pickled turnips,and 5 sauces: classic tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy sauce`,

    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',

    required: [
      {
        id: 142,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 143, title: '1 KG MEAT', value: '1', extra: null },
          { id: 144, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 145,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 146,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 147, title: 'FRESH BAKED PITA', value: '1' },
          { id: 148, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 149,
        title: 'ADD ONS',
        items: [
          {
            id: 150,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 151,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 152,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 153,
        title: 'CONDIMENTS',
        items: [
          { id: 153, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 154, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 17,
    count: 0,
    active: false,
    title: 'Apple Tabouleh',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '140',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
    required: [],
    condiments: [],
  },
  {
    id: 18,
    count: 0,
    active: false,
    title: 'Tahini Hummus',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '170',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
    required: [],
    condiments: [],
  },
  {
    id: 19,
    count: 0,
    active: false,
    title: 'Special Chicken Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '130',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [
      {
        id: 155,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 156, title: '1 KG MEAT', value: '1', extra: null },
          { id: 157, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 158,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 159,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 160, title: 'FRESH BAKED PITA', value: '1' },
          { id: 161, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 162,
        title: 'ADD ONS',
        items: [
          {
            id: 163,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 164,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 165,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 166,
        title: 'CONDIMENTS',
        items: [
          { id: 167, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 168, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 20,
    count: 4,
    title: 'Classic Meat Shawarma',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
    required: [],
    condiments: [],
  },
  {
    id: 21,
    count: 4,
    title: 'DIY SHAWARMA BOX',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '180',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
    required: [
      {
        id: 169,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 170, title: '1 KG MEAT', value: '1', extra: null },
          { id: 171, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 172,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 173,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 174, title: 'FRESH BAKED PITA', value: '1' },
          { id: 175, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 176,
        title: 'ADD ONS',
        items: [
          {
            id: 177,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 178,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 179,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 180,
        title: 'CONDIMENTS',
        items: [
          { id: 181, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 182, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
  {
    id: 22,
    count: 4,
    title: 'Sabich',
    desc: `Ready to cook traditional Chicken and Meat shawarma with 16
fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
onion, pickled red onions, pickled turnips,and 5 sauces: classic
tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
sauce`,
    price: '150',
    img:
      'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
    required: [
      {
        id: 183,
        title: 'YOUR CHOICE OF SHAWARMA',
        items: [
          { id: 184, title: '1 KG MEAT', value: '1', extra: null },
          { id: 185, title: '1 KG CHICKEN', value: '2', extra: 12 },
          {
            id: 186,
            title: '1/2 KG CHICKEN & 1/2 KG MEAT',
            value: '3',
            extra: null,
          },
        ],
      },
      {
        id: 187,
        title: 'YOUR CHOICE OF BREAD',
        items: [
          { id: 188, title: 'FRESH BAKED PITA', value: '1' },
          { id: 189, title: 'FRESH BAKED SAJ', value: '2' },
        ],
      },
    ],
    condiments: [
      {
        id: 190,
        title: 'ADD ONS',
        items: [
          {
            id: 191,
            title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
            value: '5.5',
          },
          {
            id: 192,
            title: '1/2 KG READY TO COOK MEAT SHAWARMA',
            value: '5.5',
          },
          {
            id: 193,
            title:
              '1/2 KG READY TO COOK CAULIFLOWER BITES WITH CITRUS TAHINI SAUCE',
            value: '3.75',
          },
        ],
      },
      {
        id: 193,
        title: 'CONDIMENTS',
        items: [
          { id: 194, title: 'CLASSIC GARLIC', value: '0.85' },
          { id: 195, title: 'SUMAC GARLIC', value: '0.85' },
        ],
      },
    ],
  },
]
