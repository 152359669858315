import { connect } from 'react-redux'
import Cart from './Cart'

const mapStateToProps = state => ({
  total: state.totalCart.total,
  color: '#d80417',
  lang: state.changeLang.lang
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart)
