import {
  CHECKOUT_CONTACT_FETCHING,
  CHECKOUT_CONTACT_SUCCESS,
  CHECKOUT_CONTACT_FAILURE,
} from '../actionTypes/checkout'

const initialState = {
  error: null,
  data: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_CONTACT_FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case CHECKOUT_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      }
    case CHECKOUT_CONTACT_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }

    default:
      return state
  }
}
