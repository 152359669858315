import { connect } from 'react-redux'
import Status from './Status'

const mapStateToProps = () => ({
  color: '#d80417',
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
