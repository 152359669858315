import React from 'react'
import Delivery from 'components/cart/componets/delivery'
import CartItems from 'components/cart/componets/items'
import PromoCode from 'components/cart/componets/promoCode'
import CartTotal from 'components/cart/componets/total'
import CustomBtn from 'components/public/button'
import EmptyBag from 'components/cart/componets/emptyBag'
import Header from 'components/public/pageHeader'

class Cart extends React.PureComponent {
  render() {
    const { total, color, lang } = this.props
    let Count = localStorage.getItem('merchantTotalCountCart')
    return (
      <>
        <Header url="/" text={lang === 'ar' ? 'راجع الطلب' : 'Review order'} />
        <div className="order-section height-100 white-bg">
          {(total && total.count <= 0) || Count <= 0 ? (
            <EmptyBag color="#f1041a" lang={lang} />
          ) : (
            <>
              <hr />
              <Delivery lang={lang} />
              <CartItems lang={lang} />
              <PromoCode color={color} lang={lang} />
              <hr />
              <CartTotal total={total ? total.price : null} lang={lang} />
            </>
          )}
        </div>
        <CustomBtn
          color={color}
          text={
            (total && total.count == 0) || Count == 0
              ? lang === 'ar'
                ? 'تصفح القائمة'
                : 'Browse Menu'
              : lang === 'ar'
              ? 'إتمام الطلب'
              : 'Go to checkout'
          }
          isLink
          url={
            (total && total.count == 0) || Count == 0
              ? '/'
              : '/checkout/contact'
          }
          onClick={() => {}}
        />
      </>
    )
  }
}
export default Cart
