import { connect } from 'react-redux'
import Time from './Time'
import { setDeliveryModeActionRequest } from 'store/actions'

const mapStateToProps = state => ({
  time: state.setArea.data,
  color: '#d80417',
  lang: state.changeLang.lang
})

const mapDispatchToProps = dispatch => ({
  setDeliveryModeActionRequest: body =>
    dispatch(setDeliveryModeActionRequest(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Time)
