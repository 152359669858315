import { changeTotalCartActionSuccess } from './actionCreators'

const ChangeTotalCartActionRequest = (totalPrice, totalCount) => (dispatch) => {
  let totalCart = localStorage.getItem('merchantTotalPriceCart')
  let Count = localStorage.getItem('merchantTotalCountCart')
  let newCount = Count ? Number(Count) + Number(totalCount) : totalCount
  let newTotal = totalCart
    ? Number(totalCart) + Number(totalPrice)
    : Number(totalPrice)
  var finalTotalPrice = newTotal.toFixed(2)

  localStorage.setItem('merchantTotalPriceCart', finalTotalPrice)
  localStorage.setItem('merchantTotalCountCart', newCount)

  const total = {
    count: newCount,
    price: finalTotalPrice,
  }

  dispatch(changeTotalCartActionSuccess(total))
}

export default ChangeTotalCartActionRequest
