import { connect } from 'react-redux'
import OrderStatus from './OrderStatus'

const mapStateToProps = state => ({
  color: '#d80417',
  lang: state.changeLang.lang
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderStatus)
