import React from 'react'
import { Link } from 'react-router-dom'

class Header extends React.PureComponent {
  render() {
    const { url, text, id } = this.props
    return (
      <>
        <header className="public-header" id={id}>
          <h1 className="header-title m-0 p-0">{text}</h1>
          <Link to={url} className="link-header">
            <i className="back">
              <svg width="1em" height="1em" viewBox="0 0 32 32">
                <path
                  id="path"
                  d="M10.414 17l4.293 4.293a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414l6-6a1 1 0 111.414 1.414L10.414 15H24a1 1 0 010 2H10.414z"
                ></path>
              </svg>
            </i>
          </Link>
        </header>
      </>
    )
  }
}
export default Header
