import { combineReducers } from 'redux'
import changeLang from 'store/reducers/changeLangReducer'
import cart from 'store/reducers/addCartReducer'
import totalCart from 'store/reducers/totalCartReducer'
import checkoutContact from 'store/reducers/checkoutContactReducer'
import areas from 'store/reducers/areaListReducer'
import setArea from 'store/reducers/setAreaReducer'

export default combineReducers({
  changeLang,
  cart,
  totalCart,
  checkoutContact,
  areas,
  setArea,
})
