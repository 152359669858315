import { connect } from 'react-redux'
import Home from './Home'

const mapStateToProps = state => ({ lang: state.changeLang.lang })

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
