import { connect } from 'react-redux'
import CarPickUp from './CarPickup'
import { setDeliveryModeActionRequest } from 'store/actions'

const mapStateToProps = state => ({
  color: '#d80417',
  lang: state.changeLang.lang
})

const mapDispatchToProps = dispatch => ({
  setDeliveryModeActionRequest: data =>
    dispatch(setDeliveryModeActionRequest(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarPickUp)
