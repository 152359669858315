import React from 'react'
import ModeLayout from 'components/mode'
import { ListGroupItem, ListGroup } from 'reactstrap'
import { Link } from 'react-router-dom'

class CarPickUp extends React.PureComponent {
  state = {
    activeBranch: null
  }
  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (
      orderMode &&
      orderMode.branch_enName &&
      orderMode.order_mode === 'pickup'
    ) {
      this.setState({ activeBranch: orderMode.branch_enName })
    }
  }
  renderList = data => {
    const { color, setDeliveryModeActionRequest, history, lang } = this.props
    const { activeBranch } = this.state
    return (
      <ListGroup className="ltr-1v9c9b3 mx-0">
        {data.map(item => (
          <ListGroupItem
            action
            className={`p-0 branches-list-group m-0 w-100 ${activeBranch &&
              activeBranch === item.title_en &&
              'active-area'}`}
            key={item.id}
          >
            <div
              className="d-flex  justify-content-between"
              style={{ cursor: 'pointer ' }}
            >
              <div
                className="w-100 p-3"
                onClick={() => {
                  this.setState({ activeBranch: item.name_en })
                  const orderMode = JSON.parse(
                    localStorage.getItem('merchantOrderMode')
                  )
                    ? JSON.parse(localStorage.getItem('merchantOrderMode'))
                    : {}
                  orderMode.order_mode = 'pickup'
                  orderMode.branch_id = item.id
                  orderMode.branch_enName = item.title_en
                  orderMode.branch_arName = item.title_ar
                  setDeliveryModeActionRequest(orderMode)
                  history.push('/')
                }}
              >
                <h3 className="ltr-1gvv4e3 p-0 m-0">
                  {lang === 'ar' ? item.title_ar : item.title_en}
                </h3>
                <p className="ltr-1kefxcu p-0 m-0">
                  {lang === 'ar' ? item.desc_ar : item.desc_en}
                </p>
              </div>
              <Link to="/branch/id" className="link px-3 ltr-1ipvnc5">
                <div className=" custom-text-align">
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 44 44"
                    style={{ color: `${color}` }}
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      transform="translate(10 10)"
                    >
                      <path d="M0 0h24v24H0z"></path>
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.4"
                        d="M22 12c0 2.761-.976 5.118-2.929 7.071C17.118 21.024 14.761 22 12 22s-5.118-.976-7.071-2.929C2.976 17.118 2 14.761 2 12s.976-5.118 2.929-7.071C6.882 2.976 9.239 2 12 2s5.118.976 7.071 2.929C21.024 6.882 22 9.239 22 12zm-10 4v-4"
                      ></path>
                      <circle cx="12" cy="9" r="1" fill="currentColor"></circle>
                    </g>
                  </svg>
                </div>
              </Link>
            </div>
          </ListGroupItem>
        ))}
        <style>
          {`.active-area::before {
                background: ${color};
              }`}
        </style>
      </ListGroup>
    )
  }
  render() {
    const data = [
      {
        id: 1,
        title_en: 'Surra',
        title_ar: 'سورا',
        desc_en: 'Block 02 , Street – 05 , Building No – 427,',
        desc_ar: 'شارع 5'
      }
    ]
    return (
      <ModeLayout history={this.props.history}>
        <div className="branches-section h-100 white-bg">
          {this.renderList(data)}
        </div>
      </ModeLayout>
    )
  }
}
export default CarPickUp
