import React from 'react'
import { Link } from 'react-router-dom'
import CustomBtn from 'components/public/button'

class Status extends React.PureComponent {
  render() {
    const { color, activeStep2, activeStep3, activeStep4, tell } = this.props
    return (
      <>
        <div className="w-100 h-100 white-bg px-3">
          <div className="d-flex p-3 flex-column align-items-center">
            <svg x="0px" y="0px" viewBox="0 0 814 958" width="150">
              <style>{'.st0{fill:#d60000}'}</style>
              <g id="color">
                <path d="M784 270.9l-9.7-9.7c-28.6-28.6-73.8-27.1-100.4 3.4L423.8 551.1 325.1 465c-31.2-27.2-80.7-25.6-109.9 3.7L197 486.8c-28.6 28.6-27.1 73.8 3.4 100.4l212.4 185.5c15.2 13.2 38.2 11.7 51.4-3.5l48.5-55.5c1.2-1.2 2.3-2.4 3.4-3.6l276.1-316.3c31.7-36.3 28-91.3-8.2-122.9zm-10.5 106.4L497.3 693.6c-.7.8-1.5 1.7-2.4 2.6l-.5.5-48.9 56.1c-3 3.4-6.7 3.9-8.6 3.9-2 0-4.8-.5-7.5-2.8L216.9 568.4c-9.5-8.3-14.9-19.4-15.3-31.4s4.3-23.5 13.1-32.4l18.1-18.1c10-10 24.4-15.8 39.4-15.8 13.7 0 26.6 4.7 36.4 13.2l117.5 102.6L692.7 281c8.6-9.9 20.3-15.3 32.9-15.3 11.5 0 22.5 4.7 31 13.2l10.3 10.3.6.6c25.8 22.4 28.5 61.7 6 87.5z" />
                <path d="M685.7 381c-6.3-5.5-15.9-4.9-21.4 1.5L567.9 493c-5.5 6.3-4.9 15.9 1.5 21.4 6.3 5.5 15.9 4.9 21.4-1.5l96.4-110.5c5.5-6.3 4.8-15.9-1.5-21.4zM745.7 312c-6.3-5.5-15.9-4.9-21.4 1.5l-20 22.9c-5.5 6.3-4.9 15.9 1.5 21.4s15.9 4.9 21.4-1.5l20-22.9c5.5-6.3 4.8-15.9-1.5-21.4z" />
                <g>
                  <path
                    className="st0"
                    d="M62.2 436.7l-19.9-8.3-8.3-19.9c-.6-1.1-2.2-1.7-3.3-1.1-.6 0-1.1.6-1.1 1.1l-8.3 19.9-19.9 8.3c-1.1.6-1.7 2.2-1.1 3.3 0 .6.6 1.1 1.1 1.1l19.9 8.3 8.3 19.9c.6 1.1 2.2 1.7 3.3 1.1.6 0 1.1-.6 1.1-1.1l8.3-19.9 19.9-8.3c1.1-.6 1.7-2.2 1.1-3.3 0-.6-.6-1.1-1.1-1.1zM734.7 30.6c0-.6-.6-1.1-1.1-1.1l-19.9-8.3-8.3-19.9c-.6-1.1-2.2-1.7-3.3-1.1-.6 0-1.1.6-1.1 1.1l-8.3 19.9-19.9 8.3c-1.1.6-1.7 2.2-1.1 3.3.6 0 1.1.6 1.7 1.1l19.9 8.3 8.3 19.9c.6 1.1 2.2 1.7 3.3 1.1.6 0 1.1-.6 1.1-1.1l8.3-19.9 19.4-8.3c1-.5 1.5-2.2 1-3.3zM589.5 904.7l-31-12.7-12.7-31c-1.1-2.2-3.3-2.8-5.5-1.7-.6.6-1.7 1.1-1.7 1.7l-12.7 31-31 12.7c-2.2 1.1-2.8 3.3-1.7 5.5.6.6 1.1 1.7 1.7 1.7l31 12.7 12.7 31c1.1 2.2 3.3 2.8 5.5 1.7.6-.6 1.7-1.1 1.7-1.7l12.7-31 31-12.7c2.2-1.1 2.8-3.3 1.7-5.5-.1-.6-.6-1.1-1.7-1.7z"
                  />
                </g>
              </g>
            </svg>
            <div className="d-flex flex-column text-center white-bg pt-3">
              <h1 className="ltr-1ieammf e1iliclz0">Thank you!</h1>
              <p className="ltr-qtvxxo e1iliclz0">
                Your order is placed successfully.
              </p>
            </div>
            <div className="ltr-b6njci e6q2xso0">
              <h3 className="ltr-xv8pht ">
                Order Code<span className="ltr-dl8qll ">A736-2QU9</span>
              </h3>
            </div>
          </div>
          <div className="py-3">
            <div className="ltr-vsl5ie e1limytx0 d-flex justify-content-between">
              <h3 className="ltr-14siyw ">Estimated Time</h3>
              <div className="d-flex">
                <span className="ltr-imi5h7">Thu, 9 Jul 2020</span>
                <span className="ltr-imi5h7">9:00 AM - 9:45 AM</span>
              </div>
            </div>
            <div
              title="stepper status-stepper"
              className="stepper d-flex position-relative w-100"
            >
              <div className="step active-step" />
              <div className={`step  ${activeStep2 ? 'active-step' : null} `} />
              <div className={`step ${activeStep3 ? 'active-step' : null} `} />
              <div className={`step ${activeStep4 ? 'active-step' : null} `} />
            </div>
            <p className="ltr-19sapue">Preparing your order</p>
          </div>
          <Link to={`/receipt/${'SDSF-CXDZ'}`}>
            <button className="status-btn w-100 text-center">
              View receipt
            </button>
          </Link>
          <a
            href={`tel:${tell}`}
            className="ez1ieiq2 ltr-wp05q2 e1b3lgmq0 text-center mx-0 mt-2"
          >
            <span className="content text-center w-100">Get help</span>
          </a>

          <style type="text/css">
            {`
          .active-step {
            background-color: ${color} !important;
            }
        `}
          </style>
          <div style={{ height: '50px' }} className="bg-white" />
        </div>
        <CustomBtn
          color={color}
          type="submit"
          onClick={() => {}}
          isLink
          url="/"
          text="Start over"
        />
      </>
    )
  }
}
export default Status
