import React from 'react'
import Delivery from 'components/home/components/delivery'
import CategoryList from 'components/home/components/categoryList'
import OrderBtn from 'components/home/components/orderBtn'

class Home extends React.PureComponent {
  render() {
    const { lang } = this.props
    return (
      <>
        <Delivery lang={lang} />
        <CategoryList lang={lang} />
        <OrderBtn lang={lang} />
      </>
    )
  }
}
export default Home
