import React from 'react'
import moment from 'moment-timezone'
import CustomRadioBtn from 'components/public/radioBtn'
import CustomBtn from 'components/public/button'
import 'moment/locale/ar'
import 'moment/locale/en-gb'
import _ from 'lodash'

class Schedule extends React.PureComponent {
  state = {
    selectedDate: moment()
      .tz('asia/kuwait')
      .format('MM-DD-YYYY'),
    selectedDate_ar: moment()
      .locale('ar')
      .tz('asia/kuwait')
      .format('MM-DD-YYYY'),
    selectedDay_ar: moment()
      .locale('ar')
      .tz('asia/kuwait')
      .format('D MMM'),
    selectedDay: moment()
      .tz('asia/kuwait')
      .format('D MMM'),
    defaultTime: 'time-range-option0',
    orderMode: null,
    selectedTime: null,
    selectedTime_ar: null,
    timeRange: null,
    times: [
      { id: 1, from: '10:30 AM', to: '12:30 PM' },
      { id: 2, from: '12:30 PM', to: '02:30 PM' },
      { id: 3, from: '02:30 PM', to: '04:30 PM' },
      { id: 4, from: '04:30 PM', to: '05:30 PM' }
    ],
    days: [
      moment().tz('asia/kuwait'),
      moment()
        .tz('asia/kuwait')
        .add(1, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(2, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(3, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(4, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(5, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(6, 'days'),
      moment()
        .tz('asia/kuwait')
        .add(7, 'days')
    ]
  }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    const { selectedDate, times } = this.state
    this.setState({
      selectedTime: `${moment(selectedDate + ' ' + times[0].from).format(
        'hh:mm A'
      )} - ${moment(selectedDate + ' ' + times[0].to).format('hh:mm A')}`,
      selectedTime_ar: `${moment(selectedDate + ' ' + times[0].from)
        .locale('ar')
        .format('hh:mm A')} - ${moment(selectedDate + ' ' + times[0].to)
        .locale('ar')
        .format('hh:mm A')}`
    })
    if (orderMode) {
      if (orderMode.time_mode && orderMode.time_mode === 'Scheduled time') {
        this.setState({
          selectedDay: orderMode.day,
          timeRange: orderMode.time_range
        })
      }
    }
  }

  renderDays = (date, index) => {
    const { lang } = this.props
    const { selectedDay } = this.state
    return (
      <>
        <label
          role="radio"
          htmlFor={moment()
            .locale(lang)
            .tz('asia/kuwait')
            .add(index, 'days')
            .format('MM-DD-YYYY')}
          className="ltr-dfpqc0 e6y7kge1 m-0"
          onClick={() => {
            this.setState({
              selectedDate: date.format('MM-DD-YYYY'),
              selectedDate_ar: moment()
                .locale('ar')
                .tz('asia/kuwait')
                .add(index, 'days')
                .format('MM-DD-YYYY'),
              selectedDay: date.format('D MMM'),
              selectedDay_ar: moment()
                .locale('ar')
                .tz('asia/kuwait')
                .add(index, 'days')
                .format('D MMM')
            })
          }}
        >
          <div
            color="black"
            className="ltr-1yo3nmz e6y7kge2"
            style={{
              backgroundColor:
                selectedDay === date.format('D MMM')
                  ? 'rgb(245, 245, 245)'
                  : '#fff'
            }}
          >
            <input
              id={date}
              type="radio"
              name="scheduledDate"
              className="ltr-l93xhc eppvlgj0"
              value={date.format('MM-DD-YYYY')}
              checked=""
            />
            <p className="ltr-1rn4h86 e1iliclz0 m-0 p-0 text-uppercase">
              {moment()
                .locale(lang)
                .tz('asia/kuwait')
                .add(index, 'days')
                .format('D MMM')}
            </p>
            <p className="ltr-1vueemx e1iliclz0 m-0 p-0">
              {index == 0
                ? lang === 'ar'
                  ? 'اليوم'
                  : 'Today'
                : index == 1
                ? lang === 'ar'
                  ? 'غداً'
                  : 'Tomorrow'
                : moment()
                    .locale(lang)
                    .tz('asia/kuwait')
                    .add(index, 'days')
                    .format('dddd')}
            </p>
          </div>
        </label>
      </>
    )
  }

  render() {
    const {
      selectedDate,
      selectedDay,
      times,
      days,
      defaultTime,
      selectedTime,
      timeRange,
      selectedDay_ar,
      selectedDate_ar,
      selectedTime_ar
    } = this.state
    const { setDeliveryModeActionRequest, color, history, lang } = this.props

    return (
      <>
        <div className="px-3">
          <div
            name="scheduledDate"
            aria-label="scheduledDate"
            value="2020-07-15"
            className="ltr-1qhf5kz e6y7kge0"
          >
            <div className="ltr-1j8qet7 e6q2xso0" style={{ overflowY: 'auto' }}>
              <div className="ltr-1ryzbh9 e6q2xso0">
                {days.map((item, index) => (
                  <>{this.renderDays(item, index)}</>
                ))}
              </div>
            </div>
          </div>
          <div className="ltr-bozcr4">
            {times.map((item, index) => (
              <>
                <CustomRadioBtn
                  id={`time-range-option${index}`}
                  label={`${moment(selectedDate + ' ' + item.from)
                    .locale(lang)
                    .format('hh:mm A')} - ${moment(selectedDate + ' ' + item.to)
                    .locale(lang)
                    .format('hh:mm A')}`}
                  value={`${moment(selectedDate + ' ' + item.from).format(
                    'hh:mm A'
                  )} - ${moment(selectedDate + ' ' + item.to).format(
                    'hh:mm A'
                  )}`}
                  name="times"
                  condition=" "
                  onChange={e => {
                    this.setState({
                      defaultTime: `time-range-option${index}`,
                      selectedTime: e,
                      selectedTime_ar: `${moment(selectedDate + ' ' + item.from)
                        .locale(lang)
                        .format('hh:mm A')} - ${moment(
                        selectedDate + ' ' + item.to
                      )
                        .locale(lang)
                        .format('hh:mm A')}`,
                      timeRange: item
                    })
                  }}
                  checked={
                    timeRange
                      ? _.isEqual(timeRange, item)
                      : defaultTime === `time-range-option${index}`
                  }
                />
              </>
            ))}
          </div>
        </div>
        <CustomBtn
          color={color}
          text={
            lang === 'ar'
              ? `ضبط الوقت  ・ ${
                  selectedDay ==
                  moment()
                    .tz('asia/kuwait')
                    .format('D MMM')
                    ? 'اليوم'
                    : selectedDay ==
                      moment()
                        .tz('asia/kuwait')
                        .add(1, 'days')
                        .format('D MMM')
                    ? 'غداً'
                    : selectedDay_ar
                } ${selectedTime_ar}`
              : `Set time  ・  ${
                  selectedDay ==
                  moment()
                    .tz('asia/kuwait')
                    .format('D MMM')
                    ? 'Today'
                    : selectedDay ==
                      moment()
                        .tz('asia/kuwait')
                        .add(1, 'days')
                        .format('D MMM')
                    ? 'Tomorrow'
                    : selectedDay
                } ${selectedTime}`
          }
          onClick={() => {
            const orderMode = JSON.parse(
              localStorage.getItem('merchantOrderMode')
            )
            orderMode.day =
              selectedDay ==
              moment()
                .tz('asia/kuwait')
                .format('D MMM')
                ? 'Today'
                : selectedDay ==
                  moment()
                    .tz('asia/kuwait')
                    .add(1, 'days')
                    .format('D MMM')
                ? 'Tomorrow'
                : selectedDay
            orderMode.day_ar =
              selectedDay_ar ==
              moment()
                .locale('ar')
                .tz('asia/kuwait')
                .format('D MMM')
                ? 'اليوم'
                : selectedDay_ar ==
                  moment()
                    .locale('ar')
                    .tz('asia/kuwait')
                    .add(1, 'days')
                    .format('D MMM')
                ? 'غداً'
                : selectedDay_ar
            orderMode.selected_time = selectedTime
            orderMode.selected_time_ar = selectedTime_ar
            orderMode.date = selectedDate
            orderMode.date_ar = selectedDate_ar
            orderMode.time_mode = 'Scheduled time'
            orderMode.time_range = timeRange
            setDeliveryModeActionRequest(orderMode)
            history.push('/')
          }}
        />
      </>
    )
  }
}

export default Schedule
