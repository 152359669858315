import React from 'react'
import { Link } from 'react-router-dom'

const data = [
  {
    id: 1,
    deliver_time: '15 mins',
    deliver_time_ar: '15 دقیقه'
  }
]

class Delivery extends React.PureComponent {
  state = {
    orderMode: null,
    selected_time: null,
    time_mode: null,
    branch_arName: null,
    branch_enName: null,
    day: null,
    day_ar: null,
    selected_time_ar: null,
    area_arName: null,
    area_enName: null
  }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode) {
      this.setState({ orderMode: orderMode.order_mode })
      if (orderMode.order_mode === 'pickup') {
        this.setState({
          branch_arName: orderMode.branch_arName,
          branch_enName: orderMode.branch_enName
        })
      }
      if (orderMode.order_mode === 'delivery') {
        this.setState({
          area_arName: orderMode.area_arName,
          area_enName: orderMode.area_enName
        })
      }
      if (orderMode.time_mode) {
        this.setState({ time_mode: orderMode.time_mode })
      }
      if (orderMode.day) {
        this.setState({
          day: orderMode.day,
          selected_time: orderMode.selected_time
        })
      }
      if (orderMode.day_ar) {
        this.setState({
          day_ar: orderMode.day_ar,
          selected_time_ar: orderMode.selected_time_ar
        })
      }
    }
  }
  render() {
    const { color, lang } = this.props
    const {
      orderMode,
      branch_enName,
      branch_arName,
      area_enName,
      area_arName,
      time_mode,
      day,
      day_ar,
      selected_time,
      selected_time_ar
    } = this.state
    return (
      <>
        <div className="ltr-1pl04lf e6q2xso0">
          {!orderMode ? (
            <div className="ltr-1j8qet7 e6q2xso0">
              <div data-testid="delivery-mode" className="ltr-5a3nod e6q2xso0">
                <Link className="e13k8or00 ltr-qvdoh2" to="/mode/delivery">
                  {lang === 'ar' ? 'توصيل' : 'Delivery'}
                </Link>
              </div>
              <div data-testid="delivery-mode" className="ltr-5a3nod e6q2xso0">
                <Link className="e13k8or00 ltr-qvdoh2" to="/mode/pickup">
                  {lang === 'ar' ? 'استلام بالسيارة' : 'Car Pickup'}
                </Link>
              </div>
            </div>
          ) : (
            <div className="white-bg">
              <div className="d-flex flex-column">
                <div className="ltr-xbp7n3 e6q2xso0">
                  <h2 className="ltr-14siyw e1iliclz0 m-0">
                    {orderMode === 'pickup' && lang === 'ar'
                      ? 'استلام بالسيارة'
                      : orderMode === 'pickup' && lang === 'en'
                      ? 'Car Pickup'
                      : null}
                    {orderMode === 'delivery' && lang === 'ar'
                      ? 'توصيل'
                      : orderMode === 'delivery' && lang === 'en'
                      ? 'Delivery'
                      : null}
                  </h2>
                  <Link
                    className="ltr-qeqdm6"
                    to={
                      orderMode === 'pickup' ? '/mode/delivery' : '/mode/pickup'
                    }
                  >
                    <div className="ltr-1egxki5 e6q2xso0">
                      {orderMode === 'pickup' && lang === 'ar'
                        ? 'التبديل إلى توصيل'
                        : orderMode === 'pickup' && lang === 'en'
                        ? 'Switch to Delivery'
                        : null}
                      {orderMode === 'delivery' && lang === 'ar'
                        ? 'التبديل إلى استلام بالسيارة'
                        : orderMode === 'delivery' && lang === 'en'
                        ? 'Switch to Car Pickup'
                        : null}
                      <i cursor="pointer" className="ltr-ttj5pb e1i5aqxk0">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                          <g fill="none" fillRule="evenodd">
                            <path d="M3 3h18v18H3z"></path>
                            <path d="M3 3h18v18H3z"></path>
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.4"
                              d="M9 18l6-6-6-6"
                            ></path>
                          </g>
                        </svg>
                      </i>
                    </div>
                  </Link>
                </div>
                <div className="ltr-1096nit py-3 e6q2xso0 d-flex justify-content-between">
                  <p className="ltr-13w7zlb e1iliclz0">
                    {orderMode === 'pickup' && lang === 'ar'
                      ? 'الفرع'
                      : orderMode === 'pickup' && lang === 'en'
                      ? 'Branch'
                      : null}
                    {orderMode === 'delivery' && lang === 'ar'
                      ? 'المنطقة'
                      : orderMode === 'delivery' && lang === 'en'
                      ? 'Area'
                      : null}
                  </p>
                  <Link
                    className="e13k8or00 ltr-qeqdm6"
                    to={
                      orderMode === 'pickup' ? '/mode/delivery' : '/mode/pickup'
                    }
                  >
                    <div className="ltr-1egxki5 e6q2xso0">
                      {orderMode === 'pickup' && lang === 'ar'
                        ? branch_arName
                        : orderMode === 'pickup' && lang === 'en'
                        ? branch_enName
                        : null}
                      {orderMode === 'delivery' && lang === 'ar'
                        ? area_arName
                        : orderMode === 'delivery' && lang === 'en'
                        ? area_enName
                        : null}
                      <i cursor="pointer" className="ltr-ttj5pb e1i5aqxk0">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                          <g fill="none" fillRule="evenodd">
                            <path d="M3 3h18v18H3z"></path>
                            <path d="M3 3h18v18H3z"></path>
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.4"
                              d="M9 18l6-6-6-6"
                            ></path>
                          </g>
                        </svg>
                      </i>
                    </div>
                  </Link>
                </div>
                <div className="ltr-yv1fgv e6q2xso0 d-flex justify-content-between">
                  <p className="ltr-13w7zlb e1iliclz0">
                    {time_mode &&
                    lang === 'ar' &&
                    time_mode === 'Scheduled time'
                      ? 'الطلب لوقت لاحق'
                      : time_mode &&
                        lang === 'en' &&
                        time_mode === 'Scheduled time'
                      ? time_mode
                      : time_mode &&
                        lang === 'ar' &&
                        time_mode === 'Estimated Time'
                      ? 'الوقت المتوقع'
                      : lang === 'ar'
                      ? 'الطلب لوقت لاحق'
                      : 'Estimated Time'}
                  </p>
                  <Link className="e13k8or00 ltr-qeqdm6" to="/time">
                    <div className="ltr-1egxki5 e6q2xso0">
                      {time_mode && time_mode === 'Scheduled time'
                        ? lang === 'ar' && day_ar
                          ? `${day_ar} ${selected_time_ar}`
                          : `${day} ${selected_time}`
                        : lang === 'ar'
                        ? data[0].deliver_time_ar
                        : data[0].deliver_time}
                      <i cursor="pointer" className="ltr-ttj5pb e1i5aqxk0">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                          <g fill="none" fillRule="evenodd">
                            <path d="M3 3h18v18H3z"></path>
                            <path d="M3 3h18v18H3z"></path>
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.4"
                              d="M9 18l6-6-6-6"
                            ></path>
                          </g>
                        </svg>
                      </i>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <Link className="ltr-1vsrr9p" to="/search">
            <div className="ltr-1fqqef4 e1i3i4pb0">
              <div className="ltr-hhxm44 e1i3i4pb1">
                <input
                  type="search"
                  required=""
                  data-testid="products-search-input"
                  placeholder={lang === 'ar' ? 'بحث' : 'Search'}
                  className="e1mim1e70 ltr-xauasu e1i3i4pb2"
                />
              </div>
            </div>
          </Link>
        </div>
        <style>
          {`.e13k8or00{color:${color}} .ltr-qeqdm6,.ltr-qeqdm6:hover,.e13k8or00:hover {
          color: rgb(191, 4, 20);
             }`}
        </style>
        <hr />
      </>
    )
  }
}
export default Delivery
