import {
  changeCartActionFetching,
  changeCartActionSuccess,
} from './actionCreators'

const getChangeCartActionRequest = (cart) => (dispatch) => {
  dispatch(changeCartActionFetching())
  localStorage.setItem('merchantCart', JSON.stringify(cart))
  dispatch(changeCartActionSuccess(cart))
}

export default getChangeCartActionRequest
 