import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Row, Col } from 'reactstrap'
import visaLogo from 'assets/img/visa2-small.png'
import masterLogo from 'assets/img/master-small.png'

class MasterForm extends React.PureComponent {
  render() {
    const { lang } = this.props
    return (
      <>
        <Formik
          initialValues={{
            'card-number': '',
            'expiration-date': '',
            cvv: '',
            'card-holder': ''
          }}
          onSubmit={async values => {
            await new Promise(resolve => setTimeout(resolve, 500))
            alert(JSON.stringify(values, null, 2))
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            } = props
            return (
              <form onSubmit={handleSubmit}>
                <Row className="p-0 m-0">
                  <Col
                    xl="3"
                    xs="3"
                    className="p-3 m-0 d-flex flex-column align-items-end  text-center"
                  >
                    <img
                      src={visaLogo}
                      className=" master-logos mb-3"
                      alt={visaLogo}
                    />
                    <img
                      src={masterLogo}
                      alt={masterLogo}
                      className=" master-logos mt-2"
                    />
                  </Col>
                  <Col xl="9" xs="9" className="px-5 m-0">
                    <div className="form-fields">
                      <input
                        id="card-number"
                        autocomplete="off"
                        type="tel"
                        placeholder={
                          lang === 'ar' ? 'رقم البطاقة' : 'Card Number'
                        }
                        pattern="[0-9 ]*"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? 'master-input w-100 error p-2'
                            : 'master-input w-100 p-2'
                        }
                      />
                      <div className="d-flex">
                        <input
                          id="expiration-date"
                          maxlength="5"
                          type="tel"
                          autocomplete="off"
                          placeholder="MM/YY"
                          pattern="[0-9/]*"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'master-input w-100 error p-2'
                              : 'master-input w-100 p-2'
                          }
                        />
                        <div className="vertical-line" />
                        <input
                          id="cvv"
                          autocomplete="off"
                          maxlength="3"
                          placeholder="CVV"
                          pattern="[0-9 ]*"
                          type="password"
                          inputmode="numeric"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'master-input w-100 error p-2'
                              : 'master-input w-100 px-2 p-2'
                          }
                        />
                      </div>
                      <input
                        id="card-holder"
                        autocomplete="off"
                        type="text"
                        placeholder={
                          lang === 'ar'
                            ? 'اسم حامل البطاقة'
                            : 'Card Holder Name'
                        }
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? 'master-input w-100 error p-2'
                            : 'master-input w-100 p-2'
                        }
                      />
                    </div>
                  </Col>
                  <Col xl="12" className="px-5">
                    <p>
                      {errors.email && touched.email && (
                        <div className="input-feedback">{errors.email}</div>
                      )}
                    </p>
                    <button
                      scale="1"
                      type="submit"
                      className="credit-submit text-center"
                    >
                      {lang === 'ar' ? 'تأكيد' : 'Submit'}
                    </button>
                  </Col>
                </Row>
              </form>
            )
          }}
        </Formik>
      </>
    )
  }
}

export default MasterForm
