export default {
  items: [
    {
      id: '5eba5038fb5ec67b88dd00d5',
      name_en: 'Hawally',
      name_ar: 'حولي',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd00e5',
          name_en: 'Al Bedae',
          name_ar: 'البدع',
        },
        {
          id: '5eba5038fb5ec67b88dd00e4',
          name_en: 'Bayan',
          name_ar: 'بيان',
        },
        {
          id: '5eba5038fb5ec67b88dd00e3',
          name_en: 'Hawally',
          name_ar: 'حولي',
        },
        {
          id: '5eba5038fb5ec67b88dd00e2',
          name_en: 'Hitteen',
          name_ar: 'حطين',
        },
        {
          id: '5eba5038fb5ec67b88dd00e1',
          name_en: 'Jabriya',
          name_ar: 'الجابرية',
        },
        {
          id: '5eba5038fb5ec67b88dd00e0',
          name_en: 'Maidan Hawally',
          name_ar: 'ميدان حولي',
        },
        {
          id: '5eba5038fb5ec67b88dd00df',
          name_en: 'Mishrif',
          name_ar: 'مشرف',
        },
        {
          id: '5eba5038fb5ec67b88dd00de',
          name_en: 'Mubarak Al Abdullah',
          name_ar: 'مبارك العبدالله',
        },
        {
          id: '5eba5038fb5ec67b88dd00dd',
          name_en: 'Rumaithiya',
          name_ar: 'الرميثية',
        },
        {
          id: '5eba5038fb5ec67b88dd00dc',
          name_en: 'Salam',
          name_ar: 'السلام',
        },
        {
          id: '5eba5038fb5ec67b88dd00db',
          name_en: 'Salmiya',
          name_ar: 'السالمية',
        },
        {
          id: '5eba5038fb5ec67b88dd00da',
          name_en: 'Salwa',
          name_ar: 'سلوى',
        },
        {
          id: '5eba5038fb5ec67b88dd00d9',
          name_en: 'Shaab',
          name_ar: 'الشعب',
        },
        {
          id: '5eba5038fb5ec67b88dd00d8',
          name_en: 'Shuhada',
          name_ar: 'الشهداء',
        },
        {
          id: '5eba5038fb5ec67b88dd00d7',
          name_en: 'Siddiq',
          name_ar: 'الصديق',
        },
        {
          id: '5eba5038fb5ec67b88dd00d6',
          name_en: 'Zahra',
          name_ar: 'الزهراء',
        },
      ],
    },
    {
      id: '5eba5038fb5ec67b88dd00e9',
      name_en: 'Mubarak Al Kabir',
      name_ar: 'مبارك الكبير',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd00f9',
          name_en: 'Abu Ftaira',
          name_ar: 'أبو فطيرة',
        },
        {
          id: '5eba5038fb5ec67b88dd00f8',
          name_en: 'Abu Hasaniya',
          name_ar: 'أبو الحصانية',
        },
        {
          id: '5eba5038fb5ec67b88dd00f7',
          name_en: 'Adan',
          name_ar: 'العدان',
        },
        {
          id: '5eba5038fb5ec67b88dd00f6',
          name_en: 'Al Mesayel',
          name_ar: 'المسايل',
        },
        {
          id: '5eba5038fb5ec67b88dd00f5',
          name_en: 'Coast Strip B',
          name_ar: 'شريط الساحلي ب',
        },
        {
          id: '5eba5038fb5ec67b88dd00f4',
          name_en: 'Fintas',
          name_ar: 'الفنطاس',
        },
        {
          id: '5eba5038fb5ec67b88dd00f3',
          name_en: 'Funaitees',
          name_ar: 'الفنيطيس',
        },
        {
          id: '5eba5038fb5ec67b88dd00f2',
          name_en: 'Messila',
          name_ar: 'المسيلة',
        },
        {
          id: '5eba5038fb5ec67b88dd00f1',
          name_en: 'Mubarak Al Kabir',
          name_ar: 'مبارك الكبير',
        },
        {
          id: '5eba5038fb5ec67b88dd00f0',
          name_en: 'Qurain',
          name_ar: 'القرين',
        },
        {
          id: '5eba5038fb5ec67b88dd00ef',
          name_en: 'Qusour',
          name_ar: 'القصور',
        },
        {
          id: '5eba5038fb5ec67b88dd00ee',
          name_en: 'Sabah Al Salem',
          name_ar: 'صباح السالم',
        },
        {
          id: '5eba5038fb5ec67b88dd00ed',
          name_en: 'Sabhan Industrial',
          name_ar: 'صبحان الصناعية',
        },
        {
          id: '5eba5038fb5ec67b88dd00ec',
          name_en: 'South Wista',
          name_ar: 'جنوب الوسطي',
        },
        {
          id: '5eba5038fb5ec67b88dd00eb',
          name_en: 'West Abu Ftaira',
          name_ar: 'غرب ابو فطيرة',
        },
        {
          id: '5eba5038fb5ec67b88dd00ea',
          name_en: 'Wista',
          name_ar: 'وسطى',
        },
      ],
    },
    {
      id: '5eba5038fb5ec67b88dd00fa',
      name_en: 'Farwaniya',
      name_ar: 'الفروانية',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd0110',
          name_en: 'Abbasiya',
          name_ar: 'عباسية',
        },
        {
          id: '5eba5038fb5ec67b88dd010f',
          name_en: 'Abdullah Al Mubarak',
          name_ar: 'عبدالله المبارك',
        },
        {
          id: '5eba5038fb5ec67b88dd010e',
          name_en: 'Abraq Khaitan',
          name_ar: 'أبرق خيطان',
        },
        {
          id: '5eba5038fb5ec67b88dd010d',
          name_en: 'Airport',
          name_ar: 'المطار',
        },
        {
          id: '5eba5038fb5ec67b88dd010c',
          name_en: 'Andalous',
          name_ar: 'الأندلس',
        },
        {
          id: '5eba5038fb5ec67b88dd010b',
          name_en: 'Ardhiya',
          name_ar: 'العارضية',
        },
        {
          id: '5eba5038fb5ec67b88dd010a',
          name_en: 'Ardhiya Small Industrial',
          name_ar: 'العارضية الصناعية',
        },
        {
          id: '5eba5038fb5ec67b88dd0109',
          name_en: 'Ardhiya Storage Zone',
          name_ar: 'عارضية مخازن',
        },
        {
          id: '5eba5038fb5ec67b88dd0108',
          name_en: 'Dhajeej',
          name_ar: 'الضجيج',
        },
        {
          id: '5eba5038fb5ec67b88dd0107',
          name_en: 'Exhibits - South Khaitan',
          name_ar: 'منطقة المعارض جنوب خيطان',
        },
        {
          id: '5eba5038fb5ec67b88dd0106',
          name_en: 'Farwaniya',
          name_ar: 'الفروانية',
        },
        {
          id: '5eba5038fb5ec67b88dd0105',
          name_en: 'Ferdous',
          name_ar: 'الفردوس',
        },
        {
          id: '5eba5038fb5ec67b88dd0104',
          name_en: 'Ishbilya',
          name_ar: 'اشبيليا',
        },
        {
          id: '5eba5038fb5ec67b88dd0103',
          name_en: 'Jaleeb Al Shuyookh',
          name_ar: 'جليب شيوخ',
        },
        {
          id: '5eba5038fb5ec67b88dd0102',
          name_en: 'Khaitan',
          name_ar: 'خيطان',
        },
        {
          id: '5eba5038fb5ec67b88dd0101',
          name_en: 'Omariya',
          name_ar: 'العمرية',
        },
        {
          id: '5eba5038fb5ec67b88dd0100',
          name_en: 'Rabiya',
          name_ar: 'الرابية',
        },
        {
          id: '5eba5038fb5ec67b88dd00ff',
          name_en: 'Rai Industrial',
          name_ar: 'الري الصناعيه',
        },
        {
          id: '5eba5038fb5ec67b88dd00fe',
          name_en: 'Reggai',
          name_ar: 'الرقعي',
        },
        {
          id: '5eba5038fb5ec67b88dd00fd',
          name_en: 'Rehab',
          name_ar: 'الرحاب',
        },
        {
          id: '5eba5038fb5ec67b88dd00fc',
          name_en: 'Sabah Al Nasser',
          name_ar: 'صباح الناصر',
        },
        {
          id: '5eba5038fb5ec67b88dd00fb',
          name_en: 'Shedadiya',
          name_ar: 'الشدادية',
        },
      ],
    },
    {
      id: '5eba5038fb5ec67b88dd0111',
      name_en: 'Jahra',
      name_ar: 'الجهراء',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd0122',
          name_en: 'Abdali',
          name_ar: 'العبدلي',
        },
        {
          id: '5eba5038fb5ec67b88dd0121',
          name_en: 'Amghara',
          name_ar: 'أمغره',
        },
        {
          id: '5eba5038fb5ec67b88dd0120',
          name_en: 'Jahra',
          name_ar: 'الجهراء',
        },
        {
          id: '5eba5038fb5ec67b88dd011f',
          name_en: "Mutla'",
          name_ar: 'المطلاع',
        },
        {
          id: '5eba5038fb5ec67b88dd011e',
          name_en: 'Naeem',
          name_ar: 'النعيم',
        },
        {
          id: '5eba5038fb5ec67b88dd011d',
          name_en: 'Nasseem',
          name_ar: 'النسيم',
        },
        {
          id: '5eba5038fb5ec67b88dd011c',
          name_en: 'Oyoun',
          name_ar: 'العيون',
        },
        {
          id: '5eba5038fb5ec67b88dd011b',
          name_en: 'Qairawan - South Doha',
          name_ar: 'قيروان جنوب الدوحة',
        },
        {
          id: '5eba5038fb5ec67b88dd011a',
          name_en: 'Qasr',
          name_ar: 'قصر',
        },
        {
          id: '5eba5038fb5ec67b88dd0119',
          name_en: 'Saad Al Abdullah',
          name_ar: 'سعد العبدالله',
        },
        {
          id: '5eba5038fb5ec67b88dd0118',
          name_en: 'Sabiya',
          name_ar: 'الصبيه',
        },
        {
          id: '5eba5038fb5ec67b88dd0117',
          name_en: 'Sheikh Saad Al Abdullah Airport',
          name_ar: 'مطار الشيخ سعد العبدالله',
        },
        {
          id: '5eba5038fb5ec67b88dd0116',
          name_en: 'Sulaibiya',
          name_ar: 'الصليبية',
        },
        {
          id: '5eba5038fb5ec67b88dd0115',
          name_en: 'Sulaibiya Farms',
          name_ar: 'الصليبيه الزراعيه',
        },
        {
          id: '5eba5038fb5ec67b88dd0114',
          name_en: 'Sulaibiya Industrial',
          name_ar: 'الصليبية الصناعيه',
        },
        {
          id: '5eba5038fb5ec67b88dd0113',
          name_en: 'Taima',
          name_ar: 'تيماء',
        },
        {
          id: '5eba5038fb5ec67b88dd0112',
          name_en: 'Waha',
          name_ar: 'الواحة',
        },
      ],
    },
    {
      id: '5eba5038fb5ec67b88dd0123',
      name_en: 'Kuwait City',
      name_ar: 'مدينة الكويت',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd0143',
          name_en: 'Abdullah Al Salem',
          name_ar: 'عبدالله السالم',
        },
        {
          id: '5eba5038fb5ec67b88dd0142',
          name_en: 'Adailiya',
          name_ar: 'العديلية',
        },
        {
          id: '5eba5038fb5ec67b88dd0141',
          name_en: 'Al Rai',
          name_ar: 'الري',
        },
        {
          id: '5eba5038fb5ec67b88dd0140',
          name_en: 'Bneid Al Gar',
          name_ar: 'بنيد القار',
        },
        {
          id: '5eba5038fb5ec67b88dd013f',
          name_en: 'Daiya',
          name_ar: 'الدعية',
        },
        {
          id: '5eba5038fb5ec67b88dd013e',
          name_en: 'Dasma',
          name_ar: 'الدسمة',
        },
        {
          id: '5eba5038fb5ec67b88dd013d',
          name_en: 'Dasman',
          name_ar: 'دسمان',
        },
        {
          id: '5eba5038fb5ec67b88dd013c',
          name_en: 'Doha',
          name_ar: 'الدوحة',
        },
        {
          id: '5eba5038fb5ec67b88dd013b',
          name_en: 'Faiha',
          name_ar: 'الفيحاء',
        },
        {
          id: '5eba5038fb5ec67b88dd013a',
          name_en: 'Ghornata',
          name_ar: 'غرناطة',
        },
        {
          id: '5eba5038fb5ec67b88dd0139',
          name_en: 'Jaber Al Ahmad',
          name_ar: 'جابر الأحمد',
        },
        {
          id: '5eba5038fb5ec67b88dd0138',
          name_en: 'Kaifan',
          name_ar: 'كيفان',
        },
        {
          id: '5eba5038fb5ec67b88dd0137',
          name_en: 'Khaldiya',
          name_ar: 'الخالدية',
        },
        {
          id: '5eba5038fb5ec67b88dd0136',
          name_en: 'Kuwait City',
          name_ar: 'مدينة الكويت',
        },
        {
          id: '5eba5038fb5ec67b88dd0135',
          name_en: 'Mansouriya',
          name_ar: 'المنصورية',
        },
        {
          id: '5eba5038fb5ec67b88dd0134',
          name_en: 'Mirqab',
          name_ar: 'المرقاب',
        },
        {
          id: '5eba5038fb5ec67b88dd0133',
          name_en: 'Nahdha',
          name_ar: 'النهضه',
        },
        {
          id: '5eba5038fb5ec67b88dd0132',
          name_en: 'Nuzha',
          name_ar: 'النزهة',
        },
        {
          id: '5eba5038fb5ec67b88dd0131',
          name_en: 'Qadsiya',
          name_ar: 'القادسية',
        },
        {
          id: '5eba5038fb5ec67b88dd0130',
          name_en: 'Qayrawan',
          name_ar: 'القيروان',
        },
        {
          id: '5eba5038fb5ec67b88dd012f',
          name_en: 'Qibla',
          name_ar: 'القبلة',
        },
        {
          id: '5eba5038fb5ec67b88dd012e',
          name_en: 'Qortuba',
          name_ar: 'قرطبة',
        },
        {
          id: '5eba5038fb5ec67b88dd012d',
          name_en: 'Rawda',
          name_ar: 'الروضة',
        },
        {
          id: '5eba5038fb5ec67b88dd012c',
          name_en: 'Salhiya',
          name_ar: 'الصالحية',
        },
        {
          id: '5eba5038fb5ec67b88dd012b',
          name_en: 'Sawaber',
          name_ar: 'الصوابر',
        },
        {
          id: '5eba5038fb5ec67b88dd012a',
          name_en: 'Shamiya',
          name_ar: 'الشامية',
        },
        {
          id: '5eba5038fb5ec67b88dd0129',
          name_en: 'Sharq',
          name_ar: 'شرق',
        },
        {
          id: '5eba5038fb5ec67b88dd0128',
          name_en: 'Shuwaikh',
          name_ar: 'الشويخ',
        },
        {
          id: '5eba5038fb5ec67b88dd0127',
          name_en: 'Sulaibikhat',
          name_ar: 'الصليبيخات',
        },
        {
          id: '5eba5038fb5ec67b88dd0126',
          name_en: 'Surra',
          name_ar: 'السرة',
        },
        {
          id: '5eba5038fb5ec67b88dd0125',
          name_en: 'West Sulaibikhat',
          name_ar: 'غرب الصليبيخات',
        },
        {
          id: '5eba5038fb5ec67b88dd0124',
          name_en: 'Yarmouk',
          name_ar: 'اليرموك',
        },
      ],
    },
    {
      id: '5eba5038fb5ec67b88dd0144',
      name_en: 'Ahmadi',
      name_ar: 'الأحمدي',
      childs: [
        {
          id: '5eba5038fb5ec67b88dd015a',
          name_en: 'Abu Halifa',
          name_ar: 'ابوحليفة',
        },
        {
          id: '5eba5038fb5ec67b88dd0159',
          name_en: 'Al Ahmadi',
          name_ar: 'الأحمدي',
        },
        {
          id: '5eba5038fb5ec67b88dd0158',
          name_en: 'Ali Sabah Al Salem',
          name_ar: 'علي صباح السالم',
        },
        {
          id: '5eba5038fb5ec67b88dd0157',
          name_en: 'Dhaher',
          name_ar: 'الظهر',
        },
        {
          id: '5eba5038fb5ec67b88dd0156',
          name_en: 'East Al Ahmadi',
          name_ar: 'شرق الاحمدي',
        },
        {
          id: '5eba5038fb5ec67b88dd0155',
          name_en: 'Egaila',
          name_ar: 'العقيلة',
        },
        {
          id: '5eba5038fb5ec67b88dd0154',
          name_en: 'Fahad Al Ahmed',
          name_ar: 'فهد الاحمد',
        },
        {
          id: '5eba5038fb5ec67b88dd0153',
          name_en: 'Fahaheel',
          name_ar: 'الفحيحيل',
        },
        {
          id: '5eba5038fb5ec67b88dd0152',
          name_en: 'Fintas',
          name_ar: 'الفنطاس',
        },
        {
          id: '5eba5038fb5ec67b88dd0151',
          name_en: 'Hadiya',
          name_ar: 'هدية',
        },
        {
          id: '5eba5038fb5ec67b88dd0150',
          name_en: 'Jaber Al Ali',
          name_ar: 'جابر العلي',
        },
        {
          id: '5eba5038fb5ec67b88dd014f',
          name_en: 'Khiran',
          name_ar: 'الخيران',
        },
        {
          id: '5eba5038fb5ec67b88dd014e',
          name_en: 'Magwa',
          name_ar: 'مقوع',
        },
        {
          id: '5eba5038fb5ec67b88dd014d',
          name_en: 'Mahboula',
          name_ar: 'المهبولة',
        },
        {
          id: '5eba5038fb5ec67b88dd014c',
          name_en: 'Mangaf',
          name_ar: 'المنقف',
        },
        {
          id: '5eba5038fb5ec67b88dd014b',
          name_en: 'Mina Abdullah',
          name_ar: 'ميناء عبدالله',
        },
        {
          id: '5eba5038fb5ec67b88dd014a',
          name_en: 'Mina Al Ahmadi',
          name_ar: 'ميناء الاحمدي',
        },
        {
          id: '5eba5038fb5ec67b88dd0149',
          name_en: 'Riqqa',
          name_ar: 'الرقة',
        },
        {
          id: '5eba5038fb5ec67b88dd0148',
          name_en: 'Sabah Al Ahmad City',
          name_ar: 'مدينة صباح الأحمد',
        },
        {
          id: '5eba5038fb5ec67b88dd0147',
          name_en: 'Sabahiya',
          name_ar: 'الصباحية',
        },
        {
          id: '5eba5038fb5ec67b88dd0146',
          name_en: 'Shuaiba Port',
          name_ar: 'ميناء الشعيبة',
        },
        {
          id: '5eba5038fb5ec67b88dd0145',
          name_en: 'Wafra',
          name_ar: 'الوفره',
        },
      ],
    },
  ],
}
