import React from 'react'

class CustomRadioBtn extends React.PureComponent {
  render() {
    const {
      id,
      label,
      value,
      name,
      color,
      condition,
      onChange,
      textUppercase,
      checked,
      defaultChecked,
    } = this.props

    return (
      <>
        <label htmlFor={id} className="ltr-g2zmlc eppvlgj4">
          <input
            type="radio"
            name={name}
            id={id}
            className="ltr-l93xhc eppvlgj0"
            value={value}
            onChange={() => onChange(value, name)}
            checked={checked}
            defaultChecked={defaultChecked}
          />
          <span className="ltr-1rpgelp e1bm915c0" />
          <div className="ltr-17sbyd3 eppvlgj1">
            <div className="ltr-7ae04q eppvlgj2">
              <p
                className={`m-0  ltr-1tqni6m ${
                  !textUppercase && 'text-uppercase'
                }`}
              >
                {label}
              </p>
              <div className="ltr-1wdmg0f e6q2xso0" />
            </div>
            <span className="ltr-1rn4h86">{condition ? condition : '-'}</span>
          </div>
        </label>
        <style type="text/css">
          {`
          .eppvlgj0:checked + .ltr-1rpgelp {
            background: ${color};
            border-color: ${color};
          }
          .eppvlgj4:hover .ltr-1rpgelp,
          .eppvlgj4:focus .ltr-1rpgelp,
          .eppvlgj0:focus + .ltr-1rpgelp {
            border-color: ${color};
          }
        `}
        </style>
      </>
    )
  }
}
export default CustomRadioBtn
