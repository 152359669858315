import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import Header from 'components/public/pageHeader'

class ModeLayout extends React.PureComponent {
  state = { location: window.location.pathname }
  render() {
    const { children, history, lang } = this.props
    const { location } = this.state
    return (
      <>
        <Header
          text={lang === 'ar' ? 'طريقة الاستلام' : 'Order Mode'}
          url="/"
        />
        <div className="white-bg h-100">
          <Tabs
            defaultTab={location === '/mode/delivery' ? 'one' : 'two'}
            onChange={() => {}}
          >
            <TabList className="delivery-tabs">
              <Tab
                tabFor="one"
                onClick={() => {
                  history.push('/mode/delivery')
                }}
                className="w-50"
              >
                {lang === 'ar' ? 'توصيل' : 'Delivery'}
              </Tab>
              <Tab
                tabFor="two"
                className="w-50"
                onClick={() => {
                  history.push('/mode/pickup')
                }}
              >
                {lang === 'ar' ? 'استلام بالسيارة' : 'Car pickup'}
              </Tab>
            </TabList>
            <TabPanel tabId={location === '/mode/delivery' ? 'one' : 'two'}>
              {children}
            </TabPanel>
          </Tabs>
        </div>
      </>
    )
  }
}
export default ModeLayout
