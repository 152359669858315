import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import Menu from 'components/layout/components/menu'
import AppLocale from 'utilities/lang'
import { IntlProvider } from 'react-intl'

class MainLayout extends React.PureComponent {
  state = { lng: 'ar' }

  componentDidMount() {
    const language = localStorage.getItem('merchantLang')
    const { getChangeLangActionRequest } = this.props

    if (language) {
      this.setState({ lng: language })
      getChangeLangActionRequest(language)
    }

    window.addEventListener('scroll', this.handleScroll, true)
    const nav = document.getElementById('#navbar')
    if (nav) {
      nav.classList.add('gradient-bg')
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const nav = document.getElementById('#navbar')
    const svg = document.getElementById('enSvg')
      ? document.getElementById('enSvg')
      : document.getElementById('arSvg')

    const bag = document.getElementById('bagSvg')
    const search = document.getElementById('searchSvg')
    const miniLogo = document.getElementById('miniLogo')

    if (window.scrollY >= 10 && nav && svg && bag) {
      nav.classList.add('light')
      nav.classList.remove('gradient-bg')
      svg.classList.add('scrolledSvg')
      bag.classList.add('scrolledSvg')
    } else if (nav && svg && bag) {
      nav.classList.remove('light')
      nav.classList.add('gradient-bg')
      svg.classList.remove('scrolledSvg')
      bag.classList.remove('scrolledSvg')
    }

    if (window.scrollY >= 390 && search && miniLogo) {
      search.classList.add('scrolledSvg')
      miniLogo.classList.add('mini-logo')
    } else if (search && miniLogo) {
      search.classList.remove('scrolledSvg')
      miniLogo.classList.remove('mini-logo')
    }
  }

  render() {
    const { children, lang } = this.props
    const currentAppLocale = AppLocale[lang]
    const loc = window.location.pathname

    const data = {
      background:
        'https://static.zyda.com/photos/restaurants/photo_urls/792/default/PITA-BAR-COVER-PICTURE.jpg?1588585143',
      title: 'PITA BAR',
      title_ar: 'بيتا بار',
      logo:
        'https://static.zyda.com/photos/restaurants/logo_urls/792/default/Logo.png?1584519643',
      desc: 'The Gift of The Gulf'
    }
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Container
          className={`themed-container p-0 m-0 height-100 main-bg position-relative ${
            lang === 'ar' ? 'rtl' : ''
          }`}
          fluid={true}
        >
          <Row className="p-0 m-0 height-100 d-flex flex-row flex-md-row-reverse">
            <Col
              lg="8"
              md="12"
              className="p-0 m-0 height-100 intro-section d-flex"
            >
              <div className={`inner-layout ${loc != '/' && 'res-header'}`}>
                <div
                  className="main-background"
                  style={{
                    backgroundImage: `url(${data.background})`
                  }}
                />
                <div className="menu-container " id="#navbar">
                  <Menu logo={data.logo} />
                </div>
                <div className="logo-title-section">
                  <div className="logo-title-container">
                    <img src={data.logo} alt={data.logo} className="logo" />
                    <h1 className="title">
                      {lang === 'ar' ? data.title_ar : data.title}
                    </h1>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="12"
              className={`p-0 m-0 height-100 main-section ${loc != '/' &&
                'res-main-section'}`}
            >
              <div className="position-relative h-100">{children}</div>
            </Col>
          </Row>
        </Container>
      </IntlProvider>
    )
  }
}

export default MainLayout
