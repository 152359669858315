const routes = {
  index: {
    _: '/',
  },
  branches: {
    _: '/branches',
  },
  product_detail: {
    _: '/item/:id',
  },
  branch_detail: {
    _: '/branch/:id',
  },
  cart: {
    _: '/order',
  },
  checkout: {
    _: '/checkout/contact',
    checkout_contact: {
      _: '/checkout/contact',
    },
    checkout_delivery: {
      _: '/checkout/delivery',
    },
    checkout_payment: {
      _: '/checkout/payment',
    },
    checkout_confirm: {
      _: '/checkout/confirm',
    },
  },
  location: {
    _: '/location',
  },
  status: {
    _: '/status/:id',
  },
  receipt: {
    _: '/receipt/:id',
  },
  mode: {
    delivery: {
      _: '/mode/delivery',
    },
    pickup: {
      _: '/mode/pickup',
    },
  },
  time: {
    _: '/time',
  },
  order_status: {
    _: '/order-status',
  },
  search: {
    _: '/search',
  },
}

export default routes
