import { connect } from 'react-redux'
import OrderBtn from './OrderBtn'

const mapStateToProps = (state) => ({
  totalCart: state.totalCart.total,
  color: '#d80417',
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(OrderBtn)
