import appLocaleData from 'react-intl/locale-data/en'
import enMessages from 'utilities/lang/locales/en-US'

// const translate = localStorage.getItem("coffee_translate")

const EnLang = {
  messages: {
    ...enMessages,
  },
  locale: 'en-US',
  data: appLocaleData,
}
export default EnLang
