import { TOTAL_CART_SUCCESS } from 'store/actionTypes/addToCart'

const initialState = {
  total: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_CART_SUCCESS:
      return {
        ...state,
        total: action.payload,
        isFetching: false,
      }

    default:
      return state
  }
}
