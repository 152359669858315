import React from 'react'

class CustomInput extends React.PureComponent {
  render() {
    const { id, onBlur, label, onChange, value, className } = this.props
    return (
      <div className="white-bg w-100">
        <div className="field-container">
          <input
            className={`field-input ${className}`}
            id={id}
            value={value}
            name="inputName"
            type="text"
            placeholder=" "
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
          />
          <label className="field-placeholder" htmlFor="inputName">
            {label}
          </label>
        </div>
      </div>
    )
  }
}
export default CustomInput
