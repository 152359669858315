import { connect } from 'react-redux'
import MainLayout from './Layout'
import { getChangeLangActionRequest } from 'store/actions'

const mapStateToProps = (state) => ({
  lang: state.changeLang.lang,
})

const mapDispatchToProps = (dispatch) => ({
  getChangeLangActionRequest: (lang) =>
    dispatch(getChangeLangActionRequest(lang)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
