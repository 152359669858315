import React from 'react'
import CheckoutLayout from 'components/checkout'
import knet from 'assets/img/knet.png'
import visa from 'assets/img/visa.png'
import master from 'assets/img/master.png'
import MasterForm from 'components/checkout/components/masterForm'
import CustomBtn from 'components/public/button'
import WalletSvg from './svg'
import _ from 'lodash'

class CheckoutPayment extends React.PureComponent {
  state = { activeMethod: null, error: false, mode: null }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.order_mode) {
      this.setState({ mode: orderMode.order_mode })
    }
  }
  render() {
    const { color, getCheckoutContactActionRequest, lang } = this.props
    const { activeMethod, error } = this.state
    let checkoutForm = JSON.parse(localStorage.getItem('checkoutContact'))
      ? JSON.parse(localStorage.getItem('checkoutContact'))
      : {}
    this.setState({
      activeMethod: checkoutForm ? checkoutForm.paymentMethod : null
    })
    const { mode } = this.state
    return (
      <CheckoutLayout
        activeLink="/checkout/payment"
        url={mode === 'delivery' ? '/checkout/delivery' : '/checkout/contact'}
        activeStep2
        activeStep3
      >
        {error && _.isNil(activeMethod) ? (
          <div className="text-center text-white error-section">
            {lang === 'ar'
              ? 'من فضلك اختر طريقة للدفع'
              : 'Please pick a payment method'}
          </div>
        ) : null}
        <div className="white-bg height-100">
          <WalletSvg color={color} lang={lang} />
          <div className="ltr-13atv4k etr9bj70 p-3 text-center ">
            <label className="ltr-ndfmgy e1b3lgmq0 ">
              <a
                className={`text-center d-flex flex-column ${
                  activeMethod === 'knet' ? 'active-method' : ' ltr-1scfs3q '
                }`}
                onClick={() => {
                  checkoutForm.paymentMethod = 'knet'
                  getCheckoutContactActionRequest(checkoutForm)
                  this.setState({ activeMethod: 'knet' })
                }}
              >
                <img src={knet} alt={knet} className="payment-icon mx-auto" />
                <p className="p-0 mt-2 mb-0">
                  {lang === 'ar' ? 'كي نت' : 'KNET'}
                </p>
              </a>
            </label>
            <label className="ltr-ndfmgy e1b3lgmq0">
              <a
                className={`text-center d-flex flex-column ${
                  activeMethod === 'credit' ? 'active-method' : 'ltr-1scfs3q '
                }`}
                onClick={() => {
                  checkoutForm.paymentMethod = 'credit'
                  getCheckoutContactActionRequest(checkoutForm)
                  this.setState({ activeMethod: 'credit' })
                }}
              >
                <div className="d-flex mx-auto">
                  <img alt={visa} src={visa} className="payment-icon mx-2" />
                  <img
                    alt={master}
                    src={master}
                    className="payment-icon mx-2"
                  />
                </div>
                <p className="p-0 mt-2 mb-0">
                  {lang === 'ar' ? 'بطاقة إئتمان' : 'Credit card'}
                </p>
              </a>
            </label>
          </div>
          {activeMethod === 'credit' && <MasterForm lang={lang} />}
        </div>

        <CustomBtn
          color={color}
          type="submit"
          onClick={() => {
            if (!activeMethod) {
              this.setState({ error: true })
            } else {
              this.setState({ error: false })
            }
          }}
          text={lang === 'ar' ? 'متابعة' : 'Next'}
          isLink={activeMethod}
          url="/checkout/confirm"
        />
        <style type="text/css">
          {`.active-method,.ltr-1scfs3q:hover{border-color:${color} !important ;} 
          .error{
            border-color:${color}
          }
            `}
        </style>
      </CheckoutLayout>
    )
  }
}
export default CheckoutPayment
