import React from 'react'

class CartTotal extends React.PureComponent {
  render() {
    let totalCart = localStorage.getItem('merchantTotalPriceCart')
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    const { total, lang } = this.props
    return (
      <div className="p-3 white-bg mb-5">
        {orderMode && orderMode.order_mode === 'delivery' && (
          <>
            <div className=" py-1 d-flex white-bg justify-content-between">
              <small
                className="ltr-1y4p49f"
                style={{ color: 'rgb(153, 153, 153)' }}
              >
                {lang === 'ar' ? 'مجموع الطلب' : 'Subtotal'}
              </small>
              <small
                className="ltr-1y4p49f"
                style={{ color: 'rgb(153, 153, 153)' }}
              >
                {lang === 'en' && 'KWD   '}
                {Number(totalCart).toFixed(2)}
                {lang === 'ar' && '  د.ك'}
              </small>
            </div>
            <div className="py-1 d-flex white- justify-content-between">
              <small
                className="ltr-1y4p49f"
                style={{ color: 'rgb(153, 153, 153)' }}
              >
                {lang === 'ar' ? 'رسوم التوصيل' : 'Delivery'}
              </small>
              <small
                className="ltr-1y4p49f"
                style={{ color: 'rgb(153, 153, 153)' }}
              >
                {lang === 'en' && 'KWD   '}
                {Number(totalCart).toFixed(2)}
                {lang === 'ar' && '  د.ك'}
              </small>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between mt-3">
          <h1 className="ltr-14siyw">{lang === 'ar' ? 'المجموع' : 'Total'}</h1>
          <h1 className="ltr-14siyw">
            {lang === 'en' && 'KWD   '}
            {total ? Number(total).toFixed(3) : Number(totalCart).toFixed(3)}
            {lang === 'ar' && '  د.ك'}
          </h1>
        </div>
      </div>
    )
  }
}
export default CartTotal
