import React from 'react'
import Header from 'components/public/pageHeader'
import CustomRadioBtn from 'components/public/radioBtn'
import Schedule from './Schedule'
import CustomBtn from 'components/public/button'
import moment from 'moment-timezone'

const data = [
  {
    id: 1,
    deliver_time: '15 mins',
    deliver_time_ar: '15 دقیقه'
  }
]

class Time extends React.PureComponent {
  state = {
    schedule: false,
    deliverTime: 'deliver-option-0',
    scheduleTime: data[0].deliver_time
  }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))

    if (orderMode) {
      if (orderMode.time_mode) {
        if (orderMode.time_mode === 'Scheduled time') {
          this.setState({
            deliverTime: 'deliver-option-1',
            schedule: true
          })
        }
      }
    }
  }

  render() {
    const { color, setDeliveryModeActionRequest, history, lang } = this.props
    const { schedule, deliverTime, scheduleTime } = this.state

    return (
      <>
        <Header text={lang === 'ar' ? 'وقت التوصيل' : 'Pickup Time'} url="/" />
        <div className="white-bg h-100  pb-3 pt-5 ">
          <div className="pt-2 px-3 mt-3">
            <CustomRadioBtn
              color={color}
              name="time"
              id={`deliver-option-0`}
              value={data[0].deliver_time}
              label={
                lang === 'ar' ? data[0].deliver_time_ar : data[0].deliver_time
              }
              condition=" "
              textUppercase
              checked={deliverTime === `deliver-option-0`}
              onChange={e => {
                this.setState({
                  schedule: false,
                  deliverTime: `deliver-option-0`,
                  scheduleTime: e
                })
              }}
            />
            <CustomRadioBtn
              color={color}
              name="time"
              id={`deliver-option-1`}
              value="schedule"
              label={lang === 'ar' ? 'الطلب لوقت لاحق' : 'Schedule order'}
              textUppercase
              condition=" "
              checked={deliverTime === `deliver-option-1`}
              onChange={() => {
                this.setState({
                  schedule: true,
                  deliverTime: `deliver-option-1`
                })
              }}
            />
          </div>
          {schedule && (
            <Schedule
              color={color}
              history={history}
              lang={lang}
              setDeliveryModeActionRequest={setDeliveryModeActionRequest}
            />
          )}
        </div>
        {!schedule && (
          <CustomBtn
            color={color}
            text={
              lang === 'ar'
                ? `ضبط الوقت  ・ ${scheduleTime}`
                : `Set time  ・  ${scheduleTime}`
            }
            onClick={() => {
              const orderMode = JSON.parse(
                localStorage.getItem('merchantOrderMode')
              )
              orderMode.day = moment()
                .tz('asia/kuwait')
                .format('D MMM')
              orderMode.selected_time = scheduleTime
              orderMode.date = moment()
                .tz('asia/kuwait')
                .format('L')
              orderMode.time_mode = 'Estimated Time'
              orderMode.time_range = null
              setDeliveryModeActionRequest(orderMode)
              history.push('/')
            }}
          />
        )}
      </>
    )
  }
}

export default Time
