import { addLocaleData } from 'react-intl'
import enLang from 'utilities/lang/entries/en-US'
import arLang from 'utilities/lang/entries/ar-KW'

const AppLocale = {
  en: enLang,
  ar: arLang,
}
addLocaleData(AppLocale.en.data)
addLocaleData(AppLocale.ar.data)

export default AppLocale
