export default [
  {
    category_name: 'DIY - COOK FROM HOME',
    category_name_ar: 'اطبخ في البيت',
    category_id: 1,
    items: [
      {
        id: 1,
        count: 0,
        active: false,
        title: 'DIY SHAWARMA',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: false,
        condiments: false
      },
      {
        id: 2,
        count: 0,
        active: false,
        title: '1/2 KG READY TO COOK CHICKEN SHAWARMA',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '200',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: true,
        condiments: true
      },
      {
        id: 3,
        count: 0,
        active: false,
        title: '1/2 KG READY TO COOK FALAFEL MIX WITH CITRUS TAHINI SAUCE',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '100',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/1ed2af5a-94e4-42e5-8e41-f2eccdad03b1/original/New-falafel-Mix.jpg?1588948875',
        required: false,
        condiments: false
      },
      {
        id: 4,
        count: 0,
        active: false,
        title: 'SHAWARMA BOX',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '120',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: false,
        condiments: false
      }
    ]
  },
  {
    category_name: 'CONDIMENTS',
    category_name_ar: 'مـقـبلات',
    category_id: 2,
    items: [
      {
        id: 5,
        count: 0,
        active: false,
        title: 'Apple Tabouleh',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '140',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
        required: false,
        condiments: false
      },
      {
        id: 6,
        count: 0,
        active: false,
        title: 'Tahini Hummus',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '170',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
        required: false,
        condiments: false
      },
      {
        id: 7,
        count: 0,
        active: false,
        title: 'Special Chicken Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '130',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: true,
        condiments: true
      },
      {
        id: 8,
        count: 0,
        active: false,
        title: 'Classic Meat Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: false,
        condiments: false
      },
      {
        id: 9,
        count: 0,
        active: false,
        title: 'SHAWARMA',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '180',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
        required: false,
        condiments: false
      },
      {
        id: 10,
        count: 0,
        active: false,
        title: 'Sabich',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',

        required: true,
        condiments: true
      }
    ]
  },
  {
    category_name: 'SHAWARMA',
    category_name_ar: 'شاورما',
    category_id: 3,
    items: [
      {
        id: 11,
        count: 0,
        active: false,
        title: 'Apple Tabouleh',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '140',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
        required: false,
        condiments: false
      },
      {
        id: 12,
        count: 0,
        active: false,
        title: 'Tahini Hummus',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '170',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
        required: false,
        condiments: false
      },
      {
        id: 13,
        count: 0,
        active: false,
        title: 'Special Chicken Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '130',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: true,
        condiments: true
      },
      {
        id: 14,
        count: 0,
        active: false,
        title: 'Classic Meat Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: false,
        condiments: false
      },
      {
        id: 15,
        count: 0,
        active: false,
        title: 'DIY SHAWARMA BOX',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '180',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
        required: false,
        condiments: false
      },
      {
        id: 16,
        count: 0,
        active: false,
        title: 'Sabich',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',

        required: true,
        condiments: true
      }
    ]
  },
  {
    category_name: 'APPETIZERS',
    category_name_ar: 'بوكسات',
    category_id: 4,
    items: [
      {
        id: 17,
        count: 0,
        active: false,
        title: 'Apple Tabouleh',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '140',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/1b8c458a-ab34-4cd9-b380-7e0fcc57ce32/original/APPLE-TABOULEH.jpg?1584609950',
        required: false,
        condiments: false
      },
      {
        id: 18,
        count: 0,
        active: false,
        title: 'Tahini Hummus',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '170',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/789829fa-979b-4fde-97e4-9c56286cf445/original/TAHINI-HUMMUS.jpg?1584609960',
        required: false,
        condiments: false
      },
      {
        id: 19,
        count: 0,
        active: false,
        title: 'Special Chicken Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '130',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: true,
        condiments: true
      },
      {
        id: 20,
        count: 0,
        active: false,
        title: 'Classic Meat Shawarma',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/3044f592-9e18-4d62-8091-b7d5bb2e4402/original/Untitled-1.jpg?1588980804',
        required: false,
        condiments: false
      },
      {
        id: 21,
        count: 0,
        active: false,
        title: 'DIY SHAWARMA BOX',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '180',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',
        required: true,
        condiments: true
      },
      {
        id: 22,
        count: 0,
        active: false,
        title: 'Sabich',
        desc: `Ready to cook traditional Chicken and Meat shawarma with 16
    fresh baked mini pitas, tomatoes, parsley,rocca leaves, spring
    onions,pomegrante seeds, veg mix chilli, pickled cucumber, red
    onion, pickled red onions, pickled turnips,and 5 sauces: classic
    tahini, herb Tahini, garlic sauce, sumac garlic sauce and spicy
    sauce`,
        price: '150',
        img:
          'https://static.zyda.com/photos/menu_items/photo_urls/f2bc2ae8-7712-4e64-bdac-3da622a1e3de/original/SABICH.jpg?1584610066',

        required: true,
        condiments: true
      }
    ]
  }
]

export const categories = [
  {
    category_name: 'DIY - COOK FROM HOME',
    category_name_ar: 'اطبخ في البيت',
    category_id: 1,
    count: 4
  },
  {
    category_name: 'CONDIMENTS',
    category_id: 2,
    count: 6,
    category_name_ar: 'مـقـبلات'
  },
  {
    category_name: 'SHAWARMA',
    category_id: 3,
    count: 6,
    category_name_ar: 'شاورما'
  },
  {
    category_name: 'APPETIZERS',
    category_id: 4,
    count: 6,
    category_name_ar: 'بوكسات'
  }
]
