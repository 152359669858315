import { connect } from 'react-redux'
import CartItems from './CartItems'
import {
  getChangeCartActionRequest,
  ChangeTotalCartActionRequest,
} from 'store/actions'

const mapStateToProps = (state) => ({
  cartData: state.cart.data,
})

const mapDispatchToProps = (dispatch) => ({
  getChangeCartActionRequest: (cart) =>
    dispatch(getChangeCartActionRequest(cart)),
  ChangeTotalCartActionRequest: (totalPrice, totalCount) =>
    dispatch(ChangeTotalCartActionRequest(totalPrice, totalCount)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartItems)
