import React from 'react'
import Header from 'components/public/pageHeader'

class Checkout extends React.PureComponent {
  state = { mode: null }

  componentDidMount() {
    const orderMode = JSON.parse(localStorage.getItem('merchantOrderMode'))
    if (orderMode && orderMode.order_mode) {
      this.setState({ mode: orderMode.order_mode })
    }
  }
  render() {
    const {
      color,
      children,
      url,
      activeStep2,
      activeStep3,
      activeStep4,
      lang
    } = this.props
    const { mode } = this.state
    return (
      <>
        <Header url={url} text={lang === 'ar' ? 'إتمام الطلب' : 'Checkout'} />
        <div className="checkout-layout-container">
          <div title="stepper" className="stepper d-flex">
            <div className="step active-step" />
            <div className={`step  ${activeStep2 ? 'active-step' : null} `} />
            {mode === 'delivery' && (
              <div className={`step ${activeStep3 ? 'active-step' : null} `} />
            )}
            <div className={`step ${activeStep4 ? 'active-step' : null} `} />
          </div>
          <div className="white-bg h-100">{children}</div>
        </div>
        <style type="text/css">
          {`
          .active-step {
            background-color: ${color} !important;
            }
        `}
        </style>
      </>
    )
  }
}

export default Checkout
