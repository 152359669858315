import React from 'react'

class PlusMinusBtn extends React.PureComponent {
  state = { number: 1 }
  componentDidMount() {
    const { currentTotal } = this.props
    if (currentTotal) {
      this.setState({ number: currentTotal })
    }
  }

  incrementItem = () => {
    const { number } = this.state
    const { increment } = this.props
    this.setState({ number: number + 1 })
    increment()
  }

  decreaseItem = () => {
    const { number } = this.state
    const { decrease } = this.props
    if (number > 1) {
      this.setState({ number: number - 1 })
      decrease()
    }
  }

  render() {
    const { number } = this.state
    const { color, style, plusStyle, minusStyle, textStyle } = this.props

    return (
      <>
        <div className="d-flex pt-3 justify-content-center plus-minus-container white-bg">
          <button
            style={style}
            className="btn--circle white-bg"
            onClick={() => this.decreaseItem()}
            disabled={number === 1}
          >
            <span className="btn-stage minus icon-minus1" style={minusStyle} />
          </button>
          <p className="m-0" style={textStyle}>
            {number}
          </p>
          <button
            className="btn--circle white-bg"
            style={style}
            onClick={() => this.incrementItem()}
          >
            <span className="btn-stage icon-plus1" style={plusStyle} />
          </button>
        </div>
        <style type="text/css">
          {`
          .btn-stage {
            color: ${color};
          }
          .btn--circle {
            border: 1.5px solid ${color};
          }
        `}
        </style>
      </>
    )
  }
}
export default PlusMinusBtn
